import "./scss/index.scss";

import * as React from "react";
import { SEOWrapper } from "@temp/components/SEO";

class Privacy extends React.Component<{}> {
  render() {
    return (
      <SEOWrapper pathname={window.location.pathname}>
        <div className="privacy">
          <br />
          <br />
          <p>
            <span style={{ fontSize: "x-large" }}>
              <strong>PRIVACY POLICY</strong>
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span style={{ fontSize: "small" }}>
              <strong>Last updated March 31, 2020</strong>
            </span>
          </p>
          <p>&nbsp;</p>
          <h2 className="western">
            <span style={{ fontSize: "x-large", fontWeight: "bold" }}>
              <strong>General statement on this privacy policy</strong>
            </span>
          </h2>
          <p>
            This Privacy Policy ("policy") is made in accordance with the
            Personal Data of the Laws of Singapore. This policy explains how
            personally identifiable information is collected, used and disclosed
            by UpGuest Pte. Ltd., doing business as Upguest.io ("
            <strong>Upguest.io</strong>", &ldquo;Upguest&rdquo;, &ldquo;
            <strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, or
            &ldquo;
            <strong>our</strong>&rdquo;), with respect to your access and use of
            Upguest&rsquo;s mobile web application and the website located at
            Upguest.io and any derivative of the website accessed through any
            other affiliate partner sites (collectively, the "Application") and
            the booking and related services offered through the Application
            ("Service"). The information collected will be used for the
            processing of purchase orders and account management.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>General Data Protection Regulation (GDPR)</strong>
            </span>
          </h2>
          <p>
            On 25th May 2018, the European Union upgraded its privacy laws to
            require greater transparency for how people's private information is
            used online. Upguest is committed to the new higher standards of
            transparency for the way we protect the privacy of our customers and
            we are fully compliant with these new protective standards. GDPR
            requires that companies such as Upguest comply with the following:
          </p>
          <ul>
            <li>
              Data-driven consent (optic policy, be transparent on how we view,
              access, and process data.
            </li>
            <li>
              Right to be forgotten (delete data, access &amp; rectification,
              portability).
            </li>
            <li>
              Breach notifications (inform users and local authorities within 72
              hours).
            </li>
            <li>
              Parental Control (we cannot collect data from children under 16
              without verifiable parental consent).
            </li>
            <li>
              Compliance with all subjects for third parties (all third parties
              companies we are using to collect/analyze data must be GDPR
              compliant).
            </li>
            <li>
              Comprehensible policy (policies to be easily understandable and in
              your language).
            </li>
            <li>
              Ensure privacy notices are present wherever personal data is
              collected.
            </li>
            <li>
              Implement controls to limit the organization&rsquo;s use of data
              to the purposes for which it collected the data.
            </li>
          </ul>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                What types of my personal information is collected by Upguest and
                what are the purposes for its collection?
              </strong>
            </span>
          </h2>
          <ul>
            <li>User email (authentication, newsletter).</li>
            <li>User full name (authentication, newsletter).</li>
            <li>User last name (authentication, newsletter).</li>
            <li>User telephone number (authentication).</li>
            <li>
              Hotel Name, booking dates, country of origin and info (booking
              purpose).
            </li>
            <li>User payment method (debit card, credit card, PayPal, etc).</li>
            <li>User ID/ passport/driving license (Fraud, Support).</li>
          </ul>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                What do you require for my consent and is there an option to
                opt-out?
              </strong>
            </span>
          </h2>
          <p>
            For consent to be valid under GDPR, a customer must actively confirm
            their consent, such as ticking an unchecked opt-in box. You are
            given a clear option to opt-out of any sharing of your personal
            information.
          </p>
          <p>
            You may "opt out" of receiving marketing or promotional emails from
            us by request. To opt out follow the instructions within said
            emails. If you opt out, we may still send you non-promotional
            emails, such as emails about your account or our ongoing business
            relations.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>How else do you collect my personal data?</strong>
            </span>
          </h2>
          <p>
            <strong>Passive Collection:</strong> When you use the Service,
            information may be automatically collected, such as your Internet
            Protocol (IP) address, your operating system, browser type, the
            address of a referring website, your activity on the Service,
            certain logistical information in server logs, including information
            about various features of the Service, frequency and length of each
            session.
          </p>
          <p>
            <strong>Cookies:</strong> We may rely on the use of "cookies" to
            collect certain information. Cookies are small data files stored on
            your hard drive at the request of a website. Cookies are employed to
            improve the Service and your user experience. If you elect to block,
            erase, or be warned of cookies, your browser may contain settings
            which allow you to remove or reject cookies. please be advised this
            could affect certain features or services made available through the
            Service.
          </p>
          <p>
            <strong>Anonymous Identifiers:</strong> We may also use anonymous
            identifiers. Anonymous identifiers are random strings of characters
            used in place of cookies on platforms, including certain mobile
            devices, where cookie technology is not applicable.
          </p>
          <p>
            <strong>Web Beacons:</strong> We may employ Web beacons. Web beacons
            are small, invisible graphic images that may be used on the Service
            or in emails relating to the Service to collect certain information
            and monitor user activity on the Service, such as counting visits,
            understanding usage effectiveness and to verify if an email has been
            opened.
          </p>
          <p>
            <strong>Device Specific Information:</strong> We may collect
            device-specific information related to hardware model, operating
            system version, unique device identifiers, and mobile network
            information including phone number). We may associate your device
            identifiers or phone number with your Upguest account.
          </p>
          <p>
            <strong>Registration details from Facebook and Google:</strong> We
            may also receive your personal details that are stored with Facebook
            or Google Plus if you choose to register for convenience through
            Facebook registration or through Gmail registration.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                Will you disclose any of my personal information publicly?
              </strong>
            </span>
          </h2>
          <p>
            By affirmatively opting in your consent, the information you provide
            will be available to other users and in some cases may be publicly
            available (with the exception of personally identifiable
            information, which shall not become publicly available, unless such
            personally identifiable information is shared by you as part of a
            review, photo submission or other similar method).
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                To whom, in what manner, and under what circumstances may my
                information be disclosed to a third party?
              </strong>
            </span>
          </h2>
          <p>
            Information we collect about you will not be disclosed by us to any
            other party in a form that would identify you. Further, any such
            personal information cannot be disclosed unless the third-party
            company we are using is GDPR compliant.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>Links to other websites</strong>
            </span>
          </h2>
          <p>
            Links to other websites may be provided in conjunction with the
            Service. Any personally identifiable information divulged through
            those links to any third-party is subject to that
            third-party&rsquo;s own privacy policy. This Policy does not apply
            to such linked websites, and as such we are not responsible for the
            privacy and security practices of these websites.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>How long is each class of information kept?</strong>
            </span>
          </h2>
          <p>
            The information listed above is stored for a maximum of 60 months.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>Where is my data stored?</strong>
            </span>
          </h2>
          <p>
            Personally identifiable information (such as email, username, and
            address) will be stored in the application's database on secured
            servers based in Singapore, Thailand, US, and other countries. Your
            user media (text, photos) data is stored securely on our cloud
            servers in Singapore.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>How is my information cross-referenced?</strong>
            </span>
          </h2>
          <p>
            The information is being verified in the application's database.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>How is each class of data being used?</strong>
            </span>
          </h2>
          <p>
            To cater to the user and/or customer based on their preferences.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                When is information belonging to each class destroyed?
              </strong>
            </span>
          </h2>
          <p>
            The information listed above is stored for up to 60 months and then
            destroyed.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                How is the accuracy of my collected information guaranteed?
              </strong>
            </span>
          </h2>
          <p>
            The reliability of the collection of information relies on data
            input by the user either on Upguest.io or Application directly or
            through Facebook or Google Plus. You have the right to request
            access to and correction of information about you held by us.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                Are there access mechanisms in place that allow me to
                alter/update inaccurate or obsolete information?
              </strong>
            </span>
          </h2>
          <p>
            Users have access to their user information and are able to update
            them when necessary through our Login area.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                What steps do you take to maintain the security of my personal
                data and information?
              </strong>
            </span>
          </h2>
          <p>
            Upguest takes all necessary reasonable steps to maintain the
            security, privacy and accuracy of all data we collect. Despite these
            steps we offer no warranty against third parties breaching security
            or any other unauthorized access to the data. Despite our efforts,
            no security measures are impenetrable. To assist with the integrity
            of data protection take steps to keep your password confidential and
            do not disclose it to any other person. Please advise us immediately
            if you believe your password has been misused.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                Will you report to the local authorities and to the people (data
                subjects) for data breaches involving personal data?
              </strong>
            </span>
          </h2>
          <ul>
            <li>
              Personal data breaches will be reported within 72 hours of our
              knowledge to the local authorities.
            </li>
            <li>
              We will report what data has been lost, what the consequences are
              and what countermeasures are/will be taken.
            </li>
            <li>
              Unless the data leaked was encrypted, we will report the breach to
              the user (data subject).
            </li>
          </ul>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                How can I obtain further information about privacy policy and
                practices?
              </strong>
            </span>
          </h2>
          <p>
            If you have any queries about our Privacy Policy and Practices,
            please e-mail them to{" "}
            <a href="mailto: legal@Upguest.io.">legal@Upguest.io.</a>
          </p>
          <p>&nbsp;</p>
        </div>
      </SEOWrapper>
    );
  }
}

export default Privacy;
