import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { withApollo } from "react-apollo";

import { MainHeaderContext } from "@temp/app/MainHeaderProvider";
import { SEOWrapper } from "@temp/components/SEO";
import {SignUpSuccessfulView} from "@temp/components/SignUpSuccessful/SignUpSuccessfulView";
// import CheckInResultQuery from "./CheckInResultQuery";

const SignUpSuccessful: FC<RouteComponentProps<{ hotelSlug: string }>> = ({
  history,
  location,
  match: { params: hotelSlug },
}) => {
  return (
    <MainHeaderContext.Consumer>
      {({ update }) => (
        <SEOWrapper pathname={location.pathname}>
          <SignUpSuccessfulView />
        </SEOWrapper>
      )}
    </MainHeaderContext.Consumer>
  );
};

export default withApollo(SignUpSuccessful);
