import * as React from "react";
import { useAlert } from "react-alert";

import { StringParam, useQueryParams } from "use-query-params";

import { RouteComponentProps } from "react-router";
import { TypedAccountConfirmMutation } from "./queries";

import "./scss/index.scss";
import { SEOWrapper } from "@temp/components/SEO";
import { dashboardUrl } from "@temp/constants";

const AccountConfirm: React.FC<RouteComponentProps> = ({ history }) => {
  const [query] = useQueryParams({
    email: StringParam,
    token: StringParam,
  });

  const alert = useAlert();

  const displayConfirmationAlert = (anyErrors) => {
    alert.show(
      {
        content:
          anyErrors.length > 0
            ? anyErrors.map((error) => error.message).join(" ")
            : "You can now log in",
        title: anyErrors.length > 0 ? "Error" : "Account confirmed",
      },
      { type: anyErrors.length > 0 ? "error" : "success", timeout: 5000 }
    );
  };

  React.useEffect(() => {
    this.accountManagerFn({
      variables: { email: query.email, token: query.token },
    })
      .then((result) => {
        const possibleErrors = result.data.confirmAccount.errors;
        displayConfirmationAlert(possibleErrors);
        window.location.assign(
          `${dashboardUrl}dashboard/my-account`
        );
      })
      .catch(() => {
        const errors = [
          {
            message: "Something went wrong while activating your account.",
          },
        ];
        displayConfirmationAlert(errors);
      });
    // .finally(() => {
    //   window.location.assign(dashboardUrl);
    // });
  }, []);

  return (
    <>
      <SEOWrapper pathname={window.location.pathname}>
        <div></div>
      </SEOWrapper>
      <TypedAccountConfirmMutation>
        {(accountConfirm) => {
          this.accountManagerFn = accountConfirm;
          return <div></div>;
        }}
      </TypedAccountConfirmMutation>
    </>
  );
};

export default AccountConfirm;
