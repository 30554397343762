import React from "react";

import { withApollo } from "react-apollo";
// import { getSEO as getSEORequest } from "./SEORequests";

import { Loader } from "src/components";
import SEOViewWrapper from "@temp/components/SEO/SEOViewWrapper";

interface SEOQueryProps {
  client?: any;
  slug: string;
  pathname: string;
  image: string;
  children: any;
}

/*const SEOQueryReducer = (a, b) => {
  if (a === "") {
    return b;
  } else {
    return a + ", " + b;
  }
};*/

/*
const getKeywordString = (page) => {
  return page.keywords
    .map((entry) => entry.keyword)
    .reduce(SEOQueryReducer, "");
};
*/
const SEOQuery = ({
  client,
  slug,
  pathname,
  image,
  children,
}: SEOQueryProps) => {
  const [loading] = React.useState(false);
  const [keywords] = React.useState("");
  const [description] = React.useState("");
  const [title] = React.useState("");
  const [noindex] = React.useState(true);
/*
  React.useEffect(() => {
    (async () => {
      const getPage = await getSEORequest(client, { slug: slug });

      const {
        data: { page },
        loading,
      } = getPage;

      if (page !== null && page !== undefined) {
        setKeywords(getKeywordString(page));
        setTitle(page.seoTitle);
        setDescription(page.seoDescription);
        setNoindex(false);
      }

      setLoading(loading);
    })();
  }, []);*/

  return loading ? (
    <Loader />
  ) : (
    <SEOViewWrapper
      description={description}
      title={title}
      keywords={keywords}
      noindex={noindex}
      pathname={pathname}
      image={image}
    >
      {children}
    </SEOViewWrapper>
  );
};

export default withApollo(SEOQuery);
