import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import { withApollo } from "react-apollo";
import { useAlert } from "react-alert";

import { IOnSubmitProps } from "@temp/checkout/types";
import { Loader } from "@temp/components";
import { checkIn} from "./mutations";
import { getSeller as getSellerRequest } from "@temp/components/HotelDetails/hotelDetailsRequests";
import { CheckInViewStep2 } from "@temp/components/CheckIn/Step2/CheckInViewStep2";
import { CheckInViewStep2Mobile } from "@temp/components/CheckIn/Step2/CheckInViewStep2Mobile";
import {isMobile} from "react-device-detect";

const CheckInQuery = ({ client, hotelSlug, redirectToHotelDetails, updateHeader }) => {
  const alert = useAlert();
  // console.log('CheckInQuery: hotelSlug:', hotelSlug.hotelSlug)
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [hotelData, setHotelData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fireUpAlert = ({
                         content,
                         title,
                         type,
                       }: {
    content: string;
    title: string;
    type: "error" | "success";
  }) =>
      alert.show(
          {
            content,
            title,
          },
          { type, timeout: 5000 }
      );

  useEffect(() => {
    setLoading(true);

    (async () => {
      setLoading(true);

      try {
        const {
          data: { seller },
        } = await getSellerRequest(client, {
          slug: hotelSlug.hotelSlug,
          day: moment().format("YYYY-MM-DD"),
        });

        if (seller) {
          setHotelData({
            hotelName: seller.sellerInfo?.hotelName,
            mainImageUrl: seller.sellerInfo?.mainHotelImage,
            sellerId: seller.id,
          });

          updateHeader({ onlyLogo: true, customLogo: seller.user?.avatar?.url, checkInHeader: true });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [hotelSlug]);

  const _checkIn = useCallback(
      (data: IOnSubmitProps) => {
        setCheckInLoading(true);
        // console.log('hotelData:', hotelData)
        if (hotelData.sellerId && data.file) {
          (async () => {
            try {
              const checkInResult = await checkIn(client, {
                input: {
                  sellerId: hotelData.sellerId,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  email: data.email,
                  phone: data.phone,
                },
                image: data.file,
              });

              // @ts-ignore
              if (checkInResult?.customerHotelCheckIn.customerCheckins === null) {
                fireUpAlert({ content: "Done", title: "Check In", type: "success" });
                setTimeout(() => redirectToHotelDetails(hotelSlug.hotelSlug), 2000)
              } else {
                console.warn("Check In: unexpected: ", { checkInResult });
              }
            } catch (error) {
              fireUpAlert({ content: error.message, title: "Check In", type: "error" });
            } finally {
              setCheckInLoading(false);
            }
          })();
        }
      },
      [(hotelData || {}).sellerId]
  );
  if (isMobile) {
    return loading ? (
        <Loader/>
    ) : (
        <CheckInViewStep2Mobile checkIn={_checkIn} checkInLoading={checkInLoading} {...hotelData} client={client} />
    )
  } else {
    return loading ? (
        <Loader/>
    ) : (
        <CheckInViewStep2 checkIn={_checkIn} checkInLoading={checkInLoading} {...hotelData} client={client} />
    )
  }
};

export default withApollo(CheckInQuery);
