// const slugUrl = ":slug([a-z-0-9]+)/:id([0-9]+)/";
export const privacy = "/privacy";
export const termsandconditions = "/termsandconditions";
export const faq = "/faq";
export const baseUrl = "/";
export const covid = "/covid";

export const signUpFormUrl = `${baseUrl}sign-up/`;
// export const leaveReviewFormUrl = `${baseUrl}leave-review/`;
export const hotelDetails = `${baseUrl}hotel/`;
export const hotelsList = `${baseUrl}hotels`;

export const baseUrlTh = `${baseUrl}th`;
export const searchUrl = `${baseUrl}search/`;
export const checkoutLoginUrl = `${baseUrl}login/`;
export const orderConfirmationUrl = `${baseUrl}order-confirmation/`;
export const orderConfirmedUrl = `${baseUrl}order-confirmed/`;
export const orderFailedUrl = `${baseUrl}order-failed/`;
export const accountUrl = `${baseUrl}account/`;
export const accountConfirmUrl = `${baseUrl}account-confirm/`;
export const passwordResetUrl = `${baseUrl}reset-password/`;
export const termsUrl = `${baseUrl}terms/`;
export const checkInUrl = `${baseUrl}check-in/`;
export const signUpSuccessful = `${baseUrl}signupsuccessful`;

export const facebookUrl = "https://www.facebook.com/GetDailyPass/";
export const instagramUrl = "https://www.instagram.com/getdailypass/";
export const twitterUrl = "https://twitter.com/GetDailyPass";
export const partnerLoginUrl = "https://b2b-dashboard.upguest.io/";
