import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";

type TData = {
  customerHotelCheckIn: {
    customerCheckins: unknown;
    __typename: "CustomerHotelCheckIn";
  };
};

type TVariables = {
  input: {
    sellerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  image: File;
};

type TUpdateKycRequestDataError = {
  field: string;
  message: string;
}

type TUpdateKycRequestData = {
  updateCustomerHotelCheckInRequest: {
    errors: TUpdateKycRequestDataError[];
    __typename: "updateCustomerHotelCheckInRequest";
  };
};

type TUpdateKycRequestVariables = {
  input: {
      "referenceId": string;
      "status": string;
      "internalId": string;
  };
};

type TUpdateCheckInUserData = {
  updateCheckInUser: {
    errors: TUpdateKycRequestDataError[];
    __typename: "updateCheckInUser";
  };
};

type TUpdateCheckInUserVariables = {
  input: {
    "referenceId": string;
    "verified": boolean;
  };
};

type TProcessCheckInDocumentDataImageResult = {
  resultData: string;
}
type TProcessCheckInDocumentData = {
  hotelCheckinProcessDocument: {
    imageResult: TProcessCheckInDocumentDataImageResult;
    errors: TUpdateKycRequestDataError[];
    __typename: "hotelCheckinProcessDocument";
  };
};

type TProcessCheckInDocumentInput = {
  input: {
    "referenceId": string;
    "verified": boolean;
  };
};

type TFinalizeCheckInData = {
  finalizeCheckIn: {
    errors: TUpdateKycRequestDataError[];
  }
};

type TFinalizeCheckInVariables = {
  input: {
    sellerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

export const CHECKIN = gql`
  mutation checkinMutation($input: CustomerHotelCheckInInput!) {
    customerHotelCheckIn(input: $input) {
      customerCheckins {
        seller
        id
      }
    }
  }
`;

export async function checkIn(client: ApolloClient<any>, variables) {
  // console.log("checkIn:client:", client)
  // console.log("checkIn:variables:", variables)
  const checkInResult = await client.mutate<TData, TVariables>({
    mutation: CHECKIN,
    variables,
  });

  return checkInResult.data;
}

export const DOCUMENT_TO_CHECKIN = gql`
  mutation pixlabMutation($input:AddDocumentScanResultToCustomerCheckInInput!) {
    addDocumentScanResultsToCustomerCheckIn(input:$input) {
      __typename
      customerCheckinDocuments {
        __typename
      }
    }
  }
`
export async function AddDocumentToCheckIn(client: ApolloClient<any>, variables) {
  const checkInResult = await client.mutate<TData, TVariables>({
    mutation: DOCUMENT_TO_CHECKIN,
    variables,
  });

  return checkInResult.data;
}

export const UPDATE_CHECKIN = gql`
  mutation updateCheckInMutation($input: CustomerHotelCheckInInput!) {
    customerHotelCheckIn(input: $input) {
      customerCheckins {
        seller
        id
      }
    }
  }
`
export async function updateCheckIn(client: ApolloClient<any>, variables) {
  const checkInResult = await client.mutate<TData, TVariables>({
    mutation: CHECKIN,
    variables,
  });

  return checkInResult.data;
}

export const RETRIEVE_CHECKIN = gql`
  query updateCheckInMutation($input: CustomerHotelCheckInInput!) {
    customerCheckins(input: $input) {
      seller
      id
    }
  }
`
export async function retrieveCheckInEntryFromBackend(client: ApolloClient<any>, variables) {
  const checkInResult = await client.mutate<TData, TVariables>({
    mutation: CHECKIN,
    variables,
  });

  return checkInResult.data;
}


export const UPDATE_KYC_REQUEST = gql`
  mutation updateRequest($input: UpdateCustomerHotelCheckInRequestInput!) {
  updateCustomerHotelCheckInRequest(input:$input) {
    errors {
      field
      message
    }
  }
}
`
export async function updateKycRequest(client: ApolloClient<any>, variables) {
  const updateKycRequestResult = await client.mutate<TUpdateKycRequestData, TUpdateKycRequestVariables>({
    mutation: UPDATE_KYC_REQUEST,
    variables,
  });

  return updateKycRequestResult.data;
}

export const UPDATE_CHECK_IN_USER = gql`
  mutation updateCheckInUserMutation($input: UpdateCheckInUserInput!) {
    updateCheckInUser(input:$input) {
      errors {
        field
        message
      }
    }
  }
`
export async function updateCheckInUser(client: ApolloClient<any>, variables) {
  const updateCheckInUserResult = await client.mutate<TUpdateCheckInUserData, TUpdateCheckInUserVariables>({
    mutation: UPDATE_CHECK_IN_USER,
    variables,
  });

  return updateCheckInUserResult.data;
}

export const PROCESS_CHECKIN_DOCUMENT = gql`
  mutation a($image: Upload!) { 
  hotelCheckinProcessDocument (image: $image) {
    imageResult {
      resultData
    }
    errors {
      field
      message
    }
    __typename
  }
}
`
export async function processCheckInDocument(client: ApolloClient<any>, variables) {
  const processCheckInDocumentResult = await client.mutate<TProcessCheckInDocumentData, TProcessCheckInDocumentInput>({
    mutation: PROCESS_CHECKIN_DOCUMENT,
    variables,
  });

  return processCheckInDocumentResult.data;
}


export const FINALIZE_CHECKIN = gql`
  mutation checkinFinalization($input: FinalizeCheckInInput!) {
    finalizeCheckIn(input:$input) {
      errors {
        field
        message
      }
    }
  }
`
export async function finalizeCheckIn(client: ApolloClient<any>, variables) {
  const finalizeCheckInResult = await client.mutate<TFinalizeCheckInData, TFinalizeCheckInVariables>({
    mutation: FINALIZE_CHECKIN,
    variables,
  });

  return finalizeCheckInResult.data;
}

