import React, { useCallback } from "react";
import { withApollo } from "react-apollo";

import HotelDetailsView from "./HotelDetailsView";
import { Loader } from "src/components";
import { getImages } from "./ImagesCarouselRequests";
import { storeDate } from "@temp/utils";
import {
  createInteraction as createInteractionRequest,
  getDeepLinks as getDeepLinksRequest,
  getFaq as getFaqRequest,
  getPasses as getPassesRequest,
  getSeller as getSellerRequest,
} from "./hotelDetailsRequests";

const HotelDetailsQuery = ({ client, hotelSlug, date, updateHeader }) => {
  const [, setSelectedDate] = React.useState(new Date());
  const [deepLinks, setDeepLinks] = React.useState(null);
  const [faq, setFaq] = React.useState(null);
  const [imagesSources, setImagesSources] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [seller, setSeller] = React.useState(null);
  const [venuePasses, setVenuePasses] = React.useState(null);

  const setDateWithLocalStorage = (date) => {
    setSelectedDate(date);
    storeDate(date, true);
  };

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const {
          data
        } = await getSellerRequest(client, {
          slug: hotelSlug,
          day: date,
        });
        const _seller = data?.seller;
/*        const {
          data: { seller: _seller },
        }*/

        if (_seller) {
          setSeller(_seller);
          localStorage.setItem('sellerLogo', _seller?.user?.avatar?.url)
          updateHeader({ onlyLogo: true, customLogo: _seller?.user?.avatar?.url });

          const {
            data: { hotelImages },
          } = await getImages(client, { sellerInfoId: _seller.sellerInfo.id });

          if (hotelImages) {
            setImagesSources(hotelImages);
          }

          const {
            data: { sellerPasses },
          } = await getPassesRequest(client, {
            sellerId: _seller.id,
            day: date,
          });

          if (sellerPasses) {
            setVenuePasses(sellerPasses);
          }

          const {
            data: { sellerUserFaqLines: faq },
          } = await getFaqRequest(client, { sellerId: _seller.id });

          if (faq) {
            setFaq(faq);
          }

          const {
            data: { seller: deepLinks },
          } = await getDeepLinksRequest(client, { sellerId: _seller.id });

          if (deepLinks) {
            setDeepLinks(deepLinks);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [date, hotelSlug]);

  const createInteraction = useCallback(
    (actionType: string) => {
      createInteractionRequest(client, { sellerId: seller.id, actionType }).then();
    },
    [seller?.id]
  );

  return loading ? (
    <Loader />
  ) : seller && venuePasses && faq && deepLinks ? (
    <HotelDetailsView
      createInteraction={createInteraction}
      deepLinks={deepLinks}
      faq={faq}
      hotelSlug={hotelSlug}
      imagesSources={imagesSources}
      selectedDate={date}
      seller={seller}
      setSelectedDate={setDateWithLocalStorage}
      venuePasses={venuePasses}
    />
  ) : null;
};

export default withApollo(HotelDetailsQuery);
