import React from "react";
import ReactSVG from "react-svg";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import HotelInfoMap from "./HotelInfoMap";
import infoIconPath from "images/info.svg";
import mapIconPath from "images/map-icon.svg";
import { rbrColors } from "@temp/app/ThemeProvider";


const HotelInfo = ({ address, lat, long, reservationEmail, passTypes, googleEmbeddedLink }) => {
  const classes = useStyles();
  const areCoords = lat && long;


  function formatTime(time) {
    return moment()
      .set({
        hour: time?.split(":")[0],
        minute: time?.split(":")[1],
      })
      .format("HH:mm");
  }

  // fill info per venue
  const hours = [];

  passTypes.forEach((passType) => {
    passType.venues.forEach((venue) => {
      const venueTitle = passType.name;
      if (venue.openingHour && venue.closingHour) {
        hours.push({
          label: venueTitle,
          openingTime: formatTime(venue.openingHour),
          endTime: formatTime(venue.closingHour),
        });
      }
      if (venue.secondaryOpeningHour && venue.secondaryClosingHour && venue.secondarySectionName) {
        hours.push({
          label: venue.secondarySectionName,
          openingTime: formatTime(venue.secondaryOpeningHour),
          endTime: formatTime(venue.secondaryClosingHour),
        });
      }
    });
  });

  return (
    <div className={classes.container}>
      <div className={classes.workDetailsContainer}>
        <div className={classes.workDetailsItem}>
          <div className={classes.workDetailsItemTitle}>
            <ReactSVG className={classes.svgIcon} path={infoIconPath} />
            <FormattedMessage id="header.howitworks" />
          </div>
          <div className={classes.workDetailsItemText}>
            1. <FormattedMessage id="hoteldetails.howitworks.first" />
          </div>
          <div className={classes.workDetailsItemText}>
            2. <FormattedMessage id="hoteldetails.howitworks.second" />
          </div>
          <div className={classes.workDetailsItemText}>
            3. <FormattedMessage id="hoteldetails.howitworks.third" />
          </div>
        </div>
      </div>

      {(areCoords || address) && (
        <div className={classes.address}>
          <div className="clearfix">
            <div className={classes.mapAddressStyle}>
              <ReactSVG className={classes.svgIcon} path={mapIconPath} />
              <FormattedMessage id="text.address" />
            </div>
          </div>
          <div className={classes.addressAddress}>{address}</div>
        </div>
      )}
      <div id="map" className={classes.addressContainer}>
        {areCoords ? (
          <div className={classes.map}>
            <HotelInfoMap lat={lat} long={long} googleEmbeddedLink={googleEmbeddedLink} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  addressAddress: {
    marginBottom: "1rem",
    color: "#888787",
  },
  mapAddressStyle: {
    width: "34.5rem",
    [theme.breakpoints.up("xs")]: {
      width: "15.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "19rem",
    },
  },
  svgIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "8px",
    "& svg": {
      height: "20px",
      width: "20px",
      "& path": {
        fill: rbrColors.mainColor,
      },
    },
  },
  subjectAvailability: {
    paddingTop: "1rem",
    color: "#3d3d3d",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "1.2",
    },
  },
  img: {
    width: "60px",
    height: "48px",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: "-20px",
  },
  container: {
    marginTop: "20px",
    marginLeft: "3rem",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  dayPassIncludesContainer: {
    margin: "2rem 0",
  },
  dayPasIncludesTitle: {
    marginBottom: "0.8rem",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: "1.15rem",
  },

  includesTitle: {
    marginTop: "0.9rem",
    color: theme.palette.text.primary,
    fontSize: "21px",
    display: "inline-block",
    verticalAlign: "baseline",
    fontWeight: 600,
    marginBottom: "0.25rem",
    fontFamily: "'Poppins-Bold', sans-serif",
  },
  hoursTitle: {
    marginTop: "0.2rem",
    color: theme.palette.text.primary,
    fontSize: "21px",
    width: "46%",
    display: "inline-block",
    verticalAlign: "baseline",
    fontWeight: 600,
    marginBottom: "1.75rem",
    fontFamily: "'Poppins-Bold', sans-serif",
  },

  workDetailsContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    margin: "2rem 0",
    padding: "1.5rem",
  },
  workDetailsItem: {
    marginBottom: "1.5rem",
    lineHeight: "1.5",
  },
  workDetailsItemTitle: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    display: "inline-block",
    verticalAlign: "middle",
  },
  workDetailsItemText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: "1.5",
  },
  cancellationPolicyLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },

  addressContainer: {},
  map: {
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  address: {
    width: "100%",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0.5rem 0",
    },
  },
  addressTitle: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: "1.5",
  },
  addressText: {
    color: "#888787",
    fontWeight: 400,
    fontSize: "0.8rem",
    lineHeight: "1.2",
    height: "48px",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: "-1rem",
  },
  devider: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.customColors.grid,
    margin: "2rem 0",
  },
  hoursContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      gap: "5px",
    },
  },
  includesContainer: {
    marginBottom: "1em",
    "& ul": {
      listStyle: "disc",
      "& li": {
        margin: "6px auto",
        fontSize: "17px",
        lineHeight: "1.5rem",
        "& ul": {
          listStyle: "disc",
          marginLeft: "36px",
          "& li": {
            paddingLeft: "16px",
          },
        },
      },
    },
  },
  noDiskItem: {
    listStyle: "none",
  },
  voucherIncludesName: {
    "& div": {
      fontSize: "inherit",
      display: "block",
      textAlign: "left",
      fontWeight: "inherit",
      "& span": {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
  },
  menuEbed: {
    width: "100%",
    height: "90vh",
  },
  searchButton: {
    backgroundColor: "#f9a602",
    letterSpacing: "2px",
    borderRadius: "53px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default HotelInfo;
