import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { RouteComponentProps, withRouter } from "react-router-dom";
import * as paths from "src/app/routes/paths";
import { confirmationUrl, dashboardUrl } from "../../constants";
import { HashLink } from "react-router-hash-link";
// import InputAdornment from "@material-ui/core/InputAdornment";

import { withApollo, WithApolloClient } from "react-apollo";
import { getUser, registerSeller, TypedSiteQuery } from "./signUpFormRequests";
import upguestLogo from "src/images/UpGuest_Logo_small.png"

import { Button, TextField, Theme } from "@material-ui/core";
import { useAlert } from "react-alert";
// import { isMobile } from "react-device-detect";
// import Link from "@material-ui/core/Link";
import { allFieldsFilledValidation } from "./validations";
import { SEOWrapper } from "@temp/components/SEO";
import * as appPaths from "@temp/app/routes";
// import whiteLabelImagePath from "src/images/sign-up/white-label.png";
// import retailsQRImagePath from "src/images/sign-up/retails-qr-code.png";
// import centralSystemImagePath from "src/images/sign-up/central-system.png";
// import customerAcquisitionImagePath from "src/images/sign-up/customer-acquisition.png";
import tickOrangeImagePath from "src/images/sign-up/tick_orange.png";
import hotelImagePath1 from "src/images/sign-up/hotels/InterContinental-Hotels-Group-Logo.svg.png";
import hotelImagePath2 from "src/images/sign-up/hotels/Hyatt_Logo.svg.png";
import hotelImagePath3 from "src/images/sign-up/hotels/Accor_Logo_2020.svg";
import hotelImagePath4 from "src/images/sign-up/hotels/Marriott_International_Logo.svg";
import hotelImagePath5 from "src/images/sign-up/hotels/Sheraton-Logo-1937.svg";
import hotelImagePath6 from "src/images/sign-up/hotels/Novotel_logo_(2016).svg.png";
// import ImagesCarousel from "@temp/components/HotelDetails/ImagesCarousel";
// import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
// import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
// import videoThumbnail from "images/sign-up/video_thumbnail.png";

function scrollToHash(hash) {
  const element = document.getElementById(hash);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export const partnerHotelImages = [
  {
    id: hotelImagePath3,
    url: hotelImagePath3,
  },
  {
    id: hotelImagePath4,
    url: hotelImagePath4,
  },
  {
    id: hotelImagePath2,
    url: hotelImagePath2,
  },
  {
    id: hotelImagePath1,
    url: hotelImagePath1,
  },
  {
    id: hotelImagePath5,
    url: hotelImagePath5,
  },
  {
    id: hotelImagePath6,
    url: hotelImagePath6,
  },
];
// const ITEM_HEIGHT = 48;
const contactEmail = "hello@dailypass.com";
// const options = [`Email: ${contactEmail}`];

type SignUpFormPropsInternal = WithApolloClient<RouteComponentProps>;

export interface StyleProps {
  mirror: boolean;
}
/*
const InfoSection = ({
  sectionName,
  sectionLinkName,
  title,
  title2Line = "",
  paragraphs,
  buttonText,
  buttonSectionLinkName,
  imageLink,
  mirror = false,
}) => {
  const classes = useStyles({ mirror });
  return (
    <div id={sectionLinkName} className={classes.section}>
      <div className={classes.infoContainer}>
        <p className={classes.sectionName}>{sectionName}</p>
        <br />
        <p className={classes.sectionTitle}>{title}</p>
        <p className={classes.sectionTitle}>{title2Line}</p>
        <br />
        {paragraphs.map((lines) => (
          <>
            <p className={classes.sectionText}>
              {lines.map((text) => (
                <>
                  {text}
                  <br />
                </>
              ))}
            </p>
            <br />
            <br />
          </>
        ))}
        {buttonText && (
          <Button
            type="submit"
            className={classes.sectionButton}
            variant="contained"
            color="primary"
            onClick={() => {
              location.hash = "#" + buttonSectionLinkName;
              scrollToHash(buttonSectionLinkName);
            }}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <div className={classes.imageContainer}>
        <img alt={"info-image"} className={classes.sectionImage} src={imageLink} />
      </div>
    </div>
  );
};
*/
const SignUpForm = ({ client, history }: SignUpFormPropsInternal) => {
  const classes = useStyles({ mirror: false });
  const customAlert = useAlert();

  // const currency = "$";
  const [accountEmail, setAccountEmail] = React.useState("");
  const [accountPassword, setAccountPassword] = React.useState("");
  const [hotelName, setHotelName] = React.useState("");
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [onboardWay, setOnboardWay] = React.useState("");
  // const [upsellEmail, setUpsellEmail] = React.useState("");
  // const [rooms, setRooms] = React.useState<number>(170);
  // const [avgUpsell, setAvgUpsell] = React.useState<number>(20);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [disableSignup, setDisableSignup] = React.useState(false);

  // const numberUpsells = Math.round(rooms * 0.7);
  // const revenueMonthly = Math.round(avgUpsell * 2 * numberUpsells);

  const [checkValidation, setCheckValidation] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  /*
  const open = Boolean(anchorEl);
  const handleContactClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleContactClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
*/
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  /*
  const upsellHandler = async () => {
    await sendUpsellInfo(client, {
      email: upsellEmail,
      roomsAmount: rooms,
      averageUpsell: avgUpsell,
    });
    customAlert.show(
      {
        content: "A mail with results will be sent to you.",
        title: "Calculating your upsell revenue!",
      },
      {
        timeout: 0,
        type: "success",
      }
    );
  };
  */
  const sellerRegisterHandler = async () => {
    setCheckValidation(true);

    const isAllFieldsFill = allFieldsFilledValidation({
      accountEmail,
      accountPassword,
    });
    if (isAllFieldsFill) {
      setDisableSignup(true);
      const seller = await registerSeller(client, {
        email: accountEmail,
        password: accountPassword,
        redirectUrl: confirmationUrl,
        seller: {
          contactEmail: accountEmail,
          contactPhone: phoneNumber,
          contactPerson: name,
          contactPersonTitle: jobTitle,
          onboardWay,
        },
        sellerInfo: {
          hotelName,
        },
      });
      setDisableSignup(false);
      if (seller.user && seller.user.id) {
        const user = await getUser(client, seller.user.id);

        if (user) {
          customAlert.show(
            {
              content: "A mail with further instructions will be sent to you.",
              title: "Your account has been registered!",
            },
            {
              timeout: 0,
              type: "success",
            }
          );
          history.push(paths.signUpSuccessful);
        } else {
          customAlert.show(
            {
              content: "Something went wrong... Please try again.",
              title: "Error",
            },
            {
              timeout: 0,
              type: "error",
            }
          );
        }
      } else if (seller.errors && seller.errors.length > 0) {
        customAlert.show(
          {
            content: seller.errors[0].message,
            title: "Error",
          },
          {
            timeout: 0,
            type: "error",
          }
        );
        return;
      }
    } else {
      customAlert.show(
        {
          title: "Please fill in all fields",
        },
        {
          timeout: 0,
          type: "error",
        }
      );
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const hash = location.hash.substring(1);
      scrollToHash(hash);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location.hash]);

  const handleContactNowClick = (e) => {
    window.location.href = `mailto:${contactEmail}`;
    e.preventDefault();
  };

  return (
    <SEOWrapper pathname={window.location.pathname}>
      <TypedSiteQuery>
        {({ data }) => {
          return (
            <div>
              <div className={classes.header}>
                <HashLink className={classes.link} to={appPaths.baseUrl}>
                  <img className={classes.logo} src={upguestLogo} />
                </HashLink>

              </div>

              <div className={classes.finalSection}>
                <div id="get-started" className={classes.hotelFormContainer}>
                  <div className={classes.paymentDetailsContainer}>
                    <p className={classes.sectionTitle}>Get started</p>
                    <div className={classes.pointLine}>
                      <img alt={"point"} className={classes.pointImage} src={tickOrangeImagePath} />
                      <p className={classes.sectionText}>
                        15 days FREE TRIAL with unlimited upsells
                      </p>
                    </div>
                    <div className={classes.singUpFormContainer}>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Hotel Name *"
                          value={hotelName}
                          onChange={(event) => setHotelName(event.target.value)}
                        />
                        {hotelName === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Hotel Name is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Email *"
                          value={accountEmail}
                          onChange={(event) => setAccountEmail(event.target.value.toLowerCase())}
                        />
                        {accountEmail === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Email is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Password *"
                          value={accountPassword}
                          onChange={(event) => setAccountPassword(event.target.value)}
                        />
                        {accountPassword === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Password is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Name *"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                        {name === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Name is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Phone Number *"
                          value={phoneNumber}
                          onChange={(event) => setPhoneNumber(event.target.value)}
                        />
                        {phoneNumber === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Phone Number is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Job Title"
                          value={jobTitle}
                          onChange={(event) => setJobTitle(event.target.value)}
                        />
                        <div className={classes.textFieldPad} />
                      </div>
                      <div className={classes.textField}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel id="demo-simple-select-filled-label">
                            How did you hear about UpGuest?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={onboardWay}
                            onChange={(event) => setOnboardWay(event.target.value as string)}
                          >
                            <MenuItem value="Hotel Association">Hotel Association</MenuItem>
                            <MenuItem value="Social Media">Social Media</MenuItem>
                            <MenuItem value="Referral">Referral</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>
                            <MenuItem value="Corporate Partnership">Corporate Partnership</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                        <div className={classes.textFieldPad} />
                        <p>
                          By creating an account, you agree to our{" "}
                          <a
                            href="https://www.upguest.io/terms-conditions"
                            style={{ color: "blue", textDecoration: "underline" }}
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>.
                        </p>
                      </div>
                      <br />
                      <br />
                      <Button
                        className={classes.sectionButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={sellerRegisterHandler}
                        disabled={disableSignup}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </TypedSiteQuery>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText
              primary={"Web App"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#white-label-web-app";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"QR Code"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#retails-qr-code";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"Upsells"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#calculate-upsell-revenue";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"Log in"}
              onClick={() => {
                handleDrawerClose();
                location.href = dashboardUrl;
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Email: ${contactEmail}`} />
          </ListItem>
          <ListItem button>
            <Button
              type="submit"
              className={classes.sectionButton}
              variant="contained"
              color="primary"
              onClick={() => {
                handleDrawerClose();
                location.hash = "#get-started";
              }}
            >
              Sign Up
            </Button>
          </ListItem>
          <ListItem button>
            <Button
              type="submit"
              className={classes.sectionButton}
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleContactNowClick(e);
              }}
            >
              Contact Now
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </SEOWrapper>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  header: {
    width: "100%",
    height: "10vh",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10%",
    [theme.breakpoints.down("xs")]: {
      height: "5rem",
    },
  },
  link: {
    display: "inline-block",
    padding: "0 0.5rem",
  },
  logo: {
    height: "6vw",

    "& > div": {
      height: "100%",

      "& svg": {
        height: "100%",
        width: "auto",

        [theme.breakpoints.down("xs")]: {
          height: "5rem",
          transform: "translateY(-32%)",
        },

        "& path": {
          fill: "#FFBD59",
        },
      },
    },
  },
  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "20px",
  },
  section: (props) => ({
    margin: "10vh 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0 10%",
    columnGap: "5%",
    flexDirection: props.mirror ? "row-reverse" : "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }),
  infoContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  imageContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  sectionName: {
    fontFamily: "sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#3D91EF",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  sectionTitle: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    fontWeight: 900,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
    },
  },
  firstSectionTitle: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: 900,
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
    },
  },
  sectionText: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      lineHeight: "1.2rem",
      width: "100%",
    },
  },
  sectionImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  sectionButton: {
    borderRadius: "10px",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#3D91EF",
    padding: "0.8rem",
    width: "fit-content",
    "& .MuiButton-label": {
      fontFamily: "sans-serif",
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: "1.4rem",
    },
  },
  firstSectionContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FEFCFC",
    flexDirection: "column",
    padding: "10vh 10%",
    [theme.breakpoints.down("xs")]: {
      padding: "5vh 10% 10vh 10%",
    },
  },
  firstSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    columnGap: "5%",
    marginBottom: "5vh",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  firstSectionName: {
    fontFamily: "sans-serif",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: "bold",
    color: "#3D91EF",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "2.0rem",
      lineHeight: "2.0rem",
    },
  },
  videoContainer: {
    height: "40vh",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  video: {
    height: "100%",
    width: "100%",
  },
  pointLinesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  pointLine: {
    display: "flex",
    marginBottom: "5px",
    alignItems: "center",
  },
  pointImage: {
    height: "1.6rem",
    objectFit: "contain",
    marginRight: "10px",
  },
  imageList: {
    flexWrap: "nowrap",
  },
  hotelsRoot: {
    width: "70%",
    "& > div": {
      height: "auto",
      alignItems: "center",
      "& .alice-carousel__wrapper": {
        height: "10vh",
        "& li": {
          height: "100%",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
            opacity: "0.3",
          },
        },
      },
      "& > div": {
        margin: "5px",
        "& > div": {
          backgroundColor: "unset",
        },
      },
    },
  },
  headerLink: {
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  drawer: {
    width: "100vw",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "100vw",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
  },
  cardContainer: {
    margin: "2vw 4vw 3vw",
    [theme.breakpoints.down("md")]: {
      margin: "1.5vw 3vw 1.5vw",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "4vw 2vw",
    },
  },
  card: {
    [theme.breakpoints.down("xs")]: {
      padding: 12,
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: "0.675rem",
    left: 10,
    position: "absolute",
    top: 5,
  },
  errorValidation: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    fontWeight: "bold",
    paddingTop: "0.5rem",
    alignSelf: "flex-start",
  },
  paymentDetailsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2vw",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      marginTop: "0",
    },
    "& > p": {
      marginBottom: "20px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "10px",
    "& > div": {
      width: "100%",
    },
    "& .MuiInputLabel-formControl": {
      color: "#A8ABA7",
    },
    [theme.breakpoints.down("xs")]: {
      "&:first-child": {
        marginTop: "3vw",
      },
      marginBottom: "3vw",
    },
  },
  textFieldPad: {
    height: "1.25rem",
  },
  hotelFormContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "#f5f5f5",
    padding: "10vh 10%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  finalSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  hotelRevenueContainer: {
    height: "100%",
    width: "50%",
    padding: "10vh 5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  singUpFormContainer: {
    width: "100%",
    background: "white",
    padding: "50px 5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  horizontalInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& > div": {
      width: "30%",
      "& > div": {
        background: "rgba(0, 0, 0, 0.04)",
        "& > input": {
          background: "rgba(0, 0, 0, 0)",
        },
      },
      "& > .Mui-disabled": {
        background: "#f5f5f5",
        "& > input": {
          color: "#3D91EF",
        },
        "& > .MuiInputAdornment-filled": {
          "& > p": {
            color: "#3D91EF",
          },
        },
      },
    },
  },
  labelText: {
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  emailUpsellInput: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div": {
      width: "70%",
    },
  },
}));

export default withApollo(withRouter(SignUpForm));
