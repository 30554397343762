import React from "react";
// import ReactSVG from "react-svg";
// import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// import locationSrc from '../../images/location.png';
import PassTypeIcon from "src/components/PassTypeIcon";
import infoCircleImage from "images/information-circle-icon.png";
// import mapIconPath from "images/map-icon.svg";
// import shaSrc from "images/SHA.png";
import { Rating } from "@material-ui/lab";
import { rbrColors } from "src/app/ThemeProvider";
/*
const ShaElement = ({ certified }) => {
  if (!certified) {
    return null;
  } else {
    return (
      <Tooltip
        title={
          "The hygiene and safety standard of this hotel is certified by Thailand’s Ministry of Public Health"
        }
        placement="right"
      >
        <img src={shaSrc} />
      </Tooltip>
    );
  }
};
*/
const HotelHeader = ({
  generalTermsText,
  generalTermsTitle,
  hotelDescription,
  hotelName,
  locationName,
  shaCertified,
  starRating,
  passTypes,
}) => {
  const classes = useStyles();
  const ratingsEnums = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 };

  // if (!isMobile) {
  return (
    <div className={classes.container}>
      <div className={classes.fixContainer}>
        <div className={classes.fixLeftContainer}>
          <div className={classes.hotelHead}>
            <div className={classes.hotelMainInfoContainer}>
              <div className={classes.hotelName}>{hotelName}</div>

              <div className={classes.hotelFoot}>
                <div className={classes.fixStars}>
                  <Rating
                    className={classes.img}
                    max={ratingsEnums[starRating]}
                    value={ratingsEnums[starRating]}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div>
              {/*<ShaElement certified={shaCertified} />*/}
            </div>
          </div>

          {/*{locationName && (*/}
          {/*  <a href={"#map"}>*/}
          {/*    <ReactSVG className={classes.svgIcon} path={mapIconPath} />*/}
          {/*    {locationName}*/}
          {/*  </a>*/}
          {/*)}*/}

          <PassTypeIcon passTypeLinks={passTypes?.map((t) => t?.icon)} />
        </div>
      </div>

      <div className={classes.hotelDescription}>{hotelDescription}</div>

      {generalTermsTitle && generalTermsText && (
        <div className={classes.generalTerms}>
          <div className={classes.generalTermsTitleSection}>
            <img className={classes.generalTermsImage} src={infoCircleImage} />
            <div
              className={classes.generalTermsTitle}
              dangerouslySetInnerHTML={{ __html: generalTermsTitle }}
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: generalTermsText }} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  secondTitleLine: {
    marginTop: "-0.75rem",
  },
  mover: {
    flexGrow: 1,
  },
  locationDiv: {
    marginTop: "-1rem",
  },
  img: {
    verticalAlign: "middle",
    color: "#ffe069",
    paddingLeft: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem",
    },
  },
  svgIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "8px",
    "& svg": {
      height: "20px",
      width: "20px",
      "& path": {
        fill: rbrColors.mainColor,
      },
    },
  },
  img3: {
    width: "3.3rem",
    [theme.breakpoints.down("xs")]: {
      width: "4.2rem",
      position: "relative",
      left: "79%",
      top: "-1.3rem",
    },
  },
  stars: {
    width: "40%",
    height: "48px",
    display: "inline-block",
    marginTop: "-10rem",
    marginLeft: "-3.5rem",

    img: {
      height: "48px",
    },
  },
  container: {
    marginLeft: "3rem",

    [theme.breakpoints.down("sm")]: {
      margin: "1em 0",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  hotelName: {
    color: "#000000",
    fontSize: "1.2rem",
    fontWeight: "bold",

    textAlign: "left",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.5rem",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  hotelDescription: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "0.9rem",
    lineHeight: "1.5",
    textAlign: "justify",
  },
  buttonReviewsContainer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "none",
      justifyContent: "center",
    },
  },
  buttonReviewsText: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },

  fixContainer: {
    display: "flex",
  },
  fixLeftContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  fixStars: {
    height: "2em",
    display: "flex",
    alignItems: "center",
  },
  hotelHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  hotelMainInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
  },
  hotelFoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  generalTerms: {
    padding: "12px",
    borderRadius: "5px",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#EDB858",
    backgroundColor: "#FDF7E7",
    width: "100%",
    fontSize: "1rem",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.2rem",
    color: "#3D3D3D",
  },
  generalTermsTitleSection: {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  generalTermsTitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    padding: "0 5px",
    color: "black",
  },
  generalTermsImage: {
    width: "40px",
    height: "40px",
  },
}));

export default HotelHeader;
