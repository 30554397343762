import React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { rbrColors } from "src/app/ThemeProvider";
// import * as fbq from "../../../lib/fbq.js";
// import * as gtag from "../../../lib/gtag.js";
// import moment from "moment";

// types are incorrect here. Correct them if possible
interface BookNowButtonProps extends RouteComponentProps {
  adultQuantity: number;
  childQuantity: number;
  hotelName: any;
  mainHotelImage: string;
  childTotal: any;
  adultTotal: any;
  selectedDate: any;
  passTypeName: string;
  currency: string;
  locationName: string;
  total: any;
  voucherTitle: string;
  pricePerAdult: string;
  pricePerChild: string;
  sellerId: string;
  venuePassId: string;
  timeSlotId: string;
  timeSlot: string;
  numberOfTimeslots: number;
}

const BookNowButton = (props: BookNowButtonProps) => {
  const classes = useStyles();

  const bookNowHandler = () => {
    // (async () => {
    //   // Report facebook viewed product
    //   const viewData = {
    //     value: props.sellingPrice,
    //     currency: props.currency,
    //     affiliation: "DailyPass",
    //     items: [
    //       {
    //         id: venuePassId,
    //         name: `${props.hotelName} ${props.currency} Voucher`,
    //         price: props.sellingPrice,
    //         quantity: 1,
    //       },
    //     ],
    //   };
    //   await fbq.addToCart({
    //     value: viewData.value,
    //     currency: viewData.currency,
    //     contents: viewData.items,
    //     content_type: "hotel",
    //   });
    //
    //   // Report facebook initiate Checkout
    //   const currency = viewData.currency;
    //   const numItems = viewData.items.length;
    //   const lineItems = viewData.items;
    //   const sellingPrice = viewData.value;
    //   const numAdults = props.adultQuantity;
    //   const numChildren = props.childQuantity;
    //   const city = props.locationName;
    //
    //   const checkinDate = moment(props.selectedDate).format("YYYY-MM-DD");
    //   const region = city;
    //   const country = "Thailand";
    //
    //   const initiateCheckoutData = {
    //     contents: lineItems,
    //     currency,
    //     num_items: numItems,
    //     value: sellingPrice,
    //     content_type: "hotel",
    //     checkin_date: checkinDate,
    //     checkout_date: checkinDate,
    //     content_ids: [venuePassId],
    //     city,
    //     region,
    //     country,
    //     num_adults: numAdults,
    //     num_children: numChildren,
    //     affiliation: "DailyPass",
    //   };
    //   await fbq.initiateCheckout(initiateCheckoutData);
    //
    //   // Report Google begin checkout
    //   const googleCheckoutData = {
    //     sellingPrice,
    //     currency,
    //     affiliation: "DailyPass",
    //     items: viewData.items,
    //   };
    //   gtag.event("begin_checkout", googleCheckoutData);
    // })();
    localStorage.removeItem("checkoutInfo");
    localStorage.setItem(
      "checkoutInfo",
      JSON.stringify({
        adultQuantity: props.adultQuantity,
        childQuantity: props.childQuantity,
        hotelName: props.hotelName,
        childTotal: props.childTotal,
        adultTotal: props.adultTotal,
        selectedDate: props.selectedDate,
        passTypeName: props.passTypeName,
        currency: props.currency,
        locationName: props.locationName,
        total: props.total,
        voucherTitle: props.voucherTitle,
        pricePerAdult: props.pricePerAdult,
        pricePerChild: props.pricePerChild,
        seller: props.sellerId,
        venuePass: props.venuePassId,
        mainHotelImage: props.mainHotelImage,
        timeSlotId: props.timeSlotId,
        timeSlot: props.timeSlot,
        numberOfTimeslots: props.numberOfTimeslots,
      })
    );
    props.history.push("/checkout/guest-info/");
  };

  return (
    <div
      className={classes.bookNowButtonContainer}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
    >
      <Button
        classes={{
          root: classes.bookNowButton,
          label: classes.buttonLabel,
        }}
        onClick={() => bookNowHandler()}
      >
        <FormattedMessage id="text.booknow" />
      </Button>
    </div>
  );
};

const adjustColor = (color, amount) =>
  "#" +
  color
    .replace(/^#/, "")
    .replace(/../g, (color) =>
      ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
    );

export const useStyles = makeStyles((theme) => ({
  bookNowButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bookNowButton: {
    backgroundColor: rbrColors.turquoise,
    color: "#ffffff !important",
    margin: "1rem",
    padding: "1rem",
    borderRadius: "50px",
    width: "10.5rem",
    "&:hover": {
      backgroundColor: `${adjustColor(rbrColors.turquoise, -10)}`,
    },
  },
  buttonLabel: {
    color: "#ffffff !important",
  },
}));

export default withRouter(BookNowButton);
