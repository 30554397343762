import "./scss/index.scss";

import * as React from "react";
import { SEOWrapper } from "@temp/components/SEO";

class TermsAndConditions extends React.Component<{}> {
  render() {
    return (
      <SEOWrapper pathname={window.location.pathname}>
        <div className="privacy">
          <br />
          <br />
          <p>
            <span style={{ fontSize: "x-large" }}>
              <strong>TERMS & CONDITIONS OF USE</strong>
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span style={{ fontSize: "small" }}>
              <strong>Last updated Feb 9, 2021</strong>
            </span>
          </p>
          <p>&nbsp;</p>
          <h2 className="western">
            <span style={{ fontSize: "x-large", fontWeight: "bold" }}>
              <strong>AGREEMENT TO TERMS</strong>
            </span>
          </h2>
          <p>
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and UpGuest Pte. Ltd., doing business as Upguest ("Upguest",
            "Upguest", "we", "us", or "our"), concerning your access to and use
            of the Upguest.com website as well as any other media form, media
            channel, mobile website or mobile application related, linked, or
            otherwise connected thereto (collectively, the "Website" or "Site").
            You agree that by accessing the Site, you have read, understood, and
            agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
            WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <br />
          <br />
          <p>
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason. We will alert you about any changes by updating the
            “Last updated” date of these Terms of Use, and you waive any right
            to receive specific notice of each such change. It is your
            responsibility to periodically review these Terms of Use to stay
            informed of updates. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.
          </p>
          <p>
            This website (the “Website”) and associated services (“services”) is
            published and maintained by Upguest, including UpGuest Pte. Ltd. and
            its affiliates (“Upguest”, “we”, or “us”)
          </p>
          <p>
            PLEASE READ AND REVIEW THE FOLLOWING TERMS OF USE (“AGREEMENT”)
            CAREFULLY BEFORE USING THIS WEBSITE. BY ACCESSING OR USING THE
            Upguest.COM WEBSITE AND ANY SERVICES PROVIDED BY US, YOU AGREE TO
            BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS
            INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS
            AND CONDITIONS, DO NOT USE THE Upguest.COM WEBSITE APPLICATION OR
            ANY SERVICES PROVIDED BY US.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>1. AGREEMENT BETWEEN CUSTOMER AND Upguest.COM</strong>
            </span>
          </h2>
          <p>
            This Website and associated services contained herein are offered to
            you based on your acceptance without modification of all the terms,
            conditions, and notices set forth below. Your access and/or use of
            this Website in any manner, implies that you agree to be bound by
            the Agreement. We reserve the right at any time, at our sole
            discretion, to change or otherwise modify the Agreement without
            prior notice, and your continued access or use of this Website
            signifies your acceptance of the updated or modified Agreement.
            These Terms and Conditions of Use shall be governed by and construed
            in accordance with the laws of Singapore, without reference to its
            conflicts of law rules.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>2. USE OF THE WEBSITE AND ASSOCIATED PRODUCTS</strong>
            </span>
          </h2>
          <p>
            As a condition of your use of this Website, you warrant that (i) you
            are at least 18 years of age; (ii) you possess the legal authority
            to enter into a binding legal obligation. Upguest reserves the right
            to deny access to anyone to this Website and to the services we
            offer.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>3. SCOPE OF USE</strong>
            </span>
          </h2>
          <p>
            These terms of service (the “Terms”) apply to your access and use of
            the website located at Upguest.com and any derivative of the
            website accessed through any affiliate partner sites (collectively,
            the “Application”) provided by Upguest and any feature, content,
            tools and services accessible by means of the Application. The
            Application and such services are collectively referred to as the
            “Service”. The Terms do not alter in any way the terms or conditions
            of any other agreement you may have with Upguest for the Service (or
            any part thereof). If you are using the Service on behalf of an
            entity, you represent and warrant that you are authorized to accept
            the Terms on such entity’s behalf, and that such entity agrees to
            indemnify you and Upguest for violations of the Terms.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>4. MODIFICATION OF TERMS</strong>
            </span>
          </h2>
          <p>
            The terms and conditions contained herein may be altered at any time
            and in our sole discretion. Any changes or modifications will be
            effective immediately upon the posting of such revisions on our
            website. Specific notice of said changes is waived as a condition of
            this Agreement. If you do not agree to the amended Terms, you must
            stop using the Service.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>5. PRIVACY AND USE OF PERSONAL INFORMATION</strong>
            </span>
          </h2>
          <p>
            Information Processing: Upguest conducts business in Singapore, as
            such your personal data will be processed within the legal
            jurisdiction of Singapore. The data protection and privacy
            regulations may not offer the same level of protection as in other
            parts of the world, such as the European Union. Use of this Website
            implies consent to the collection and/or processing in Singapore. By
            providing personal information to Upguest, you unambiguously consent
            to the collection and processing of such information in Singapore.
            Cookies: Upguest.com web server automatically recognizes and
            stores information known as cookies, which contain information such
            as the visitor’s IP address/domain name combination. Upguest may use
            this information to enhance the usability features of its website.
            Personal information: Upguest may ask you to provide personal or
            individually identifiable information. If you choose not to provide
            your personal information, you may not be able to participate in
            certain activities contained on the Website. Use of Information: by
            providing Upguest with personal information, you shall have the
            right, on reasonable prior notice, to inspect, change, amend, or
            delete such information. Upguest will not share the personal
            information you provide except with your consent. Upguest takes
            appropriate measures to safeguard against the unauthorised
            disclosure of your personal information. Upguest makes no guarantees
            that the personally identifiable information that we collect will
            not be disclosed in a manner that is inconsistent with this privacy
            notice.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>6. DEVICE USAGE TERMS AND CONDITIONS</strong>
            </span>
          </h2>
          <p>
            You acknowledge and agree that your use of the Service must also be
            in accordance with the usage rules established by your mobile device
            platform or service provider.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>7. REGISTRATION DATA</strong>
            </span>
          </h2>
          <p>
            You agree to: (i) provide accurate, current and complete information
            about you as may be prompted by any signup, login and/or
            registration forms made available via the Service (“Registration
            Data”); (ii) maintain and promptly update the Registration Data, and
            any other information you provide to Upguest, in order to keep it
            accurate, current and complete; and (iii) accept all risks of
            unauthorized access to the Registration Data and any other
            information you provide to Upguest.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>8. GRANT AND RESTRICTIONS</strong>
            </span>
          </h2>
          <p>
            Subject to the terms, conditions and limitations set forth in the
            Terms, Upguest grants you a non- exclusive, non-transferable and
            revocable license to use the Application on any mobile device that
            you own or control. The terms of the license will also govern any
            upgrades provided by Upguest that replace and/or supplement the
            original Application, unless such upgrade is accompanied by a
            separate license, in which case the terms of that license will
            govern.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>9. THIRD PARTY USAGE</strong>
            </span>
          </h2>
          <p>
            You agree not to do, or authorize or permit any third-party to do,
            any of the following: (i) distribute or make the Application
            available over a network where it could be used by multiple devices
            at the same time; (ii) rent, lease, lend, sell, redistribute or
            sublicense the Application; (iii) copy, decompile, reverse engineer,
            disassemble, attempt to derive the source code of, modify, or create
            derivative works of the Application, any updates, or any part
            thereof (except as and only to the extent any of the foregoing
            restrictions are prohibited by applicable law); or (iv) remove,
            alter or obscure any copyright, trademark or other proprietary
            rights notice on or in the Application. If you violate any of the
            foregoing restrictions, your use of the Application will cease
            immediately and you will have infringed the copyright and other
            rights of Upguest, which may subject you to prosecution and damages.
            Upguest reserves all rights not expressly granted to you in the
            Terms.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>10. CONSENT TO USE OF DATA</strong>
            </span>
          </h2>
          <p>
            You agree that Upguest may collect and use technical data, personal
            information and related information in connection with your use of
            the Application including, but not limited to, contact information
            and technical information about your device, system and application
            software, and peripherals, that is gathered periodically to
            facilitate the features and functionality of the software updates,
            product support and other services. You also consent to our
            communicating with you about the Application or in connection with
            the features, functions and activities contained in the Application.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>11. COMPATIBILITY WITH MOBILE DEVICES</strong>
            </span>
          </h2>
          <p>
            Upguest does not warrant that the Service will be compatible or
            interoperable with your mobile device or any other hardware,
            software or equipment installed on or used in connection with your
            mobile device.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>12. CARRIER CHARGES</strong>
            </span>
          </h2>
          <p>
            You acknowledge and understand that the Service requires and
            utilizes phone service, data access and text messaging capabilities.
            Carrier rates for phone, data and text messaging may apply and you
            are responsible for any such charges.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>13. PRODUCT DESCRIPTIONS</strong>
            </span>
          </h2>
          <p>
            Upguest attempts to be as accurate as possible. However, Upguest does
            not warrant that the Service is complete, reliable, current or
            error-free. If you have reason to believe that a Upguest.com
            offering you purchased through the Service does not meet the claims
            advertised within the Service, your sole remedy is to not consummate
            your Upguest.com offering and contact Upguest Customer Service
            immediately, and in any case no later than three business days
            following the check-in date. Upguest will make a determination as to
            the merits of your claim in our sole discretion.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>14. ADVERTISED PRICES</strong>
            </span>
          </h2>
          <p>
            Upguest makes no guarantee that the prices advertised through the
            Service represent (i) the lowest price for a Upguest.com offering
            in every area on any particular day or (ii) the published price for
            a Upguest.com offering on another website or publication not
            affiliated with Upguest in every area on any particular day.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>15. HONORING YOUR PAYMENT COMMITMENTS</strong>
            </span>
          </h2>
          <p>
            When you make a purchase via the Service you are representing to us
            that: (i) any credit information you supply is true and complete;
            (ii) charges incurred by you will be honored by your credit card
            company; and (iii) you will pay the charges incurred by you at the
            posted prices, including any applicable taxes. By using the Service
            you acknowledge that: (a) the listed prices are inclusive of the
            service offering as negotiated by Upguest plus service fees and
            taxes, where applicable; (b) Upguest reimburses the hotel suppliers
            and relevant taxing authorities where applicable for the taxes due
            for the Upguest offer; and (c) Where an alternative cancellation
            policy is not explicitly stated for a particular offer, Upguest
            typically maintains a refund policy up until midnight before the day
            of check-in. No refunds will be issued under any circumstances for
            cancellations made on the day of check-in. (d) Alternative
            cancellation policy may apply if explicitly stated in booking
            details, and are subjected to the conditions in said policy. For
            example, we may provide free cancellation for certain Upguest.com
            purchases via affiliate partners’ sites, provided the cancellation
            is within the cancellation period, among other conditions.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>16. HOTEL OVERBOOKINGS; AGENT FEE</strong>
            </span>
          </h2>
          <p>
            If a hotel has overbooked and is sold out, it is the hotel’s
            responsibility to find you an alternative. This is our agreement
            with each hotel and our suppliers. We will endeavor to enforce our
            agreements with hotels, and while we are not responsible to you if
            the hotel does not, for whatever reason, find you an available
            alternative, we ask that you contact us immediately in the event
            that the hotel is unable to find you an available alternative so
            that we may assist in the process.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>17. Upguest CREDITS</strong>
            </span>
          </h2>
          <p>
            Upguest booking credit (i.e. Upguest vouchers) is not redeemable
            for cash or cash equivalents, is non- transferable and may not be
            applied towards prior purchases. Upguest vouchers can only be used
            on the booking date you selected during the booking process. Any
            balance exceeding the redeemable amount of the Upguest voucher is
            to be settled directly at the hotel. Any balance left from the
            redeemable amount of the Upguest voucher cannot be exchanged for
            cash or credit. Other restrictions may apply. Unless you paid us in
            full for any booking credits, we reserve the right to rescind any
            booking credits subject to reasonable notice to you. It is not
            allowed to redeem vouchers originating from accounts, which you have
            created yourself. It is not allowed to invite accounts that use the
            same credit card as you do. It is not allowed to invite users that
            use the same device as you do. It is not allowed to redeem loyalty
            program credits for bookings purchased via metasearch or affiliate
            websites. It is not allowed to apply referral or other vouchers to
            bookings purchased via metasearch or affiliate websites.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>18. ELECTRONIC COMMUNICATIONS</strong>
            </span>
          </h2>
          <p>
            When you use the Service or send emails to us, you are communicating
            with us electronically, and you consent to receive communications
            from us electronically. We will communicate with you by e-mail or
            providing notices via the Service. You agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing. Any such emails may include marketing
            and promotional content.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>19. THIRD-PARTY INTERACTIONS</strong>
            </span>
          </h2>
          <p>
            Your use of the Application and your contact, interaction, or
            dealings with any third-parties arising out of your use of the
            Service is solely at your own risk. You acknowledge and agree that
            Upguest is not responsible or liable in any manner for any loss,
            damage or harm of any sort incurred as the result of the Service.
            The hotels and other suppliers providing accommodations through the
            Service are independent contractors and not agents or employees of
            Upguest. Upguest is not responsible, and shall not be held liable for
            the acts, errors, omissions, representations, warranties, breaches
            or negligence of any such suppliers for any personal injuries,
            death, property damage, loss, theft or other damages or expenses
            resulting therefrom.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>20. USER CONTENT AND YOUR CONDUCT</strong>
            </span>
          </h2>
          <p>
            The Service includes forums and other interactive areas or services
            (“Interactive Areas”) in which you or other users can create, post
            or share content, materials, data, information, text, photos and/or
            other materials (“User Content”). You are solely responsible for
            your use of such Interactive Areas and the User Content you share.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>21. PROHIBITED USE</strong>
            </span>
          </h2>
          <p>
            You agree not to post, upload, transmit, distribute, store, create
            or otherwise publish through the Service any of the following: User
            Content that is libelous, defamatory, profane, obscene,
            pornographic, sexually explicit, indecent, suggestive, violent,
            harassing, hateful, threatening, offensive, discriminatory, abusive,
            inflammatory, fraudulent, deceptive or otherwise objectionable; User
            Content that is illegal or unlawful, that would constitute,
            encourage or provide instructions for a criminal offense, or
            otherwise create liability or violate any local, state, national or
            international law;
          </p>
          <p>
            User Content that may infringe or violate any patent, trademark,
            trade secret, copyright, right of privacy, right of publicity or
            other intellectual property right or other right of any party;
            Unsolicited promotions, political campaigning, or commercial
            messages (SPAM) or any chain messages or User Content designed to
            deceive or trick the user of the Service; Private information of any
            third-party including, but not limited to, addresses, phone numbers,
            email addresses, Social Security numbers and credit card numbers;
            and Viruses, corrupted data or other harmful, disruptive or
            destructive files or code, script or other software designed to
            automate any functionality on the Service without Upguest’s express
            written consent. You further agree that you are solely responsible
            for your conduct while using the Service, and you agree that you
            will not do any of the following in connection with the Service or
            the users of the Service:
          </p>
          <ul>
            <li>
              Use the Service in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying the
              Service or that could damage, disable, overburden or impair the
              functioning of the Service in any manner;
            </li>
            <li>
              Collect any personally identifiable information about other users
              (except as specifically authorized by Upguest), or intimidate,
              threaten, stalk, or otherwise harass or cause discomfort to other
              users of the Service;
            </li>
            <li>Use the Service for any commercial purpose;</li>
            <li>
              Use the Service for any illegal or unauthorized purpose or engage
              in, encourage, or promote any illegal activity, or any activity
              that violates the Terms or any other rules or polices established
              from time-to-time by Upguest;
            </li>
            <li>
              Create an account, post any content, or otherwise use the Service
              if you are not, at least, 18 years of age;
            </li>
            <li>Modify, adapt, hack or emulate the Service;</li>
            <li>
              Circumvent or attempt to circumvent any filtering, security
              measures or other features designed to protect the Service or
              users of the Service or third- parties; and Infringe upon or
              violate the rights of Upguest, its users or any third-party.
              Accounts on the application are personal. It is not allowed to
              create more than one account per person.
            </li>
          </ul>
          <p>
            Upguest takes no responsibility and assumes no liability for any user
            conduct or for any User Content posted, stored or made available via
            the Service, nor is Upguest liable for any mistakes, defamation,
            slander, libel, omissions, falsehoods, obscenity, pornography or
            profanity you may encounter. Your use of Interactive Areas and the
            Service is at your own risk. Enforcement of the user content and
            conduct rules set forth in the Terms is solely at Upguest’s
            discretion, and failure to enforce such rules in some instances does
            not constitute a waiver of our right to enforce such rules in other
            instances. In addition, these rules do not create any private right
            of action on the part of any third- party or any reasonable
            expectation that the Service will not contain any content that is
            prohibited by such rules.
          </p>
          <p>
            We reserve the right to investigate complaints or reported
            violations of the Terms and to take any action we deem appropriate,
            including, but not limited to, reporting any suspected unlawful
            activity to law enforcement officials, regulators, or other
            third-parties and disclosing any information necessary or
            appropriate to such persons or entities relating to your
            Registration Data, usage history, posted materials, IP addresses and
            traffic information.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>22. THIRD-PARTY CONTENT</strong>
            </span>
          </h2>
          <p>
            Upguest may provide third-party content via the Service and may
            provide links to Web pages and content of third-parties
            (collectively, the “Third-Party Content”) as a service to those
            interested in this information. Upguest does not control, endorse or
            adopt any Third-Party Content and makes no representation or
            warranties of any kind regarding the Third-Party Content including,
            but not limited to, its accuracy or completeness. You acknowledge
            and agree that Upguest is not responsible or liable in any manner for
            any Third-Party Content and undertakes no responsibility to update
            or review any Third-Party Content. Users use such Third-Party
            Content contained therein at their own risk.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>
                23. ADVERTISEMENTS AND PROMOTIONS; THIRD-PARTY PRODUCTS AND
                SERVICES
              </strong>
            </span>
          </h2>
          <p>
            Upguest may run advertisements and promotions from third-parties via
            the Service or may otherwise provide information about or links to
            third-party products or services via the Service. Your business
            dealings or correspondence with, or participation in promotions of,
            such third-parties, and any terms, conditions, warranties or
            representations associated with such dealings or promotions, are
            solely between you and such third-party. Upguest is not responsible
            or liable for any loss or damage of any sort incurred as the result
            of any such dealings or promotions or as the result of the presence
            of such non- Upguest advertisers or third-party information
            accessible via the Service.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>24. INTELLECTUAL PROPERTY NOTICES</strong>
            </span>
          </h2>
          <p>
            All materials on this Website, including but not limited to the
            Content are protected by copyright under Singapore copyright law,
            international conventions, and other copyright laws and are the
            property of Upguest, or its subsidiaries, affiliated companies or
            third party licensors. The Service (including, but not limited to,
            the Application) contains the valuable proprietary content of Upguest
            and its licensors and is protected by copyright and other
            intellectual property laws and treaties. You agree not to use the
            Service (including, but not limited to, the Application) except in
            its intended manner in accordance with the terms and conditions of
            the Terms. The Upguest and its “cloud” logo, as well as Upguest and
            its logo and icons are trademarks or registered trademarks of Upguest
            Pte. Ltd. in Singapore and in numerous international jurisdictions.
            These marks may not be reproduced or used without Upguest’s prior
            written permission. You may not use any metatags or any other
            “hidden text” utilizing “Upguest” or any other name, trademark or
            product or service name of Upguest without our prior written
            permission. In addition, the look and feel of the Service, including
            all page headers, custom graphics, button icons and scripts, is the
            service mark, trademark and/or trade dress of Upguest and may not be
            copied, imitated or used, in whole or in part, without our prior
            written permission. All other trademarks, registered trademarks,
            product names and company names or logos made available via the
            Service are the property of their respective owners. Reference to
            any products, services, processes or other information, by trade
            name, trademark, manufacturer, supplier or otherwise does not
            constitute or imply endorsement, sponsorship or recommendation
            thereof by us.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>25. TERMINATION OR MODIFICATION OF APPLICATION</strong>
            </span>
          </h2>
          <p>
            Upguest reserves the right to change, suspend, remove, discontinue or
            disable access to the Service (including, but not limited to, the
            Application) at any time without notice. In no event will Upguest be
            liable for the removal of or disabling of access to any portion or
            feature of the Service (including, but not limited to, the
            Application).
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>26. TERMINATION</strong>
            </span>
          </h2>
          <p>
            If you breach any of the terms or conditions of the Terms or Upguest
            discontinues the Application, the Terms will automatically
            terminate. In the event of the termination of the Terms for any
            reason: (i) the license granted to you in the Terms will immediately
            terminate; and (ii) you must immediately cease all use of the
            Application and destroy or erase all copies of the Application in
            your possession or control. All of the sections of the Terms will
            survive any termination of the Terms except the License section and
            the Consent to Use of Data and Mobile Communications section. Any
            use of the Application after termination is unlicensed and is in
            violation of the copyright and other rights of Upguest. Upguest and
            its affiliates reserve the right to refuse service, terminate
            accounts, remove or edit content or cancel orders at their sole
            discretion.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>27. DISCLAIMERS</strong>
            </span>
          </h2>
          <p>
            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICE
            (INCLUDING, BUT NOT LIMITED TO, THE APPLICATION) IS AT YOUR SOLE
            RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
            PERFORMANCE, SAFETY, ACCURACY AND EFFORT IS WITH YOU. TO THE MAXIMUM
            EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE (INCLUDING, BUT NOT
            LIMITED TO, THE APPLICATION) IS PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. Upguest DISCLAIMS ANY AND ALL WARRANTIES AND
            REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT
            TO THE TERMS AND THE SERVICE (INCLUDING, BUT NOT LIMITED TO, THE
            APPLICATION) WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON
            OF CUSTOM OR USAGE IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE,
            INCLUDING ANY AND ALL: (I) WARRANTIES OF MERCHANTABILITY; (II)
            WARRANTIES OF FITNESS OR SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT
            Upguest KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED OR IS OTHERWISE
            AWARE OF ANY SUCH PURPOSE); AND (III) WARRANTIES OF NON-INFRINGEMENT
            OR CONDITION OF TITLE. Upguest DOES NOT WARRANT THAT THE FUNCTIONS
            CONTAINED IN THE APPLICA TION WILL BE ACCURA TE OR MEET YOUR
            REQUIREMENTS, THA T THE OPERATION OF THE APPLICATION WILL BE
            UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE APPLICATION WILL
            BE CORRECTED. NO ORAL OR WRITTEN INFORMATION, GUIDELINES OR ADVICE
            GIVEN BY Upguest OR ITS AUTHORIZED REPRESENTATIVE WILL CREATE A
            WARRANTY.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>28. INDEMNIFICATION</strong>
            </span>
          </h2>
          <p>
            You agree, at your sole expense, to defend, indemnify and hold us,
            our independent contractors, service providers and consultants, and
            their respective directors, employees and agents, harmless from and
            against any and all actual or threatened suits, actions, proceedings
            (at law or in equity), claims, damages, payments, deficiencies,
            fines, judgments, settlements, liabilities, losses, costs and
            expenses (including, but not limited to, reasonable attorney fees,
            costs, penalties, interest and disbursements) caused by, arising out
            of, resulting from, attributable to or in any way incidental to: (i)
            your conduct; (ii) your violation of the Terms or your violation of
            the rights of any third-party; or (iii) any user content.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>29. LIMITATION OF LIABILITY</strong>
            </span>
          </h2>
          <p>
            TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL Upguest BE
            LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY DIRECT, INCIDENTAL,
            SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES
            WHATSOEVER INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
            PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL
            DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR
            INABILITY TO USE THE SERVICE (INCLUDING, BUT NOT LIMITED TO, THE
            APPLICATION), HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
            (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE,
            PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER
            THEORY) AND EVEN IF Upguest HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS
            LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT WILL Upguest’S TOTAL
            LIABILITY, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
            NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY,
            STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE
            USE OF OR INABILITY TO USE THE SERVICE.
          </p>
          <h2 className="western">
            <span style={{ fontSize: "x-large" }}>
              <strong>30. RIGHT OF REFUSAL</strong>
            </span>
          </h2>
          <p>
            Upguest reserves the right not to accept customers or bookings (or in
            exceptional cases to cancel confirmed ones) at our discretion and
            for whatever (legal) reason without the need to justify such
            refusal. Typical reasons for rejecting a customer or booking
            include, but are not limited to:
          </p>
          <ul>
            <li>Breach of the Terms of Use</li>
            <li>force majeure events</li>
            <li>trade or economic sanctions</li>
            <li>(suspicions of) fraud or theft</li>
            <li>suspected criminal activity</li>
            <li>suspicious bookings</li>
            <li>
              submission by customer of misleading or erroneous information
            </li>
            <li>credit card problems, inappropriate behavior</li>
            <li>threats, insults, violence</li>
            <li>refusal to supply information</li>
            <li>practical obstacles</li>
            <li>communication problems</li>
            <li>obvious errors</li>
          </ul>
          <p>
            In case a purchase is rejected or cancelled by Upguest and a payment
            has already been made, you will receive a refund of the total
            booking value. We also reserve the right to bar (“blacklist”) users
            from the app, on a permanent or temporary basis, at our discretion.
            Any such blacklisted user must not attempt to use the App under any
            other name or through any other user.
          </p>
          <p>
            In rare cases, we reserve the right not to accept and cancel or
            reject a booking due to “obvious errors”, independent of the origin
            of such errors. For clarity, an obvious error is a mistake on the
            Site (e.g. in terms of price) which a reasonable person would not
            consider to be normal. The amount charged will be reimbursed without
            charge in such case. Whether to cancel or reject a booking for this
            reason is at Upguest’s sole discretion.
          </p>
        </div>
      </SEOWrapper>
    );
  }
}

export default TermsAndConditions;
