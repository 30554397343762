import "./scss/index.scss";

// import { History } from "history";
import * as React from "react";
// import { AlertManager, useAlert } from "react-alert";
import { RouteComponentProps } from "react-router";

// import { orderConfirmationUrl } from "../../../app/routes";
// import { defaultLocale } from "@temp/components/Locale/Locale";
/*
const completeCheckout = (
  data,
  history: History,
  clearCheckout: () => void,
  clearCart: () => void,
  alert: AlertManager
) => {
  const canProceed = !data.checkoutComplete.errors.length;
  if (canProceed) {
    const { redirectUrl } = data.checkoutComplete;
    const { token } = data.checkoutComplete.order;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      history.push({
        pathname: orderConfirmationUrl,
        state: { token },
      });
    }
  } else {
    data.checkoutComplete.errors.map((error) => {
      alert.show(
        { title: error.message },
        {
          type: "error",
        }
      );
    });
  }
};
*/
const View: React.FC<RouteComponentProps<{ token?: string, updateHeader: any }> & { updateHeader: any }> = ({
  history,
  match: {
    params: { token },
  },
  updateHeader
}) => {
  // let locale = window.localStorage.getItem("locale");
  // locale = locale ? JSON.parse(locale) : defaultLocale;

  // const alert = useAlert();
  const sellerLogo = localStorage.getItem('sellerLogo');
  console.log('BillingView: sellerLogo:', sellerLogo)
  if (sellerLogo) {
    console.log('BillingView: if sellerLogo trues')
    // updateHeader({ onlyLogo: true, customLogo: sellerLogo});
    console.log('BillingView: if sellerLogo trues done')
  }
  return (
    <>
      {/*<div className="checkout-review">*/}
      {/*  <Link*/}
      {/*    to={generatePath(paymentUrl, { token })}*/}
      {/*    className="checkout-review__back"*/}
      {/*  >*/}
      {/*    <FormattedMessage id="text.backtoprevious" />*/}
      {/*  </Link>*/}

      {/*  <div className="checkout__step checkout__step--inactive">*/}
      {/*    <span>{checkout.isShippingRequired ? "5" : "3"}</span>*/}
      {/*    <h4 className="checkout__header">*/}
      {/*      <FormattedMessage id="text.revieworder" />*/}
      {/*    </h4>*/}
      {/*  </div>*/}

      {/*  <CartSummaryReview checkout={checkout} />*/}

      {/*  <div className="checkout__content">*/}
      {/*    <div className="checkout-review__content">*/}
      {/*      <Summary*/}
      {/*        checkout={checkout}*/}
      {/*        cardData={cardData}*/}
      {/*        dummyStatus={dummyStatus}*/}
      {/*        history={history}*/}
      {/*        token={token}*/}
      {/*      />*/}
      {/*      <div className="checkout-review__content__submit">*/}
      {/*        <TypedCompleteCheckoutMutation*/}
      {/*          onCompleted={(data) =>*/}
      {/*            completeCheckout(*/}
      {/*              data,*/}
      {/*              history,*/}
      {/*              clearCheckout,*/}
      {/*              clearCart,*/}
      {/*              alert*/}
      {/*            )*/}
      {/*          }*/}
      {/*        >*/}
      {/*          {(completeCheckout, { loading }) => (*/}
      {/*            <Button*/}
      {/*              type="submit"*/}
      {/*              disabled={loading}*/}
      {/*              onClick={() =>*/}
      {/*                completeCheckout({*/}
      {/*                  variables: {*/}
      {/*                    checkoutId: checkout.id,*/}
      {/*                  },*/}
      {/*                })*/}
      {/*              }*/}
      {/*            >*/}
      {/*              {loading*/}
      {/*                ? messages[locale]["text.loading"]*/}
      {/*                : messages[locale]["text.placeorder"]}*/}
      {/*            </Button>*/}
      {/*          )}*/}
      {/*        </TypedCompleteCheckoutMutation>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default View;
