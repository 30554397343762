import * as React from "react";
// tslint:disable-next-line:no-duplicate-imports
import {useEffect} from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import "src/checkout/scss/billing.scss";
import * as H from "history";

import { IBillingPageProps } from "./types";

import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import GuestAddressForm from "@temp/checkout/components/GuestAddressForm";
import HotelImageInfo from "@temp/checkout/components/HotelImageInfo/HotelImageInfo";
import Bill from "@temp/checkout/components/Bill/Bill";
import { orderCreate } from "@temp/checkout/views/Billing/mutations";
import { orderConfirmedUrl } from "@temp/app/routes";


const Page: React.FC<WithApolloClient<IBillingPageProps> & { history: H.History, updateHeader: any }> = ({ checkoutInfo, client, history, updateHeader }) => {
  const formattedMessageIdPrefix = "src.checkout.views.Billing.Page.";
  const intl = useIntl();
  // if (createCheckoutError) alert("Vouchers were sold out"); // hardcoded to display error for no vouchers

  useEffect(() => {
    const sellerLogo = localStorage.getItem('sellerLogo');
    if (sellerLogo) {
      updateHeader({ onlyLogo: true, customLogo: sellerLogo});
    }
  }, [])

  const onSubmit = async (formData) => {
    const {
      order: { token },
    } = await orderCreate(client, {
      languageCode: intl.locale,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      recipientEmail: formData.email,
      sendEmailNews: formData.sendEmailNews,
      checkInDate: moment(checkoutInfo.selectedDate).format("YYYY-MM-DD"),
      passTypeName: checkoutInfo.passTypeName,
      adultsCount: checkoutInfo.adultQuantity,
      childrenCount: checkoutInfo.childQuantity,
      currency: checkoutInfo.currency,
      pricePerAdult: checkoutInfo.pricePerAdult,
      pricePerChild: checkoutInfo.pricePerChild,
      totalAmount: checkoutInfo.total,
      seller: checkoutInfo.seller,
      venuePass: checkoutInfo.venuePass,
      timeSlot: checkoutInfo.timeSlotId,
    });
    history.push({
      pathname: orderConfirmedUrl,
      search: `?token=${token}`,
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="billing-page">
      <div className="info-banner__container">
        <div className="info-banner__content">
          <ThumbUpAltOutlinedIcon />
          <span className="info-banner__text">
            <FormattedMessage id={`${formattedMessageIdPrefix}infoText`} />
          </span>
        </div>
      </div>
      <div className="content-container">
        <div className="data-container">
          <div className="order-summary">
            <div className="order-summary__title">
              <FormattedMessage id={`${formattedMessageIdPrefix}orderSummary`} />
            </div>
            <Bill
              voucherData={checkoutInfo}
              voucherTitle={checkoutInfo?.voucherTitle}
              currency={checkoutInfo.currency}
            />
          </div>
          <div className="divider" />
          <div className="address-form-container">
            <div className="address-form-container__title">
              <FormattedMessage id={`${formattedMessageIdPrefix}guestDetails`} />
            </div>
            <GuestAddressForm onSubmit={onSubmit} />
          </div>
        </div>
        <HotelImageInfo
          mainImageUrl={checkoutInfo?.mainHotelImage}
          hotelName={checkoutInfo?.hotelName}
          voucherTitle={checkoutInfo?.voucherTitle}
        />
      </div>
    </div>
  );
};

export default withApollo(Page);
