import * as React from "react";
// tslint:disable-next-line:no-duplicate-imports
import { createContext } from "react";

export interface MainHeaderContextInterface {
  onlyLogo?: boolean;
  customLogo?: string;
  checkInHeader?: boolean;
  update?(headerData: MainHeaderContextInterface): void;
  reset?(): void;
}

const defaultVariables = {
  onlyLogo: false,
  customLogo: "",
  checkInHeader: false
};
export const defaultContext = {
  ...defaultVariables,
  update: (headerData: {}) => null,
  reset: () => null
};

export const MainHeaderContext =
  createContext<MainHeaderContextInterface>(defaultContext);

MainHeaderContext.displayName = "MainHeaderContext";

interface ProviderProps {
  children: React.ReactNode;
}

export const MainHeaderProvider: React.FC<ProviderProps> = ({
                                                              children
                                                            }: ProviderProps) => {
  const [state, setState] = React.useState({
    onlyLogo: false,
    customLogo: "",
    checkInHeader: false
  });

  const update = (headerData: MainHeaderContextInterface) => {
    setState((prevState) => ({
      ...prevState,
      ...headerData
    }));
  };

  const reset = () => {
    setState((prevState) => ({
      ...prevState,
      ...defaultVariables
    }));
  };

  const getContext = () => ({
    ...state,
    update,
    reset
  });

  return (
    <MainHeaderContext.Provider value={getContext()}>
      {children}
    </MainHeaderContext.Provider>
  );
};
