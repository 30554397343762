import React, { FC } from "react";
import "src/checkout/scss/billing.scss";
import { IOnSubmitProps } from "@temp/checkout/types";
import {BulletStepProgress} from "@temp/components/CheckIn/BulletStepProgress";
import passportImg from "src/images/passportImg.png";
import successfulUploadImg from "src/images/SuccessfulUpload.png";

import {CircularProgress} from "@material-ui/core";
import {AddDocumentToCheckIn, finalizeCheckIn, processCheckInDocument} from "@temp/components/CheckIn/Step2/mutations";
import {CheckInButton} from "@temp/components/CheckIn/CheckInButton";


type TCheckInViewProps = {
    checkIn: (props: IOnSubmitProps) => void;
    checkInLoading: boolean;
    hotelName: string;
    mainImageUrl: string;
    client: any;
};

// styles
const passportImgStyle = {
    width: "70%",
    marginLeft: "14%",
    marginTop: "9%",
    marginBottom: "5%",
}

const imgPreview = {
    width: "70%",
    marginLeft: "15%",
}

const uploadButtonLabel = {
}

const uploadDiv = {
    marginBottom: "10%",
}


const centerContainerStyle = {
    display: 'grid',
    placeItems: 'center',
    height: '100vh', // Set the height to 100% of the viewport height
}

export enum CheckInProcessState {
    INITIAL = 'INITIAL',
    SELECTED_DOCUMENT = 'SELECTED_DOCUMENT',
    UPLOAD_SUCCESSFUL = 'UPLOAD_SUCCESSFUL',
}

const getButton = (variant: string, noUploadedDocuments: number, display: boolean = true, disabled: boolean = false, click: any = null) => {
    if (variant === 'uploadeddocuments') {
        if (noUploadedDocuments === 0) {
            return <CheckInButton display={display} buttonText1="Select" buttonText2="document"
                                  step={2}/>
        } else {
            return <CheckInButton display={display} buttonText1="Next" buttonText2="Guest"
                                  step={2}/>
        }
    } else if (variant === "submitcheckin") {
        return <CheckInButton
            display={display}
            disabled={disabled}
            buttonText1="Submit"
            buttonText2="Check-In"
            step={2}
            click={click}
            color="#71ab54"
        />
    }
}

export const CheckInViewStep2Mobile: FC<TCheckInViewProps> = ({
                                                            checkIn,
                                                            checkInLoading,
                                                            hotelName,
                                                            mainImageUrl,
                                                            client
                                                        }) => {

    const _onClick = async (event: any) => {
        event.preventDefault();

        setIsLoading(true)

        const result = await processCheckInDocument(client, {image: file})

        if (result === null || result.hotelCheckinProcessDocument.errors.length > 0) {
            setErrorMessage('Verification failed')
        } else {
            localStorage.setItem("pixlab", result.hotelCheckinProcessDocument.imageResult.resultData)
            localStorage.setItem("pixlab2", "null")
            const variables = {
                "input": {
                    firstName: localStorage.getItem("last-name"),
                    lastName: localStorage.getItem("first-name"),
                    email: localStorage.getItem("email"),
                    phone: localStorage.getItem("phone"),
                    notificationOptIn: localStorage.getItem("optinUpdates"),
                    sellerId: localStorage.getItem("seller-id"),
                    unparsed: result.hotelCheckinProcessDocument.imageResult.resultData
                }
            }
            await AddDocumentToCheckIn(client, variables)
            setNoUploadedDocuments(noUploadedDocuments + 1)
            setCheckInProcessState(CheckInProcessState.UPLOAD_SUCCESSFUL)
            setImageUrl(null);
        }
        setIsLoading(false)
    };

    const _onClickNextStep = async (event: any) => {
        event.preventDefault();
        setIsLoading(true)
        const finalizeCheckInVariables = {
            "input": {
                firstName: localStorage.getItem("last-name"),
                lastName: localStorage.getItem("first-name"),
                email: localStorage.getItem("email"),
                phone: localStorage.getItem("phone"),
                sellerId: localStorage.getItem("seller-id"),
            }
        }
        const finalizeCheckInResult =  await finalizeCheckIn(client, finalizeCheckInVariables)
        if (finalizeCheckInResult.finalizeCheckIn.errors.length === 0) {
            const length = window.location.href.length - 2
            const baseUrl = window.location.href.substring(0, length).replace('check-in', 'hotel')
            window.location.assign(`${baseUrl}`);
        } else {
            // TODO: Show notification about error
            setErrorMessage('Finalizing Checkin failed')
        }
    };

    const [checkInProcessState, setCheckInProcessState] = React.useState<CheckInProcessState>(CheckInProcessState.INITIAL);
    const [noUploadedDocuments, setNoUploadedDocuments] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [imageUrl, setImageUrl] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const handleFileUpload = (event) => {
        setErrorMessage(null);
        event.preventDefault();
        event.persist();

        const [file] = event.target.files;
        const reader = new FileReader();

        reader.onloadend = () => {
            setFile(file);
            setImageUrl(reader.result);
            setCheckInProcessState(CheckInProcessState.SELECTED_DOCUMENT)
        };

        reader.readAsDataURL(file);
    };

    const submitCheckInBtnDisabledFunc = (errorMessage, noUploadedDocuments, imageUrl) => {
        if (errorMessage) {
            return true
        }

        if (imageUrl) {
            return true
        }

        if (noUploadedDocuments > 0) {
            return false
        }
        return true;
    }

    return isLoading ? (
        <div style={centerContainerStyle}>
            <CircularProgress />
        </div>
    ) : (
        <>
            {
                checkInProcessState === CheckInProcessState.INITIAL ? (<img style={passportImgStyle} src={passportImg}/>) : null
            }

            {imageUrl && checkInProcessState !== CheckInProcessState.UPLOAD_SUCCESSFUL ? (
                <img style={imgPreview} src={imageUrl} alt="Uploaded Image"/>
            ) : null}
            {checkInProcessState === CheckInProcessState.UPLOAD_SUCCESSFUL ? (
                <img style={passportImgStyle} src={successfulUploadImg}/>
            ) : null }
            <div className="imageUploaderContainer" style={uploadDiv}>

                <label htmlFor="upload-image" style={uploadButtonLabel}>
                    { getButton( "uploadeddocuments", noUploadedDocuments, (errorMessage || imageUrl))}
                    <input
                        id="upload-image"
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleFileUpload}
                    />
                </label>
            </div>
            {(errorMessage) ? <p>{errorMessage}</p> : null}
            <CheckInButton
                display={(errorMessage || !imageUrl)}
                disabled={errorMessage || !imageUrl}
                buttonText1="Upload"
                step={2}
                click={(e) => {
                    _onClick(e)
                }}
            />

            {
                getButton(
                    "submitcheckin",
                    noUploadedDocuments,
                    submitCheckInBtnDisabledFunc(errorMessage,noUploadedDocuments, imageUrl),
                    submitCheckInBtnDisabledFunc(errorMessage,noUploadedDocuments, imageUrl),
                    (e) => {
                        _onClickNextStep(e)
                    }
                )
            }

            <BulletStepProgress activeSteps={1}/>
        </>
    )

}
