import React from "react";

import "src/checkout/scss/billing.scss";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";
import {StyleProps} from "@temp/components/SignUpForm/SignUpForm";


export const SignUpSuccessfulView =() => {
    const classes = useStyles({ mirror: false });

    return (
      <div className={classes.container}>
          <h2 className={classes.header}>Almost there!</h2>
          <p className={classes.text1}>An email has been sent to the address you registered with. Please check your inbox and confirm your email address to start using your UpGuest account.</p>
          <p className={classes.success}>Your Success Awaits,</p>
          <p className={classes.team}>UpGuest Team</p>
          <hr className={classes.hr}/>
          <p className={classes.FooterLine1}>Made by UpGuest Pte. Ltd.</p>
          <p className={classes.FooterLine2}>All Rights Reserved</p>
      </div>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    container: {
        marginLeft: "8.5%",
        marginRight: "8.5%",
        color: "#4B4A4C",
        fontFamily: "Roboto",
    },
    header: {
        marginTop: "13%",
        fontWeight: "bolder",
        fontSize: "26px",
    },
    text1: {
        marginTop: "7%",
        fontSize: "13px",
        lineHeight: "1.2rem"
    },
    success: {
        marginTop: "10%",
        fontWeight: "bolder",
    },
    team: {
        marginTop: "1%",
        fontSize: "13px",
        marginBottom: "5%",
    },
    hr: {
        color: "#AAAAAC",
    },
    FooterLine1: {
        textAlign: "center",
        color: "#AAAAAC",
        fontSize: "10px",
    },
    FooterLine2: {
        textAlign: "center",
        color: "#AAAAAC",
        fontSize: "10px",
        lineHeight: "20%",
    }
}));
