import { hot } from "react-hot-loader";
import { ThemeProvider } from "styled-components";

import { NotificationTemplate } from "@components/atoms";
import { ServiceWorkerContext, ServiceWorkerProvider } from "@components/containers";
import { SaleorProvider, useAuth } from "@sdk/react";
import { defaultTheme, GlobalStyle } from "@styles";

import { defaultDataIdFromObject, InMemoryCache } from "apollo-cache-inmemory";
import { persistCache } from "apollo-cache-persist";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { RetryLink } from "apollo-link-retry";
import * as React from "react";
import { positions, Provider as AlertProvider, useAlert } from "react-alert";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { createUploadLink } from "apollo-upload-client";

import { App } from "./app";
import { CheckoutApp } from "./checkout";
import { baseUrl as checkoutBaseUrl } from "./checkout/routes";

import { apiUrl, serviceWorkerTimeout } from "./constants";
import { history } from "./history";

import { OverlayProvider, UserProvider } from "./components";

import { authLink, invalidTokenLinkWithTokenHandlerComponent } from "./core/auth";
import { LocaleProvider } from "./components/Locale/Locale";

const { link: invalidTokenLink } = invalidTokenLinkWithTokenHandlerComponent(UserProvider);

const bHLink = new BatchHttpLink({ uri: apiUrl });

const uploadLink = createUploadLink({
  credentials: "same-origin",
  uri: apiUrl,
});

const errorWrapper = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if ("errors" in response && response.errors.length > 0) {
      try {
        throw new Error("GraphQL Error");
      } catch (err) {
        // @ts-ignore
        window.newrelic.addToTrace(response);
        // @ts-ignore
        window.newrelic.addToTrace(response.errors);
        // @ts-ignore
        response.filterField = JSON.stringify(response.errors[0].message);
        // @ts-ignore
        window.newrelic.noticeError(err, response);
      }
    }
    return response;
  });
});

const link = ApolloLink.from([
  invalidTokenLink,
  authLink,
  new RetryLink(),
  uploadLink,
  // new BatchHttpLink({uri: apiUrl}),
  errorWrapper.concat(bHLink),
]);

const cache = new InMemoryCache({
  dataIdFromObject: (obj) => {
    if (obj.__typename === "Shop") {
      return "shop";
    }
    return defaultDataIdFromObject(obj);
  },
});

const startApp = async () => {
  await persistCache({
    cache,
    storage: window.localStorage,
  });

  const apolloClient = new ApolloClient({
    cache,
    link,
  });

  const notificationOptions = {
    position: positions.BOTTOM_RIGHT,
    timeout: 2500,
  };

  const Root = hot(module)(() => {
    const Notifications = () => {
      const alert = useAlert();

      const { updateAvailable } = React.useContext(ServiceWorkerContext);

      React.useEffect(() => {
        if (updateAvailable) {
          return;
          alert.show(
              {
                actionText: "Refresh",
                content: "To update the application to the latest version, please refresh the page!",
                title: "New version is available!",
              },
              {
                onClose: () => {
                  location.reload();
                },
                timeout: 0,
                type: "success",
              }
          );
        }
      }, [updateAvailable]);

      useAuth((authenticated: boolean) => {
        if (authenticated) {
          alert.show(
              {
                title: "You are now logged in",
              },
              { type: "success" }
          );
        } else {
          alert.show(
              {
                title: "You are now logged out",
              },
              { type: "success" }
          );
        }
      });
      return null;
    };

    return (
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ApolloProvider client={apolloClient}>
              <SaleorProvider client={apolloClient}>
                <LocaleProvider>
                  <OverlayProvider>
                    <Switch>
                      <Route path={checkoutBaseUrl} component={CheckoutApp} />

                      <Route component={App} />
                    </Switch>
                    <Notifications />
                  </OverlayProvider>
                </LocaleProvider>
              </SaleorProvider>
            </ApolloProvider>
          </QueryParamProvider>
        </Router>
    );
  });

  render(
      <ThemeProvider theme={defaultTheme}>
        <AlertProvider template={NotificationTemplate as any} {...notificationOptions}>
          <ServiceWorkerProvider timeout={serviceWorkerTimeout}>
            <GlobalStyle />
            <Root />
          </ServiceWorkerProvider>
        </AlertProvider>
      </ThemeProvider>,
      document.getElementById("root")
  );

  // Hot Module Replacement API
  if (module.hot) {
    module.hot.accept();
  }
};

startApp();
