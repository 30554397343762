import "./scss/index.scss";

import React, { useState } from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { NotFound } from "../../components";

import gql from "graphql-tag";

import * as gtag from "../../lib/gtag.js";
import * as fbq from "../../lib/fbq.js";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "@temp/components/Loader";
import { SEOWrapper } from "@temp/components/SEO";

import moment from "moment";
import Line from "./components/Line";
import { getCurrencySymbol } from "@temp/utils";

import {MainHeaderContextInterface} from "@temp/app/MainHeaderProvider";
export interface NoProps {
  updateHeader: any
}

const View: React.FC<WithApolloClient<NoProps>> = ({ client, updateHeader }) => {
  const formattedMessageIdPrefix = "src.views.OrderConfirmed.View.";
  const [orderData, setOrderData] = useState(null);
  const queryParams = {
    token: "",
  };

  const queryPart = window.location.href.split("?")[1];
  const queryParamsArray = queryPart.split("&");

  for (const entry of queryParamsArray) {
    const split = entry.split("=");
    queryParams[split[0]] = split[1];
  }

  const token = queryParams.token;

  if (token.length === 0) {
    return <NotFound />;
  }

  const ORDER_BY_TOKEN = gql`
    fragment orderDetails on Order {
      id
      checkInDate
      adultsCount
      childrenCount
      totalAmount
      currency
      pricePerAdult
      pricePerChild
      recipientEmail
      passTypeName
      seller {
        sellerInfo {
          hotelName
          hotelAddress
        }
        user {
          id
          avatar {
            url
          }
        }
      }
    }

    query OrderByToken($token: UUID!) {
      orderByToken(token: $token) {
        ...orderDetails
      }
    }
  `;
  React.useEffect(() => {
    (async () => {
      const {
        data: { orderByToken: orderFinanceData },
      } = await client.query({
        query: ORDER_BY_TOKEN,
        variables: { token },
      });
      // console.log(orderFinanceData);
      if (orderFinanceData) {
        const updateHeaderData: MainHeaderContextInterface = { "customLogo": orderFinanceData?.seller?.user?.avatar?.url}
        updateHeader(updateHeaderData);

        const purchaseData = {
          transaction_id: orderFinanceData?.id,
          value: orderFinanceData?.totalAmount,
          currency: orderFinanceData?.currency,
          affiliation: "UpGuest",
          items: [
            {
              id: orderFinanceData?.venuePass?.id,
              name: `${orderFinanceData?.seller?.sellerInfo?.hotelName} ${orderFinanceData.currency} Voucher`,
              price: orderFinanceData?.pricePerAdult,
              quantity: 1,
            },
          ],
        };
        await gtag.event("purchase", purchaseData);
        await fbq.purchase({
          value: purchaseData.value,
          currency: purchaseData.currency,
          contents: purchaseData.items,
        });
        setOrderData(orderFinanceData);
      } else {
        // console.log("Didn't receive orderFinanceData");
      }
    })();
  }, []);

  const intl = useIntl();
  const voucherTitle = `${orderData?.passTypeName} Offer`;
  const dateTitle = `${intl.formatMessage({
    id: `src.checkout.components.Bill.UpguestFor`,
  })} ${moment(orderData?.checkInDate).locale(intl.locale).format("dddd, DD MMMM YYYY")}`;
  const adultCount = orderData?.adultsCount;
  const adultTitle = `${intl.formatMessage({ id: "text.adults" })} x ${orderData?.adultsCount}`;
  const adultTotalPrice = adultCount * orderData?.pricePerAdult;
  const childrenTitle = `${intl.formatMessage({ id: "text.children" })} x ${
    orderData?.childrenCount
  }`;
  const childrenTotalPrice = orderData?.childrenCount * orderData?.pricePerChild;
  const totalPrice = orderData?.totalAmount;
  const totalTitle = intl.formatMessage({ id: "src.checkout.components.Bill.total" });

  return (
    <SEOWrapper pathname={window.location.pathname}>
      <div className="order-confirmation">
        <div className="thank-you-block">
          <div className="title">
            <FormattedMessage id={`${formattedMessageIdPrefix}thankyou.thankYou`} />
          </div>
          <div className="text">
            <FormattedMessage id={`${formattedMessageIdPrefix}thankyou.notification.firstPart`} />
            {orderData?.recipientEmail}
            <FormattedMessage id={`${formattedMessageIdPrefix}thankyou.notification.secondPart`} />
          </div>
        </div>
        <div className="bill-block">
          <div className="title">
            <FormattedMessage id={`${formattedMessageIdPrefix}bill.title`} />
          </div>

          {orderData === null ? (
            <Loader />
          ) : (
            <>
              <div className="header">{orderData?.seller?.sellerInfo?.hotelName}</div>
              <div className="address">{orderData?.seller?.sellerInfo?.hotelAddress}</div>
              <div className="bill-container">
                <Line title={dateTitle} extraClasses="date-title" />
                <Line title={voucherTitle} value={""} />
                <Line
                  title={adultTitle}
                  value={`${getCurrencySymbol(orderData?.currency)}${adultTotalPrice}`}
                />
                <Line
                  title={childrenTitle}
                  value={`${getCurrencySymbol(orderData?.currency)}${childrenTotalPrice}`}
                />
                <Line
                  title={totalTitle}
                  value={`${getCurrencySymbol(orderData?.currency)}${totalPrice}`}
                  extraClasses="total"
                  extraTitleClasses="total__title"
                />
              </div>
            </>
          )}
        </div>
        <div className="contact-block">
          <div className="title">
            <FormattedMessage id={`${formattedMessageIdPrefix}contact.enjoyDaycation`} />
          </div>
        </div>
      </div>
    </SEOWrapper>
  );
};

export default withApollo(View);
