import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import moment from "moment";

type TData = {
    createCustomerHotelCheckIn: {
      createCustomerCheckins: unknown
    }
};

type TKYCRequestDataError = {
  field: string;
  message: string;
}
type TKYCRequestData = {
  createCustomerHotelCheckInRequest: {
    errors: TKYCRequestDataError[];
    __typename: "CreateCustomerHotelCheckInRequest";
  };
};

type TVariables = {
  input: {
    sellerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  image: File;
};

type TKYCRequestVariables = {
  input: {
    internalId: string;
    referenceId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

export const CHECKIN = gql`
  mutation checkinMutation($input: CreateCustomerHotelCheckInInput!) {
    createCustomerHotelCheckIn(input: $input) {
      createCustomerCheckins {
        seller
        id
      }
    }
  }
`;

export async function checkIn(client: ApolloClient<any>, variables) {
  const checkInResult = await client.mutate<TData, TVariables>({
    mutation: CHECKIN,
    variables,
  });

  return checkInResult.data;
}


export const CREATE_KYC_REQUEST = gql`
  mutation checkinMutation($input: CustomerHotelCheckInInput!, $image: Upload!) {
    customerHotelCheckIn(input: $input, image: $image) {
      errors {
        field
        message
      }
    }
  }
`;

export async function createKycRequest(client: ApolloClient<any>, variables) {
  const createKycRequestResult = await client.mutate<TKYCRequestData, TKYCRequestVariables>({
    mutation: CREATE_KYC_REQUEST,
    variables,
  });

  return createKycRequestResult.data;
}

const GET_SELLER = gql`
  query ($slug: String!, $day: Date) {
    seller(slug: $slug, day: $day) {
      id
      slug
      notificationEmail
      hotelWebsite
      contactEmail
      contactPhone
      contactPerson
      contactPersonTitle
      reservationEmail
      reservationPhone
      acceptingBookings
      shaCertified
      passTypes {
        id
        name
        icon
        venues {
          id
          description
          openingHour
          closingHour
          secondarySectionName
          secondaryOpeningHour
          secondaryClosingHour
          passes {
            id
            description
          }
        }
      }
      venuePasses {
        id
        offerName
        timeSlots {
          id
          startTime
          endTime
          seatsLeft
        }
      }
      sellerInfo {
        id
        hotelName
        hotelDescription
        hotelShortDescription
        status
        starRating
        hotelAddress
        latitude
        longitude
        area {
          id
          name
          location {
            id
            name
          }
        }
        mainHotelImage
        generalTermsTitle
        generalTermsText
      }
      user {
        id
        email
        isActive
        avatar {
          url
        }
      }
    }
  }
`;

export async function getSeller(client, { slug, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLER,
    variables: { slug, day: moment(day).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
