import React from "react";
import ReactSVG from "react-svg";
import { makeStyles } from "@material-ui/core/styles";
import clockImg from "images/clock-icon.svg";

const useStylesMain = makeStyles((theme) => ({
  hourContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    marginBottom: "12px",
    [theme.breakpoints.down("xs")]: {
      width: "32%",
    },
  },
  hoursItem: {
    display: "flex",
    alignItems: "center",
    lineHeight: "1.5",
    fontSize: "17px",
  },
  hoursItemTitle: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontFamily: "'Poppins-Bold', sans-serif",
  },
  hoursItemText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "14px",
    opacity: ".7",
  },
}));

const useStylesVoucher = makeStyles((theme) => ({
  hourContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    gap: "10px",
    marginBottom: "12px",
    marginLeft: "4%",
    marginTop: "5%",
    [theme.breakpoints.down("xs")]: {
      width: "32%",
    },
  },
  hoursItem: {
    display: "flex",
    alignItems: "center",
    lineHeight: "1.5",
    fontSize: "17px",
  },
  hoursItemTitle: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontFamily: "'Poppins-Bold', sans-serif",
  },
  hoursItemText: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "14px",
    opacity: ".7",
  },
}));

const getStyles = (variant) => {
  // console.log('type of useStylesMain:', typeof useStylesMain)
  if (variant === 'main') {
    return useStylesMain
  } else if (variant === 'voucher') {
    return useStylesVoucher
  }
}

const SingleOpeningHoursEntry = ({label, openingTime, endTime, variant='main'}) => {
  const classes = getStyles(variant)();
  if (openingTime !== null) {
    return <>
      <div className={classes.hourContainer} key={label}>
        <ReactSVG path={clockImg}/>
        <div>
          <div className={classes.hoursItem}>
            <span className={classes.hoursItemTitle}>{label}</span>
          </div>
          <div className={classes.hoursItem}>
            <span className={classes.hoursItemText}>
              {openingTime} - {endTime}
            </span>
          </div>
        </div>
      </div>
    </>
  } else return null;
}


export default SingleOpeningHoursEntry;
