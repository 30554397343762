import gql from "graphql-tag";
import moment from "moment";

const GET_SELLER = gql`
  query ($slug: String!, $day: Date) {
    seller(slug: $slug, day: $day) {
      id
      slug
      notificationEmail
      hotelWebsite
      contactEmail
      contactPhone
      contactPerson
      contactPersonTitle
      reservationEmail
      reservationPhone
      acceptingBookings
      shaCertified
      passTypes {
        id
        name
        icon
        venues {
          id
          description
          openingHour
          closingHour
          secondarySectionName
          secondaryOpeningHour
          secondaryClosingHour
          passes {
            id
            description
          }
        }
      }
      venuePasses {
        id
        offerName
        timeSlots {
          id
          startTime
          endTime
          seatsLeft
        }
      }
      sellerInfo {
        id
        hotelName
        hotelDescription
        hotelShortDescription
        status
        starRating
        hotelAddress
        latitude
        longitude
        area {
          id
          name
          location {
            id
            name
          }
        }
        mainHotelImage
        generalTermsTitle
        generalTermsText
        mapsEmbeddedLink
      }
      user {
        id
        email
        isActive
        avatar {
          url
        }
      }
    }
  }
`;

export async function getSeller(client, { slug, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLER,
    variables: { slug, day: moment(day).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const GET_SELLER_PASSES = gql`
  query ($sellerId: ID!, $day: Date!) {
    sellerPasses(sellerId: $sellerId, day: $day) {
      id
      isBestSeller
      sellingPrice
      currency
      childPrice
      allowChildren
      passTypeName
      passTypeIconLink
      description      
      offerName
      openingHour
      closingHour
      timeSlots {
        id
        startTime
        endTime
        seatsLeft
        isForSale
      }
    }
  }
`;

export async function getPasses(client, { sellerId, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLER_PASSES,
    variables: { sellerId, day: moment(day).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const GET_SELLER_FAQ = gql`
  query c($sellerId: ID!, $active: Boolean) {
    sellerUserFaqLines(sellerUserId: $sellerId, active: $active) {
      id
      question
      answer
      active
    }
  }
`;

export async function getFaq(client, { sellerId }) {
  const { data, loading } = await client.query({
    query: GET_SELLER_FAQ,
    variables: { active: true, sellerId },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const GET_SELLER_DEEP_LINKS = gql`
  query f($sellerId: ID!) {
    seller(id: $sellerId) {
      reservationEmail
      whatsappLink
      lineLink
    }
  }
`;

export async function getDeepLinks(client, { sellerId }) {
  const { data, loading } = await client.query({
    query: GET_SELLER_DEEP_LINKS,
    variables: { sellerId },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const CREATE_INTERACTION = gql`
  mutation d($input: SellerLogUserCustomerInteractionInput!) {
    sellerLogUserCustomerInteraction(input: $input) {
      errors {
        field
        message
      }
      customerInteraction {
        actionType
        seller
      }
    }
  }
`;

export async function createInteraction(client, input) {
  const { data, loading } = await client.mutate({
    mutation: CREATE_INTERACTION,
    variables: { input },
  });

  return { data, loading };
}
