import React, { FC } from "react";
// import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import "src/checkout/scss/billing.scss";
// import GuestAddressForm from "./GuestAddressForm";
// import HotelImageInfo from "../../checkout/components/HotelImageInfo/HotelImageInfo";
// import { IOnSubmitProps } from "@temp/checkout/types";
// import {MobileStepper} from "@material-ui/core";

type TBulletStepProgressProps = {
    activeSteps: number;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Select master blaster campaign settings', 'Create an ad group'];
}

export const BulletStepProgress: FC<TBulletStepProgressProps> = ({
                                                                     activeSteps
                                                                }) => {
    const classes = useStyles();
    // const [activeStep2] = React.useState(step);
    const steps = getSteps();


    return (
        <div className={classes.root}>
            <Stepper activeStep={activeSteps}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
