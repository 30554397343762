import React, { FC, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { withApollo } from "react-apollo";

import * as paths from "@temp/app/routes/paths";
import CheckInQuery from "./CheckInQuery";

const CheckInStep2: FC<RouteComponentProps<{ hotelSlug: string }> & { updateHeader: any }> = ({
                                                                     history,
                                                                     location,
                                                                     match: { params: hotelSlug  },
                                                                     updateHeader
                                                                 }) => {
    const redirectToHotelDetails = useCallback((hotelSlug) => {
        history.push(`${paths.hotelDetails}${hotelSlug}`);
    }, [hotelSlug]);

    return (
                <CheckInQuery
                    client={null}
                    hotelSlug={hotelSlug}
                    redirectToHotelDetails={redirectToHotelDetails}
                    updateHeader={updateHeader}
                />
    );
};

export default withApollo(CheckInStep2);
