import React, { FC } from "react";

import { makeStyles } from '@material-ui/core/styles';


import "src/checkout/scss/billing.scss";
import {Button, Theme} from "@material-ui/core";
import {StyleProps} from "@temp/components/SignUpForm/SignUpForm";

type TCheckInButton = {
    step?: number;
    disabled?: boolean;
    display?: boolean;
    buttonText1: string;
    buttonText2?: string;
    click?: any;
    color?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    sectionButton: {
        borderRadius: "10px",
        textAlign: "center",
        color: "#fff",
        backgroundColor: "#3D91EF",
        padding: "0.8rem",
        // width: "fit-content",
        width: '50%',
        "& .MuiButton-label": {
            fontFamily: "sans-serif",
            fontWeight: "bold",
            fontSize: "1rem",
            lineHeight: "1.4rem",
        },
    }
}));



export const CheckInButtonStep1: FC<TCheckInButton>= ({
                                                          disabled,
                                                          buttonText1,
                                                          buttonText2,
                                                      }) => {
    const classes = useStyles({ mirror: false });
    return (
        <Button
            className={classes.sectionButton}
            color="primary"
            disabled={disabled}
            type="submit"
            variant="contained"
        >
            {buttonText1}<br />
            { (buttonText2) ? ({buttonText2}) : null }
        </Button>
    )
};

const getButtonText = (buttonText1: string, buttonText2: string) => {
    let buttonText;
    const pStyle = {
        lineHeight: "1.0rem"
    };

    // tslint:disable-next-line:prefer-conditional-expression
    if (buttonText2) {
        buttonText = <><p style={pStyle}>{buttonText1}</p><p style={pStyle}>{buttonText2}</p></>;
    } else {
        buttonText = <p>{buttonText1}</p>;
    }

    return buttonText
}

export const CheckInButtonStep2: FC<TCheckInButton>= ({
                                                          display,
                                                          buttonText1,
                                                          buttonText2,
                                                          click,
                                                          color,
                                                      }) => {

    const buttonText = getButtonText(buttonText1, buttonText2);

    return <Button
        variant="contained"
        component="span"
        // @ts-ignore
        color={color}
        style={{
            borderRadius: "10px",
            textAlign: "center",
            color: "#fff",
            backgroundColor: color,

            width: '50%',
            display: (display) ? "none" : "block",
            marginLeft: '25%',
        }}
        onClick={(click) ? click : null}
    >

        {buttonText}
    </Button>
};




export const CheckInButton: FC<TCheckInButton> = ({
                                                      step,
                                                      disabled,
                                                      display,
                                                      buttonText1,
                                                      buttonText2,
                                                      click,
                                                      color
}) => {
    if (color === null || color === undefined || color === "") {
        color = "primary"
    }
    if (step === 1) {
        return <CheckInButtonStep1 buttonText1={buttonText1} buttonText2={buttonText2} disabled={disabled} />
    } else if (step === 2) {

        return <CheckInButtonStep2
            buttonText1={buttonText1}
            buttonText2={buttonText2}
            disabled={disabled}
            display={display}
            click={click}
            color={color}
        />
    }
}
