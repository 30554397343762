import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import { withApollo } from "react-apollo";
import { useAlert } from "react-alert";

import { CheckInView } from "@temp/components/CheckIn/CheckInView";
import { IOnSubmitProps } from "@temp/checkout/types";
import { Loader } from "@temp/components";
import { checkIn, getSeller as getSellerRequest } from "./mutations";
const CheckInQuery = ({ client, hotelSlug, redirectToHotelDetails, updateHeader }) => {
  const alert = useAlert();
  // console.log('CheckInQuery: hotelSlug:', hotelSlug.hotelSlug)
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [hotelData, setHotelData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fireUpAlert = ({
                         content,
                         title,
                         type,
                       }: {
    content: string;
    title: string;
    type: "error" | "success";
  }) =>
      alert.show(
          {
            content,
            title,
          },
          { type, timeout: 5000 }
      );
  const [sellerId, setSellerId] = React.useState(null);

  useEffect(() => {
    setLoading(true);

    (async () => {
      setLoading(true);

      try {
        const {
          data: { seller },
        } = await getSellerRequest(client, {
          slug: hotelSlug.hotelSlug,
          day: moment().format("YYYY-MM-DD"),
        });

        if (seller) {
          setSellerId(seller.id)
          localStorage.setItem("seller-id", seller.id)

          setHotelData({
            hotelName: seller.sellerInfo?.hotelName,
            mainImageUrl: seller.sellerInfo?.mainHotelImage,
            sellerId: seller.id,
          });

          updateHeader({ onlyLogo: true, customLogo: seller.user?.avatar?.url, checkInHeader: true });
          setLoading(false);
        } else {
          fireUpAlert({ content: "Done", title: "Check In", type: "error" });
        }
      } catch (error) {
        if (error.message && error.message.includes("SellerUser matching query does not exist")) {
          fireUpAlert({
            content: "Hotel not found, please go back",
            title: "Check In",
            type: "error"
          });
        }
        console.error('error:', error);
      }/* finally {

      }*/
    })();
  }, [hotelSlug]);

  const _checkIn = useCallback(
      (data: IOnSubmitProps) => {
        setCheckInLoading(true);
        // console.log('hotelData:', hotelData)
        if (hotelData.sellerId && data.file) {
          (async () => {
            try {
              const checkInResult = await checkIn(client, {
                input: {
                  sellerId: hotelData.sellerId,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  email: data.email,
                  phone: data.phone,
                  bookingReference: data.bookingReference,
                },
                image: data.file,
              });

              // @ts-ignore
              if (checkInResult?.customerHotelCheckIn.customerCheckins === null) {
                fireUpAlert({ content: "Done", title: "Check In", type: "success" });
                setTimeout(() => redirectToHotelDetails(hotelSlug.hotelSlug), 2000)
              } else {
                console.warn("Check In: unexpected: ", { checkInResult });
              }
            } catch (error) {
              fireUpAlert({ content: error.message, title: "Check In", type: "error" });
            } finally {
              setCheckInLoading(false);
            }
          })();
        }
      },
      [(hotelData || {}).sellerId]
  );

  return loading ? (
      <Loader />
  ) : (
      <CheckInView checkIn={_checkIn} checkInLoading={checkInLoading} {...hotelData} sellerId={sellerId} client={client} />
  );
};

export default withApollo(CheckInQuery);
