import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { NotFound } from "../../components";
import { Billing, Review } from "../views";
import { CheckoutRouteDispatcher } from "./CheckoutRouteDispatcher";

import * as paths from ".";
import { MainHeaderContext } from "@temp/app/MainHeaderProvider";

export const CheckoutRoutes: React.FC = () => {
    return (
  <Switch>
    <Route exact path={paths.baseUrl} component={CheckoutRouteDispatcher} />
    <Route path={paths.billingUrl} render={(props) => (
        <MainHeaderContext.Consumer>
            {({ update }) => {
                return <Billing
                    updateHeader={update}
                    history={props.history}
                    match={props.match}
                    location={props.location}>
                </Billing>
            }}
        </MainHeaderContext.Consumer>
    )} />

    <Route path={paths.reviewUrl} render={(props) => (
        <MainHeaderContext.Consumer>
            {({ update }) => {
                return <Review
                    updateHeader={update}
                    history={props.history}
                    match={props.match}
                    location={props.location}>

                </Review>
            }}
        </MainHeaderContext.Consumer>
    )} />

    <Route component={NotFound} />
  </Switch>
)
};
