import React from "react";
import PdfViewer from "@temp/components/PdfViewer";
import { TypedSiteQuery } from "@temp/components/Terms/queries";

const Terms = () => {
  return (
    <TypedSiteQuery>
      {({ data }) => {
        // @ts-ignore
        return <PdfViewer url={data.site.termsPdf} />;
      }}
    </TypedSiteQuery>
  );
};

export default Terms;
