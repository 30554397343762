import React from "react";
import "./scss/index.scss";
import { FormattedMessage } from "react-intl";

import ReactSVG from "react-svg";
import logoMiniPath from "src/images/logo-daily-pass-dark-mini.svg";
import { defaultLocale, messages } from "@temp/components/Locale/Locale";
import { useLocalStorage } from "@hooks";

const NewToPlatform = () => {
  const { storedValue: locale } = useLocalStorage("locale", defaultLocale);

  const [email, setEmail] = React.useState("");

  const onSignUpButtonPress = (event) => {
    const validateEmail = (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    if (!validateEmail(email)) {
      event.preventDefault();
      window.alert(messages[locale]["text.entervalidemail"]);
    }
  };

  const onSignUpScrollerButtonPress = (event) => {
    window.scroll({
      behavior: "smooth",
      top: document.body.scrollHeight,
    });
  };

  return (
    <div className="new-to-platform">
      <div className="left">
        <ReactSVG className="logo-mini" path={logoMiniPath} />
        <div className="info-dayilypass">
          <div className="new-to-dayilypass">
            <FormattedMessage id="emailcta.newtoUpguest" />
          </div>
          <div className="sign-up">
            <FormattedMessage id="emailcta.signuppromotion" />
            <br />
            <FormattedMessage id="emailcta.signuppromotion2" />
          </div>
        </div>
      </div>
      <div className="right">
        <form
          action="https://DailyPass.us8.list-manage.com/subscribe/post?u=d551067f8e2c9224101526d8d&amp;id=598b5ef5ca"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            placeholder={messages[locale]["emailcta.enteremail"]}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            required
          />
          <button
            className="sign-up-button"
            type="submit"
            defaultValue="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            onClick={onSignUpButtonPress}
          >
            <FormattedMessage id="header.signup" />
          </button>
        </form>
        <button className="sign-up-scroller" onClick={onSignUpScrollerButtonPress}>
          <FormattedMessage id="header.signup" />
        </button>
      </div>
    </div>
  );
};

export default NewToPlatform;
