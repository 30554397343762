import React, { useEffect } from "react";
import { setQuickButtons, addResponseMessage } from "react-chat-widget";
// import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";

import HotelHeader from "./HotelHeader";
import HotelInfo from "./HotelInfo";
import HotelInfoReviewsQuery from "@temp/components/HotelDetails/HotelInfo/HotelInfoReviewsQuery";
import HotelVouchers from "./HotelVouchers";
import HowItWorksArea from "@temp/components/HotelDetails/HowItWorksArea/HowItWorksArea";
import ImagesCarousel from "./ImagesCarousel";
import { SEOWrapper } from "@temp/components/SEO";

import "react-chat-widget/lib/styles.css";
import "./HotelDetailsView.scss";
/*
type TFaqEntryBe = {
  active: boolean;
  answer: string;
  id: number;
  position: number;
  question: string;
  sellerId: string;
};
 */
/*
type TDebouncedSetterProps = {
  // tslint:disable-next-line
  defaultButtons?: { label: string; value: string }[];
  input: string;
  list: TFaqEntryBe[];
};
*/
// const BOT_DEBOUNCE_WAIT_TIME = 300;
const BOT_NUMBER_OF_DEFAULT_QUESTIONS = 5;
const BOT_SIGN_EMAIL = "___EMAIL___";
const BOT_SIGN_LINE = "___LINE___";
const BOT_SIGN_STAFF = "___STAFF___";
const BOT_SIGN_WHATSAPP = "___WHATSAPP___";
const BOT_INITIAL_MESSAGE =
  "Hi! Please select a topic you’re interested in, or simply start asking here.";
/*
const debouncedSetter = debounce(
  ({ defaultButtons = [], input, list }: TDebouncedSetterProps): boolean => {
    // tslint:disable-next-line
    const _list = input
      ? list.reduce<{ label: string; value: string }[]>((acc, curr) => {
          const inputWords = input.toLowerCase().split(/[,.\s]+/g);

          const pick = inputWords.some(
            (w) => curr.question.toLowerCase().includes(w) || curr.answer.toLowerCase().includes(w)
          );

          if (pick) {
            acc.push({ label: curr.question, value: `${curr.id}` });
          }

          return acc;
        }, [])
      : [];

    setQuickButtons([..._list, ...defaultButtons]);

    return !!list.length;
  },
  BOT_DEBOUNCE_WAIT_TIME
);
*/
const HotelDetailsView = ({
  createInteraction,
  deepLinks,
  faq,
  hotelSlug,
  imagesSources,
  selectedDate,
  seller: {
    id: sellerId,
    reservationEmail,
    sellerInfo: {
      hotelName,
      hotelDescription: hotelDescriptionEn,
      starRating,
      hotelAddress: hotelAddressEn,
      latitude,
      longitude,
      area: {
        name: areaEn,
        location: { name: locationName },
      },
      mainHotelImage,
      generalTermsTitle,
      generalTermsText,
      mapsEmbeddedLink
    },
    acceptingBookings,
    shaCertified,
    passTypes,
  },
  setSelectedDate,
  venuePasses,
}) => {
  const classes = useStyles();
  const { lineLink, whatsappLink } = deepLinks || {};

  const contactsButtons = [];
  // tslint:disable-next-line:no-unused-expression
  lineLink && contactsButtons.push({ label: "LINE", value: BOT_SIGN_LINE });
  // tslint:disable-next-line:no-unused-expression
  whatsappLink && contactsButtons.push({ label: "WhatsApp", value: BOT_SIGN_WHATSAPP });
  contactsButtons.push({ label: "Email", value: BOT_SIGN_EMAIL });

  const defaultButtons = [{ label: "Talk to staff", value: BOT_SIGN_STAFF }];

  const slicedButtons = faq
    .slice(0, BOT_NUMBER_OF_DEFAULT_QUESTIONS)
    .map(({ id, question }) => ({ label: question, value: id }));

  useEffect(() => {
    setQuickButtons([...slicedButtons, ...defaultButtons]);
    addResponseMessage(BOT_INITIAL_MESSAGE);
  }, []);
/*
  const handleTextInputChange = useCallback(
    (event) => {
      event.persist();

      const _defaultButtons = event.target.value.length
        ? defaultButtons
        : [...slicedButtons, ...defaultButtons];

      debouncedSetter({
        defaultButtons: _defaultButtons,
        list: faq,
        input: event.target.value,
      });
    },
    [lineLink, whatsappLink, reservationEmail]
  );
*/
  /*
  const handleQuickButtonClicked = useCallback(
    (value) => {
      switch (true) {
        case value === BOT_SIGN_STAFF:
          setQuickButtons(contactsButtons);
          break;
        case value === BOT_SIGN_EMAIL:
          window.open(`mailto:${reservationEmail}`, "_blank");
          createInteraction("Sent an email");
          break;
        case value === BOT_SIGN_LINE:
          window.open(lineLink, "_blank");
          createInteraction("Texted in LINE");
          break;
        case value === BOT_SIGN_WHATSAPP:
          window.open(whatsappLink, "_blank");
          createInteraction("Texted in WhatsApp");
          break;
        default:
          const responseTopic = faq.find((t) => t.id === value);
          addResponseMessage(responseTopic.answer);
      }
    },
    [lineLink, whatsappLink, reservationEmail]
  );*/

  const image = mainHotelImage !== undefined ? mainHotelImage : null;
  const hotelAddress = hotelAddressEn;
  const hotelDescription = hotelDescriptionEn;

  const vouchersComponent = (
    <div className={classes.voucher}>
      <HotelVouchers
        acceptingBookings={acceptingBookings}
        hotelName={hotelName}
        hotelSlug={hotelSlug}
        locationName={locationName}
        mainHotelImage={mainHotelImage}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        venuePasses={venuePasses}
        sellerId={sellerId}
        reservationEmail={reservationEmail}
      />
    </div>
  );

  const headerComponent = (
    <HotelHeader
      generalTermsText={generalTermsText}
      generalTermsTitle={generalTermsTitle}
      hotelDescription={hotelDescription}
      hotelName={hotelName}
      locationName={hotelAddress}
      shaCertified={shaCertified}
      starRating={starRating}
      passTypes={passTypes}
    />
  );

  const infoComponent = (
    <HotelInfo
      address={hotelAddress}
      lat={latitude}
      long={longitude}
      reservationEmail={reservationEmail}
      passTypes={passTypes}
      googleEmbeddedLink={mapsEmbeddedLink}
    />
  );

  return (
    <SEOWrapper pathname={location.pathname} image={image}>
      <ImagesCarousel
        imagesSources={[{ id: sellerId, url: mainHotelImage }, ...imagesSources]}
        hotelName={hotelName}
      />

      <HowItWorksArea />

      <div className={classes.gridContainer}>
        {isMobile ? (
          [headerComponent, vouchersComponent, infoComponent]
        ) : (
          <>
            <div>
              {headerComponent}
              {infoComponent}
            </div>
            {vouchersComponent}
          </>
        )}
      </div>

      <div className={classes.reviews}>
        <HotelInfoReviewsQuery client={null} params={{ sellerId }} />
      </div>

      {/*<ChatWidget
        autofocus={false}
        handleQuickButtonClicked={handleQuickButtonClicked}
        handleTextInputChange={handleTextInputChange}
        senderPlaceHolder="Type a question..."
        showBadge={false}
        subtitle={hotelName}
        title="DailyBot"
      />*/}
    </SEOWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  reviews: {
    padding: "4vw",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3rem",
      padding: "4vw",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10vw",
      marginLeft: "0rem",
    },
  },
  divider: {
    height: "1px",
    width: "89%",
    backgroundColor: theme.customColors.grid,
    margin: "3rem 6rem",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto auto",
    padding: "1vw 4vw 4vw 4vw",
    gridTemplateAreas: `
          "a b"
          "b c"
        `,

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      padding: "1vw 4vw 4vw 4vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-11vw",
      padding: "1vw 4vw 4vw 4vw",
    },
  },
  voucher: {
    gridColumn: "2 / 3",
    gridRow: "1 / 3",

    [theme.breakpoints.down("sm")]: {
      gridColumn: "1 / 2",
      gridRow: "2 / 3",
    },
  },
}));

export default HotelDetailsView;
