import * as React from "react";
import classNames from "classnames";
import {FormControlLabel, Theme } from "@material-ui/core";
import { useIntl } from "react-intl";

import "./scss/index.scss";

import { Form, TextField } from "../../../components";

import Checkbox from '@material-ui/core/Checkbox';

import {ApolloClient} from "apollo-client";
import {makeStyles} from "@material-ui/core/styles";
import {StyleProps} from "@temp/components/SignUpForm/SignUpForm";
import { checkIn } from "@temp/components/CheckIn/mutations";
import {CheckInButton} from "@temp/components/CheckIn/CheckInButton";

const optInStyle = {
    marginTop: "0.1%",
    marginLeft: "0%",
    marginBottom: "5.5%"
}

export type TFormData = {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    checkedOptIn: boolean;
    bookingReference: string;
}

export type IGuestAddressProps = {
    checkIn: any,
    checkInLoading: any,
    onSubmit: any,
    client: ApolloClient<any>,
    sellerId: any,
    setNotificationValue: any,
    notificationValue: any
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    header: {
        width: "100%",
        height: "10vh",
        marginTop: "1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 10%",
        [theme.breakpoints.down("xs")]: {
            height: "5rem",
        },
    },
    link: {
        display: "inline-block",
        padding: "0 0.5rem",
    },
    logo: {
        height: "6vw",

        "& > div": {
            height: "100%",

            "& svg": {
                height: "100%",
                width: "auto",

                [theme.breakpoints.down("xs")]: {
                    height: "5rem",
                    transform: "translateY(-32%)",
                },

                "& path": {
                    fill: "#FFBD59",
                },
            },
        },
    },
    headerButtons: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        columnGap: "20px",
    },
    section: (props) => ({
        margin: "10vh 0",
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "0 10%",
        columnGap: "5%",
        flexDirection: props.mirror ? "row-reverse" : "row",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    }),
    infoContainer: {
        height: "100%",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            alignItems: "center",
            marginBottom: "10px",
        },
    },
    imageContainer: {
        height: "100%",
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    sectionName: {
        fontFamily: "sans-serif",
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#3D91EF",
        marginBottom: "10px",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
        },
    },
    sectionTitle: {
        fontFamily: "sans-serif",
        color: "black",
        fontSize: "2.5rem",
        lineHeight: "2.5rem",
        fontWeight: 900,
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            fontSize: "1.4rem",
            lineHeight: "1.6rem",
        },
    },
    firstSectionTitle: {
        fontFamily: "sans-serif",
        color: "black",
        fontSize: "2rem",
        lineHeight: "2rem",
        fontWeight: 900,
        marginBottom: "20px",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            fontSize: "1.2rem",
            lineHeight: "1.2rem",
        },
    },
    sectionText: {
        fontFamily: "sans-serif",
        color: "black",
        fontSize: "1.2rem",
        lineHeight: "1.6rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem",
            lineHeight: "1.2rem",
            width: "100%",
        },
    },
    sectionImage: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
    firstSectionContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FEFCFC",
        flexDirection: "column",
        padding: "10vh 10%",
        [theme.breakpoints.down("xs")]: {
            padding: "5vh 10% 10vh 10%",
        },
    },
    firstSection: {
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        columnGap: "5%",
        marginBottom: "5vh",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    firstSectionName: {
        fontFamily: "sans-serif",
        fontSize: "2.8rem",
        lineHeight: "2.8rem",
        fontWeight: "bold",
        color: "#3D91EF",
        marginBottom: "10px",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            fontSize: "2.0rem",
            lineHeight: "2.0rem",
        },
    },
    videoContainer: {
        height: "40vh",
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    video: {
        height: "100%",
        width: "100%",
    },
    pointLinesContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    pointLine: {
        display: "flex",
        marginBottom: "5px",
        alignItems: "center",
    },
    pointImage: {
        height: "1.6rem",
        objectFit: "contain",
        marginRight: "10px",
    },
    imageList: {
        flexWrap: "nowrap",
    },
    hotelsRoot: {
        width: "70%",
        "& > div": {
            height: "auto",
            alignItems: "center",
            "& .alice-carousel__wrapper": {
                height: "10vh",
                "& li": {
                    height: "100%",
                    "& img": {
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        opacity: "0.3",
                    },
                },
            },
            "& > div": {
                margin: "5px",
                "& > div": {
                    backgroundColor: "unset",
                },
            },
        },
    },
    headerLink: {
        fontFamily: "sans-serif",
        fontWeight: "bold",
    },
    drawer: {
        width: "100vw",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "100vw",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
    },
    cardContainer: {
        margin: "2vw 4vw 3vw",
        [theme.breakpoints.down("md")]: {
            margin: "1.5vw 3vw 1.5vw",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "4vw 2vw",
        },
    },
    card: {
        [theme.breakpoints.down("xs")]: {
            padding: 12,
        },
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: "0.675rem",
        left: 10,
        position: "absolute",
        top: 5,
    },
    errorValidation: {
        color: theme.palette.error.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
        paddingTop: "0.5rem",
        alignSelf: "flex-start",
    },
    paymentDetailsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        [theme.breakpoints.down("sm")]: {
            marginTop: "2vw",
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-around",
            marginTop: "0",
        },
        "& > p": {
            marginBottom: "20px",
        },
    },
    regularButtonContainerCheckin: {
        display: 'flex',
        justifyContent: 'center'
    },
    textField: {
        width: "100%",
        marginBottom: "10px",
        "& > div": {
            width: "100%",
        },
        "& .MuiInputLabel-formControl": {
            color: "#A8ABA7",
        },
        [theme.breakpoints.down("xs")]: {
            "&:first-child": {
                marginTop: "3vw",
            },
            marginBottom: "3vw",
        },
    },
    textFieldPad: {
        height: "1.25rem",
    },
    hotelFormContainer: {
        height: "100%",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#f5f5f5",
        padding: "10vh 10%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            alignItems: "center",
            marginBottom: "10px",
        },
    },
    finalSection: {
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    hotelRevenueContainer: {
        height: "100%",
        width: "50%",
        padding: "10vh 5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            alignItems: "center",
            marginBottom: "10px",
        },
    },
    singUpFormContainer: {
        width: "100%",
        background: "white",
        padding: "50px 5%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    horizontalInput: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        "& > div": {
            width: "30%",
            "& > div": {
                background: "rgba(0, 0, 0, 0.04)",
                "& > input": {
                    background: "rgba(0, 0, 0, 0)",
                },
            },
            "& > .Mui-disabled": {
                background: "#f5f5f5",
                "& > input": {
                    color: "#3D91EF",
                },
                "& > .MuiInputAdornment-filled": {
                    "& > p": {
                        color: "#3D91EF",
                    },
                },
            },
        },
    },
    labelText: {
        fontWeight: "bold",
        fontFamily: "sans-serif",
    },
    emailUpsellInput: {
        marginTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > div": {
            width: "70%",
        },
    },
}));

const createCheckInToHotel = async (client: ApolloClient<any>, sellerId, sellerCustomerCheckInUser: TFormData) => {

    const variables = {
        input: {
            sellerId: sellerId,
            firstName: sellerCustomerCheckInUser.firstName,
            lastName: sellerCustomerCheckInUser.lastName,
            email: sellerCustomerCheckInUser.email,
            phone: sellerCustomerCheckInUser.phone,
            // referenceId: sellerCustomerCheckInUser.kycId,
            // unparsed: sellerCustomerCheckInUser.pixlab,
            notificationOptIn: sellerCustomerCheckInUser.checkedOptIn,
            bookingReference: sellerCustomerCheckInUser.bookingReference,
        }
    }
    const checkInResult = await checkIn(client, variables);

    // @ts-ignore
    if (checkInResult?.createCustomerHotelCheckIn.createCustomerCheckins === null) {
        // Success case
        // TODO: handle all okay
        return true
    } else {
        // TODO: Handle check in creation error
        return false
    }
}


const GuestAddressForm: React.FC<IGuestAddressProps> = ({ checkIn, checkInLoading, onSubmit, client, sellerId, setNotificationValue, notificationValue }) => {
    const intl = useIntl();
    const formattedMessageIdPrefix = "src.checkout.components.GuestAddressForm.GuestAddressForm.";
    const classes = useStyles({ mirror: false });

    const changeNotification = () => {
        setNotificationValue(!notificationValue)
    }

    const _onSubmit = async (event: React.FormEvent<TFormData>, data: TFormData) => {
        event.preventDefault();

        localStorage.setItem("first-name", data.firstName)
        localStorage.setItem("last-name", data.lastName)
        localStorage.setItem("email", data.email)
        localStorage.setItem("phone", data.phone)
        localStorage.setItem("booking-reference", data.bookingReference)
        if (notificationValue) {
            localStorage.setItem("optinUpdates","true")
        } else {
            localStorage.setItem("optinUpdates", "false")
        }

        const user: TFormData = {
            firstName: localStorage.getItem("last-name"),
            lastName: localStorage.getItem("first-name"),
            email:localStorage.getItem("email"),
            phone: localStorage.getItem("phone"),
            bookingReference: localStorage.getItem("booking-reference"),
            checkedOptIn: notificationValue
        }

        await createCheckInToHotel(client, sellerId, user)

        window.location.assign(`${window.location.href}/2`);
    };

    return (
        <div className={classes.paymentDetailsContainer}>
            <Form
                onSubmit={_onSubmit}
                data={{ firstName: "", lastName: "", email: "", phone: "", bookingReference: "", checkedOptIn: true}}
            >
                <fieldset>
                    <div className="address-form-online-check-in__grid">
                        <div className={classes.textField}>
                            <TextField
                                type="given-name"
                                name="firstName"
                                autoComplete="given-name"
                                required
                                label="First Name *"

                            />
                        </div>
                    </div>
                    <div className={classes.textField}>
                        <TextField
                            type="family-name"
                            name="lastName"
                            autoComplete="family-name"
                            required
                            label="Last Name *"
                        />
                    </div>
                    <div
                        className={classNames("address-form-online-check-in__grid", {
                            "address-form-online-check-in__grid--full": true,
                        })}
                    >
                        <div className={classes.textField}>
                            <TextField
                                type="email"
                                autoComplete="email"
                                name="email"
                                required
                                label="Email Address*"
                            />
                        </div>
                        <div className={classes.textField}>
                            <TextField
                                type="tel"
                                name="phone"
                                autoComplete="phone"
                                required
                                label="Phone Number *"
                            />
                        </div>
                        <div className="address-form-online-check-in__grid">
                            <div className={classes.textField}>
                                <TextField
                                    type="booking-reference"
                                    name="bookingReference"
                                    autoComplete="booking-reference"
                                    label="Booking Reference *"
                                />
                            </div>
                        </div>
                        <FormControlLabel
                            control={<Checkbox
                                className="address-form-online-check-in__custom-checkbox"
                                name="checkedOptIn"
                                defaultChecked={true}
                                checked={notificationValue}
                                onChange={changeNotification}
                            /> }
                            label={intl.formatMessage({id:`${formattedMessageIdPrefix}futureUpdateOptIn`})}
                            style={optInStyle}
                        />
                    </div>
                    <div className={classes.regularButtonContainerCheckin}>
                        <CheckInButton disabled={!checkIn} buttonText1="Next" step={1} />
                    </div>
                </fieldset>

            </Form>
        </div>
    );
};

export default GuestAddressForm;
