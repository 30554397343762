import * as React from "react";
import classNames from "classnames";
import { Button, CircularProgress } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

import "./scss/index.scss";

import { Form, TextField } from "../../../components";
import { IGuestAddressProps, TFormData } from "../../types";

const GuestAddressForm: React.FC<IGuestAddressProps> = ({ checkIn, checkInLoading, onSubmit }) => {
    const intl = useIntl();
    const formattedMessageIdPrefix = "src.checkout.components.GuestAddressForm.GuestAddressForm.";

    const [file, setFile] = React.useState(null);
    const [imageUrl, setImageUrl] = React.useState(null);
    const [sendEmailNews, setSendEmailNews] = React.useState<boolean>(true);

    const handleFileUpload = (event) => {
        event.preventDefault();
        event.persist();

        const [file] = event.target.files;
        const reader = new FileReader();

        reader.onloadend = () => {
            setFile(file);
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const _onSubmit = (event: React.FormEvent<TFormData>, data: TFormData) => {
        event.preventDefault();

        if (checkIn) {
            onSubmit({ ...data, file });
        } else {
            onSubmit(data);
        }
    };

    return (
        <div className="address-form">
            <Form
                onSubmit={_onSubmit}
                data={{ sendEmailNews, firstName: "", lastName: "", email: "", phone: "" }}
            >
                <fieldset>
                    <div className="address-form__grid">
                        <TextField
                            label={intl.formatMessage({
                                id: `${formattedMessageIdPrefix}firstName`,
                            })}
                            type="given-name"
                            name="firstName"
                            autoComplete="given-name"
                            required
                        />
                        <TextField
                            label={intl.formatMessage({
                                id: `${formattedMessageIdPrefix}lastName`,
                            })}
                            type="family-name"
                            name="lastName"
                            autoComplete="family-name"
                            required
                        />
                    </div>
                    <div
                        className={classNames("address-form__grid", {
                            "address-form__grid--full": true,
                        })}
                    >
                        <TextField
                            label={intl.formatMessage({
                                id: `${formattedMessageIdPrefix}email`,
                            })}
                            type="email"
                            autoComplete="email"
                            name="email"
                            required
                        />

                        <TextField
                            label={intl.formatMessage({
                                id: `${formattedMessageIdPrefix}phoneNumber`,
                            })}
                            type="tel"
                            name="phone"
                            autoComplete="phone"
                            required
                        />

                        {checkIn ? (
                            <div className="imageUploaderContainer">
                                {imageUrl ? (
                                    <img className="uploadedImagePreview" src={imageUrl} alt="Uploaded Image" />
                                ) : null}
                                <label htmlFor="upload-image">
                                    <label htmlFor="upload-image">
                                        <Button
                                            className="smallButton"
                                            variant="contained"
                                            component="span"
                                            size="small"
                                        >
                                            {imageUrl ? "Update image" : "Upload document"}
                                        </Button>
                                        <input
                                            id="upload-image"
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileUpload}
                                        />
                                    </label>
                                    <input
                                        id="upload-image"
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        onChange={handleFileUpload}
                                    />
                                </label>
                            </div>
                        ) : (
                            <div className="address-form__copy-address">
                                <div className="custom-checkbox">
                                    <label className="container">
                                        <input
                                            name="sendEmailNews"
                                            type="checkbox"
                                            // value={sendEmailNews ? "true" : ""}
                                            checked={sendEmailNews}
                                            onClick={(event) => {
                                                const target = event.target as HTMLInputElement;
                                                setSendEmailNews(target.value !== "true");
                                            }}
                                        />
                                        <span>
                      <FormattedMessage id="text.yesreceivemail" />
                    </span>
                                        <span className="checkmark" />
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </fieldset>
                {/*ToDo: add this and similar from HotelListVew to the common component Button if possible when whole site will be redesigned*/}
                <div className="regularButtonContainer">
                    <Button
                        className="regularButton"
                        color="primary"
                        disabled={(checkIn && !file) || (checkIn && checkInLoading)}
                        type="submit"
                        variant="contained"
                    >
                        {checkIn ? (
                            checkInLoading ? (
                                <CircularProgress size={30} />
                            ) : (
                                "Check In"
                            )
                        ) : (
                            "Pay at the hotel"
                        )}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default GuestAddressForm;
