import React, {FC, useState} from "react";
// import { FormattedMessage } from "react-intl";

import "src/checkout/scss/billing.scss";
import GuestAddressForm from "./GuestAddressForm";
// import HotelImageInfo from "../../checkout/components/HotelImageInfo/HotelImageInfo";
import { IOnSubmitProps } from "@temp/checkout/types";
import {BulletStepProgress} from "@temp/components/CheckIn/BulletStepProgress";
import {ApolloClient} from "apollo-client";

type TCheckInViewProps = {
    checkIn: (props: IOnSubmitProps) => void;
    checkInLoading: boolean;
    hotelName: string;
    mainImageUrl: string;
    sellerId: string;
    client: ApolloClient<any>;
};

export const CheckInView: FC<TCheckInViewProps> = ({
                                                       checkIn,
                                                       checkInLoading,
                                                       hotelName,
                                                       mainImageUrl,
                                                       sellerId,
                                                       client
                                                   }) => {

    const [notificationValue, setNotificationValue] = useState(true);

    return (
        <div>
            <GuestAddressForm checkIn checkInLoading={checkInLoading} onSubmit={checkIn} sellerId={sellerId} client={client} notificationValue={notificationValue} setNotificationValue={setNotificationValue}/>
            <BulletStepProgress  activeSteps={0}/>
        </div>
    )
};
