import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PdfViewer.css";

import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;

class PdfViewer extends Component {
  componentDidMount() {
    // @ts-ignore
    const loadingTask = pdfjsLib.getDocument(this.props.url);
    loadingTask.promise.then((doc) => {
      this.setState({ loaded: true });
      // console.log(`Document ${this.props.url} loaded ${doc.numPages} page(s)`);

      function renderPage(num) {
        doc.getPage(num).then((page) => {
          const canvasId = "pdf-viewer-" + num;
          const viewerDiv = document.getElementById("pdf-viewer");
          const canvas = document.createElement("canvas");
          canvas.id = canvasId;
          viewerDiv.appendChild(canvas);
          const viewport = page.getViewport({ scale: 1.0 });
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          canvas.style.maxWidth = "100%";
          canvas.style.maxHeight = "100%";
          const ctx = canvas.getContext("2d");
          const renderTask = page.render({
            canvasContext: ctx,
            viewport
          });
          return renderTask.promise;
        });
      }

      for (let i = 1; i <= doc.numPages; i++) {
        renderPage(i);
      }
    }, (reason) => {
      // @ts-ignore
      console.error(`Error during ${this.props.url} loading: ${reason}`);
    });
  }

  render() {
    return (
      <div className="pdf-page-container">

        {
          // @ts-ignore
          this.state?.loaded ? null : (<div className="pdf-page-header">
            <h2>Loading document</h2>
          </div>
        )}
        <div id="pdf-viewer" className="pdf-page-body">
        </div>
      </div>
    );
  }
}

// @ts-ignore
PdfViewer.propTypes = {
  url: PropTypes.string
};

export default PdfViewer;
