import React from "react";
import { darken, alpha } from "@material-ui/core/styles/colorManipulator";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const fontFamily = '"roboto", "sans-serif"';

const createShadow = (pv, pb, ps, uv, ub, us, av, ab, as) =>
  [
    `0 ${pv}px ${pb}px ${ps}px rgba(0, 0, 0, 0.2)`,
    `0 ${uv}px ${ub}px ${us}px rgba(0, 0, 0, 0.14)`,
    `0 ${av}px ${ab}px ${as}px rgba(0, 0, 0, 0.12)`,
  ].join(",");

export const rbrColors = {
  mainColor: "#477bc4",
  accentColor: "#ff8a30", // text on White BG, small icon
  accentBackground: "#fff4e0",
  darkOrange: "#ff6b00", // emphasize BG for specific text
  turquoise: "#00c2cb",
  white: "#fefcfc",
  covidBkg: "#5c807b", // Covid-19 info BG
};

export const colors = {
  autofill: "#f4f6c5",
  background: {
    default: "#F1F6F6",
    paper: "#FFFFFF",
  },
  checkbox: {
    default: "#616161",
  },
  divider: "#EAEAEA",
  error: "#d93c42",
  font: {
    button: "#FFFFFF",
    default: "#3D3D3D",
    gray: "#616161",
    textButton: rbrColors.turquoise,
    textDisabled: "rgba(0, 0, 0, 0.4)",
  },
  gray: {
    default: "#C8C8C8",
    disabled: "rgba(216, 216, 216, 0.3)",
  },
  grid: "#EAEAEA",
  input: {
    border: "#BDBDBD",
    default: "#FFFFFF",
    disabled: "#EAEAEA",
    disabledBackground: "#F4F4F4",
    disabledText: "#9D9D9D",
    error: "#8C2054",
    text: "#3D3D3D",
    textHover: "#616161",
  },
  inputBorder: "rgba(61, 61, 61, 0.3)",
  paperBorder: "#EAEAEA",
  primary: rbrColors.mainColor,
  primaryTransparent: "rgba(205, 109, 113, 30)",
  primaryTransparentBit: "rgba(205, 109, 113, 0.20)",
  secondary: rbrColors.mainColor,
  theme: "light",
  yellowIcon: "#fc3",
};

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    customColors: {
      brand: string;
      gray: string;
      grid: string;
      inputBorder: string;
      primaryTransparent: string;
      primaryTransparentBit: string;
      red: string;
      yellowIcon: string;
    };
  }

  interface ThemeOptions {
    customColors?: {
      brand: string;
      gray: string;
      grid: string;
      inputBorder: string;
      primaryTransparent: string;
      primaryTransparentBit: string;
      yellowIcon: string;
      red: string;
    };
  }
}

const theme = createTheme({
  customColors: {
    brand: rbrColors.mainColor,
    gray: colors.background.paper,
    grid: colors.grid,
    inputBorder: colors.inputBorder,
    primaryTransparent: colors.primaryTransparent,
    primaryTransparentBit: colors.primaryTransparentBit,
    red: "#ef3f2f",
    yellowIcon: colors.yellowIcon,
  },
  overrides: {
    MuiButton: {
      contained: {
        "&$disabled": {
          backgroundColor: alpha(colors.primary, 0.12),
        },
        "&:active": {
          boxShadow: null,
        },
        "&:hover": {
          boxShadow: null,
        },
        boxShadow: null,
      },
      containedPrimary: {
        "&:active": {
          backgroundColor: darken(colors.primary, 0.4),
        },
        "&:hover": {
          backgroundColor: darken(colors.primary, 0.1),
        },
      },
      label: {
        fontWeight: 600,
        textTransform: "none",
      },
      root: {
        "& svg": {
          marginLeft: 8,
        },
        borderRadius: 4,
      },
      text: {
        "& span": {
          color: colors.font.default,
        },
      },
      textPrimary: {
        "& span": {
          color: colors.primary,
        },
      },
    },
    MuiCard: {
      root: {
        borderColor: colors.paperBorder,
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
        overflow: "visible",
      },
    },
    MuiCardActions: {
      root: {
        flexDirection: "row-reverse" as "row-reverse",
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 0,
        },
        padding: "2vw",
      },
    },
    MuiSelect: {
      root: {
        paddingLeft: "1rem",
      },
    },
    MuiChip: {
      avatar: {
        height: 32,
        left: -5,
        position: "relative",
        width: 32,
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: `1px solid ${colors.divider}`,
        padding: `16px 24px`,
      },
    },
    MuiDialogContent: {
      root: {
        "& label": {
          overflowX: "hidden",
        },
        padding: 24,
      },
    },
    MuiDialogContentText: {
      root: {
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: `1px solid ${colors.divider}`,
      },
    },
    MuiFilledInput: {
      root: {
        "&.Mui-focused": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        "&:hover": {
          backgroundColor: colors.gray.disabled,
        },
        backgroundColor: colors.background.paper,
      },
    },
    MuiFormControlLabel: {
      label: {
        marginLeft: 4,
      },
    },
    MuiFormLabel: {
      filled: {
        "&&:not($error)": {
          color: colors.primary,
        },
      },
      root: {
        "&&$focused:not($error)": {
          color: colors.font.gray,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: alpha(colors.primary, 0.12),
        },
      },
    },
    MuiInput: {
      input: {
        "&:-webkit-autofill": {
          WebkitTextFillColor: colors.font.default,
          boxShadow: `inset 0 0 0px 9999px ${colors.autofill}`,
        },
        "&::placeholder": {
          opacity: "1 !important" as any,
        },
      },
      underline: {
        "&:after": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "none",
        },
        "&:hover": {
          "&:not(.Mui-disabled)": {
            "&:before": {
              borderBottom: "none",
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      input: {
        "&$disabled": {
          color: colors.font.default,
        },
        "&::placeholder": {
          color: colors.input.disabledText,
          opacity: "1 !important" as any,
        },
        zIndex: 2,
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
    MuiInputLabel: {
      filled: {
        zIndex: 2,
      },
      formControl: {
        color: colors.primary,
        fontSize: "0.9rem",
        transform: "translate(0, 1.5px) scale(0.75)",
        transformOrigin: "top left" as "top left",
        width: "100%",
      },
      outlined: {
        "&$shrink": {
          transform: "translate(12px, 9px) scale(0.75)",
        },
        transform: "translate(14px, 18px) scale(1)",
        zIndex: 9,
      },
      root: {
        "&$disabled": {
          color: `${alpha(colors.primary, 0.4)} !important` as any,
        },
        "&$error": {
          "&$focused": {
            color: colors.error,
          },
          color: colors.error,
        },
        "&&$focused": {
          "&:not($error)": {
            color: colors.primary,
          },
        },
        color: colors.input.text,
      },
      shrink: {
        // Negates x0.75 scale
        width: "133%",
      },
    },
    MuiList: {
      root: {
        display: "grid",
        gridRowGap: 8 + "px",
        padding: "8px !important",
      },
    },
    MuiListItem: {
      button: {
        "&:focus": {
          backgroundColor: colors.input.default,
        },
      },
      root: {
        "&$selected": {
          "&:hover": {
            backgroundColor: colors.input.default,
          },
          backgroundColor: colors.input.default,
        },
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 8,
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected, &$selected:focus, &$selected:hover": {
          backgroundColor: [colors.background.default, "!important"] as any,
          color: colors.primary,
          fontWeight: 700,
        },
        "&:hover": {
          backgroundColor: [colors.background.default, "!important"] as any,
          color: colors.font.default,
          fontWeight: 400,
        },
        borderRadius: 4,
      },
    },
    MuiNativeSelect: {
      icon: {
        color: colors.primary,
      },
      select: {
        fontSize: "0.8rem",
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      input: {
        "&:-webkit-autofill": {
          borderRadius: 4,
          boxShadow: `0 0 0px 1000px rgba(19, 190, 187, 0.1) inset`,
          zIndex: 0,
        },
        "&::placeholder": {
          opacity: [[0], "!important"] as any,
        },
        color: colors.input.text,
        padding: "23px 12px 10px 12px",
      },
      inputMultiline: {
        left: -2,
        padding: "10px 0",
        position: "relative",
      },
      root: {
        "& fieldset": {
          "&&:not($error)": {
            borderColor: colors.input.border,
          },
          top: 0,
          zIndex: 1,
        },
        "& legend": {
          display: "none",
        },
        "&$disabled": {
          "& fieldset": {
            backgroundColor: colors.input.disabledBackground,
            borderColor: [[colors.input.disabled], "!important"] as any,
          },
          "& input": {
            color: colors.input.disabledText,
            zIndex: 2,
          },
        },
        "&$error": {
          "&$focused": {
            "& fieldset": {
              borderColor: colors.error,
            },
            "& input": {
              color: colors.error,
              zIndex: 2,
            },
          },
          "&:hover": {
            "& fieldset": {
              borderColor: colors.error,
            },
            "& input": {
              color: colors.error,
              zIndex: 2,
            },
          },
        },
        "&$focused": {
          "& input": {
            "& fieldset": {
              borderColor: colors.primary,
            },
            "&::placeholder": {
              opacity: [[1], "!important"] as any,
            },
            color: colors.font.default,
          },
        },
        "&:hover": {
          "& input": {
            color: colors.font.default,
          },
          "&&&": {
            "& fieldset": {
              borderColor: colors.primary,
            },
            "&$error fieldset": {
              borderColor: colors.input.error,
            },
          },
        },
        backgroundColor: colors.background.paper,
        borderColor: colors.input.border,
        top: 0,
      },
    },
    MuiSnackbarContent: {
      action: {
        "& $MuiIconButton": {
          "& svg": {
            color: colors.font.default,
          },
        },
        alignSelf: "baseline",
      },
      message: {
        fontSize: 16,
      },
      root: {
        backgroundColor: colors.background.paper,
        boxShadow:
          "0 6px 10px 0px rgba(0, 0, 0, 0.15), 0 1px 18px 0px rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.10)",
        color: colors.font.default,
        display: "grid",
        gridTemplateColumns: "1fr 56px",
        maxWidth: 480,
      },
    },
    MuiSwitch: {
      // colorPrimary: {
      //   "&$checked": {
      //     color: colors.background.paper
      //   }
      // },
      // root: {
      //   "&$disabled": {
      //     "&$switchBase": {
      //       "& + $thumb": {
      //         backgroundColor: colors.gray.disabled
      //       }
      //     }
      //   },
      //   height: 48,
      //   width: 72
      // },
      switchBase: {
        "&$checked": {
          color: colors.primary,
        },
        "&$checked + $track": {
          backgroundColor: colors.primary,
        },
        color: colors.primaryTransparentBit,
      },
      // switchBase: {
      //   "&$checked": {
      //     transform: "translateX(23px)"
      //   },
      //   boxShadow: "none",
      //   left: 1,
      //   marginLeft: 4,
      //   top: 5
      // },
      // thumb: {
      //   boxShadow: "none"
      // },
      track: {
        backgroundColor: colors.gray.default,
      },
      // track: {
      //   "$colorPrimary$checked + &": {
      //     backgroundColor: colors.primary
      //   },
      //   backgroundColor: colors.gray.default,
      //   borderRadius: 12,
      //   height: 24,
      //   opacity: [["1"], "!important"] as any,
      //   width: 48
      // }
    },
    MuiTable: {
      root: {
        fontFamily,
        fontFeatureSettings: '"tnum"',
      },
    },
    MuiTableCell: {
      body: {
        fontSize: "0.85rem",
        paddingBottom: 10,
        paddingTop: 10,
      },
      head: {
        fontSize: "0.8rem",
      },
      paddingCheckbox: {
        "&:first-child": {
          padding: "0 12px",
          width: 72,
        },
        "&:not(first-child)": {
          padding: 0,
          width: 52,
        },
      },
      root: {
        "&:first-child": {
          "&:not($paddingCheckbox)": {
            paddingLeft: 24 + "px",
            textAlign: "left" as "left",
          },
        },
        borderBottomColor: colors.paperBorder,
        height: 36,
        padding: "4px 24px",
      },
    },
    MuiTableRow: {
      footer: {
        "$root$hover&:hover": {
          background: "none",
        },
      },
      head: {
        "$root$hover&:hover": {
          background: "none",
        },
      },
      hover: {
        "$root&:hover": {
          backgroundColor: alpha(colors.primary, 0.3),
        },
      },
      root: {
        "&$selected": {
          backgroundColor: alpha(colors.primary, 0.05),
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: alpha(colors.primary, 0.2),
      },
      childLeaving: {
        backgroundColor: alpha(colors.primary, 0.2),
      },
      ripple: {
        "&$rippleVisible": {
          backgroundColor: alpha(colors.primary, 0.2),
        },
        borderRadius: "100%",
      },
    },
  },
  palette: {
    action: {
      active: colors.checkbox.default,
    },
    background: colors.background,
    divider: colors.divider,
    error: {
      main: colors.error,
    },
    primary: {
      contrastText: "#ffffff",
      dark: colors.font.textDisabled,
      main: colors.primary,
    },
    secondary: {
      contrastText: "#ffffff",
      main: colors.secondary,
    },
    text: {
      disabled: colors.font.textDisabled,
      hint: colors.font.gray,
      primary: colors.font.default,
      secondary: colors.font.gray,
    },
  },
  props: {
    MuiFormControl: {
      variant: "filled",
    },
  },
  shadows: [
    "none",
    createShadow(1, 1, 0, 2, 1, -2, 1, 3, 0),
    createShadow(2, 2, 0, 3, 1, -2, 1, 5, 0),
    createShadow(3, 4, 0, 3, 3, -2, 1, 8, 0),
    createShadow(4, 5, 0, 1, 10, 0, 2, 4, -1),
    createShadow(5, 8, 0, 1, 14, 0, 3, 4, -1),
    createShadow(6, 10, 0, 1, 18, 0, 3, 5, -1),
    createShadow(7, 10, 0, 2, 16, 1, 4, 5, -2),
    createShadow(8, 10, 1, 3, 14, 2, 5, 5, -3),
    createShadow(9, 12, 1, 3, 16, 3, 5, 6, -4),
    createShadow(10, 14, 1, 4, 18, 3, 6, 7, -4),
    createShadow(11, 16, 1, 4, 20, 3, 6, 7, -4),
    createShadow(12, 17, 1, 5, 22, 4, 7, 8, -4),
    createShadow(13, 19, 1, 5, 24, 4, 7, 8, -4),
    createShadow(14, 21, 1, 5, 26, 4, 7, 9, -5),
    createShadow(15, 22, 1, 5, 28, 4, 7, 9, -5),
    createShadow(16, 24, 2, 6, 30, 5, 8, 10, -5),
    createShadow(15, 27, 3, 7, 28, 3, 10, 14, -4),
    createShadow(14, 30, 4, 8, 26, 1, 12, 17, -3),
    createShadow(13, 33, 4, 8, 24, -1, 14, 20, -1),
    createShadow(12, 36, 5, 9, 22, -2, 16, 24, 1),
    createShadow(11, 39, 6, 10, 20, -4, 18, 28, 1),
    createShadow(10, 41, 7, 10, 18, -5, 20, 31, 2),
    createShadow(9, 44, 7, 11, 16, -6, 22, 35, 2),
    createShadow(9, 46, 8, 11, 15, -7, 24, 38, 3),
  ],
  typography: {
    allVariants: {
      fontFamily,
    },
    body1: {
      color: colors.font.default,
    },
    fontFamily,
    h4: {
      color: colors.font.default,
    },
    h5: {
      fontSize: "1.3125rem",
    },
  },
});

const ThemeProvider = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
