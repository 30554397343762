import { useLocalStorage } from "@hooks/useLocalStorage";
import * as React from "react";
import { IntlProvider } from "react-intl";

// import locale_en from './locale/en.json'
// import locale_th from './locale/th.json'

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

let defaultLocale = "";

const data = {
  en: {
    "faq.header": "Frequently Asked Questions",
    "faq.subheader": "You can find the most frequently asked questions about Upguest here.",
    "faq.question1": "What is a Upguest?",
    "faq.answer1":
      "A Upguest lets you be a guest for a day at luxurious hotels and resorts. Without the need of booking a room, you can spend a full day at the swimming pool with our pool pass, while using vouchers to get great discounts on food and drinks. Our buffet pass allows access to the best buffet restaurants, and our rooftop pass gets you access to the most exclusive rooftop bars with special offers.",
    "faq.question2": "What times/hours are the Upguest valid for?",
    "faq.answer2":
      "You can see the opening hours for each hotel, restaurant and rooftop bar on our website, as well as in your booking confirmation email after you book. Typically, a Upguest is valid for the whole day.",
    "faq.question3": "What is included when I book a Upguest?",
    "faq.answer3":
      "The booking page and the booking email you will receive will clearly state all the benefits and inclusions, but with a pool pass you get free access to the pool, a voucher that is redeemable for drinks and snacks, towels, a welcome drink and free Wifi. A buffet pass allows you access to the best buffets, and a rooftop pass allows you access to the best rooftop bars.",
    "faq.question4": "Can I access the fitness / gym at the hotel?",
    "faq.answer4":
      "Some hotels offer free access to the fitness or gym to Upguest customers. Other hotels may charge a fee that can be paid for with a Upguest voucher. You will find this information on the hotel page, and in the confirmation email you will receive after booking.",
    "faq.question5": "Can I bring my own food and drink items to the hotel?",
    "faq.answer5": "No, bringing outside food and beverage (including coolers) is not permitted.",
    "faq.question6": "Are towels provided at the hotel?",
    "faq.answer6": "Yes, towels are provided at the pool area.",
    "faq.question7": "I booked a Upguest, now what do I do?",
    "faq.answer7":
      "On the day of your Upguest booking, please go to the hotel reception and show the booking confirmation email you received together with a photo ID. The receptionist may give you an access card for the pool area, as well as further information on how to redeem your vouchers. Typically, the voucher will be deducted from your bill when you’re ready to check out.",
    "faq.question8": "The date I would like to book is unavailable",
    "faq.answer8":
      "The hotel may be sold out, or if you are trying to book a date that is more than 3 months away, please note that the booking window is up to 3 months in advance. Availability for dates further out than 3 months from now will open up as we get closer, so check back then! Alternatively, you can check out any of the other hotels or resorts in your area.",
    "faq.question9": "How do I use my promo code?",
    "faq.answer9":
      "You can use your promo code on the payment page right before you enter your credit card details. Kindly enter the code and click on submit to apply your discount.",
    "faq.question10": "My city is not on the list of locations?",
    "faq.answer10":
      "We are expanding the cities where we are active on a regular basis. If you feel we should come to your city, please send us a message at hello@Upguest.com. {breakingLine} We recommend you to subscribe to our newsletter, where we will inform you on newly added cities and hotels.",
    "faq.question11": "The hotel I like is not on your list?",
    "faq.answer11":
      "We handpick our hotel partners and aim for quality over quantity. If you feel we should add the hotel you prefer, please send us a message at hello@Upguest.com. {breakingLine} We recommend you to subscribe to our newsletter, where we will inform you on newly added cities and hotels.",
    "faq.question12": "How late can I book a Upguest?",
    "faq.answer12":
      "You can book your Upguest for today, tomorrow, and up to 3 months in advance. {breakingLine}For a Upguest on the same day, please plan according to the opening hours of the hotel swimming pool, the restaurants or the rooftop bar.",
    "faq.question13": "What is the cancellation/refund policy?",
    "faq.answer13":
      "Should you change your mind, you can cancel with a full refund up until midnight before the check-in date. Cancellations received after midnight before check-in will not be refunded as per our cancellation policy.{breakingLine}Please note that if you are eligible for a refund, we process this immediately when you request a cancellation. However, depending on your bank’s policy, it may take up to 1 billing cycle (30-45 days) for the refund to be shown in your bank account.",
    "faq.question14": "How can I cancel?",
    "faq.answer14":
      "You can cancel your Upguest by sending us a message at hello@Upguest.com. Please include your booking confirmation number, and the email address used to make the booking. Please make sure to always check the cancellation policy to see whether you are eligible for a refund.{breakingLine}Please note that if you are eligible for a refund, we process this immediately when you request a cancellation. However, depending on your bank’s policy, it may take up to 1 billing cycle (30-45 days) for the refund to be shown in your bank account.",
    "faq.question15": "How can I change my date?",
    "faq.answer15":
      "You can change the date of your Upguest up to midnight before check-in, by sending us a message with your preferred date and your Upguest booking confirmation number.",
    "faq.question16": "How can I add another person to my Upguest?",
    "faq.answer16":
      "For a pool pass, you can add up to 1 adult OR 1 kid (age 5 and up) to your Upguest. Per pool pass, 1 infant (age up to 5) is free of charge.{breakingLine}Need to add more people? Kindly make a new booking with the same date to have more people joining you for your Upguest experience.",
    "faq.question17": "I did not receive an email confirmation for my booking",
    "faq.answer17":
      "Please check whether the email is in your junk mail folder. If it is, consider adding hello@Upguest.com to your address book.{breakingLine}If you still do not see the email, please contact us at hello@Upguest.com with your correct email address and we will resend your confirmation email. Perhaps there was a typo in your email address when you registered on our website.",
    "faq.question18": "Is it safe to enter my credit card details?",
    "faq.answer18":
      "We have industry standard enhanced security for online shopping on your credit or debit cards. Before you enter your payment details, double check that the lock icon is displayed before our website URL in your browser address bar. This means that you have a secure connection to our systems, and you can safely enter your credit or debit card information.",
    "covid.header": "COVID-19 Information",
    "covid.welcomeText":
      "Last update: 9 February 2021{breakingLine}{breakingLine}" +
      "Dear customers,{breakingLine}{breakingLine}" +
      "Upguest is committed to providing our customers a safe and relaxing way to unwind and de-stress, find the perfect Instagram moment, and relax at the swimming pool of luxury hotels while enjoying drinks and food.{breakingLine}{breakingLine}" +
      "Your safety and comfort are of utmost importance for us and the management of the hotels that are partnered with us. We strictly follow the government guidelines and will update the information on the hotel pages in case there are any restrictions.",
    "covid.question1": "Are the hotels safe to visit?",
    "covid.answer1":
      "Partnered hotels and resorts strictly follow the guidelines from the Tourism Authority of Thailand on enhanced screening, cleaning and social distancing. Every guest’s temperature is checked before entering the hotel and the public areas are frequently cleaned with disinfectants. Wherever needed, steps are taken to ensure social distancing.",
    "covid.question2": "Are the swimming pools safe to swim in?",
    "covid.answer2":
      "There is no evidence that COVID-19 can be spread to humans through the use of swimming pools (source: cdc.gov). Follow safe swimming practices along with social distancing and everyday preventative actions to protect yourself.",
    "covid.question3": "How can I stay safe?",
    "covid.answer3":
      "There are a lot of practical steps you can take. Regularly wash your hands (with soap and water or an alcohol-based hand gel), avoid touching eyes, nose and mouth and stay at least two metres away from someone who is coughing or sneezing.",
    "covid.question4": "Where can I find more information?",
    "covid.answer4":
      "Follow the {WHOLink}.{breakingLine}" +
      "The Tourism Authority of Thailand has provided a dedicated site for the {SHAThaiLink}.{breakingLine}" +
      "The {ThaiMFALink} regularly posts updates regarding COVID-19 on their website, in Thai and English.",
    worldHealthOrganization: "World Health Organization’s advice for the public on COVID-19",
    shaThai: "Amazing Thailand Safety & Health Administration (SHA), in Thai",
    ThaiMFA: "Thai Ministry of Foreign Affairs",
    "button.booknow": "BOOK NOW",
    "button.view": "VIEW",
    "covid.message": "Information regarding COVID-19",
    "creditcard.cvc": "CVC",
    "creditcard.expiry": "Expiry Date (MM/YY)",
    "creditcard.name": "Name on Card",
    "creditcard.number": "Card Number",
    "selectPass.title": "Select your ideal Pass",
    "selectPass.pool": "Pool",
    "selectPass.buffet": "Buffet",
    "selectPass.skyBar": "SkyBar",
    "selectPass.comingSoon": "(coming soon)",
    "emailcta.enteremail": "Enter your email address here",
    "emailcta.newtoUpguest": "New to Upguest?",
    "emailcta.signuppromotion": "Sign up to get access to promotions",
    "emailcta.signuppromotion2": "and updates on newly added hotels",
    "explore.main1": "Enjoy luxurious hotel's facilities and services for a day",
    "explore.main2": "without the need of a room",
    "footer.aboutus": "About us",
    "footer.browsehotels": "Browse Hotels",
    "footer.contactus": "Contact Us",
    "footer.discover": "Discover",
    "footer.faq": "FAQ",
    "footer.hotelpartnerlogin": "Hotel Partner Login",
    "footer.howitworks": "How it Works",
    "footer.partners": "Partners",
    "footer.partnerwithus": "Partner with us",
    "footer.privacypolicy": "Privacy Policy",
    "footer.signup":
      "Join the world's top hotel brands and maximize your revenue with automated upselling",
    "footer.signup2": "and update on newly added hotels",
    "footer.subscribe": "Are you a Hotelier?",
    "footer.support": "Support",
    "footer.termsconditions": "Terms & Conditions",
    "footer.partner_sign_up": "Become a partner",
    "header.becomehotelpartner": "Become a Hotel Partner",
    "header.booknow": "Book Now2",
    "header.browsehotels": "Browse Hotels",
    "header.howitworks": "How it Works",
    "header.partner_sign_up": "Become a partner",
    "header.login": "Log In",
    "header.signup": "Sign Up",
    "homepage.poster1": "Get Hotel Experience for a Day",
    "homepage.poster2": "Pool, Buffet, Skybar, and more with affordable prices",
    "hotels.currency": "THB",
    "hotels.guest": "Guest",
    "hotels.popular": "Popular Hotels",
    "hotels.starting": "Starting at",
    "hoteldetails.choose": "Choose the date",
    "hoteldetails.enjoy": "Your Booking is Confirmed",
    "hoteldetails.howitworks.first": "Select your date, your offer, add number of guests, and book.",
    "hoteldetails.howitworks.second": "Receive booking confirmation with details via email.",
    "hoteldetails.howitworks.third": "Show your confirmation email at check-in.",
    "hoteldetails.howitworks.fourth": "Enjoy your Upguest!",
    "hoteldetails.select": "Select the voucher and book",
    "hoteldetails.slogan1": "Discover and book our hotel services during your stay",
    "hoteldetails.slogan2": "",
    "hoteldetails.subslogan1": "The higher the voucher value,",
    "hoteldetails.subslogan2": "the bigger the discount!",
    "mainimage.getUpguest": "Get a Upguest",
    "mainimage.search": "Search",
    "mainimage.wantluxurydaycation": "Want a luxury daycation?",
    "mainmenu.account": "Account",
    "mainmenu.loginregister": "Log In | Register",
    "mainmenu.logout": "Log Out",
    "mainmenu.myaccount": "My Account",
    "onboarding.addingmore": "We are adding more",
    "onboarding.bookdetails": "Book pool time and amenities",
    "onboarding.bookdetails2": "at luxury hotels",
    "onboarding.cancellation":
      "Change your mind later? You can cancel for free up until midnight before check in.",
    "onboarding.choosedate": "Choose your Pass and Book!",
    "onboarding.choosedatedetail":
      "Get the best deals for swimming pools, buffet, restaurants, rooftop bars, gym, staycation and more.",
    "onboarding.choosehotelresort": "Choose from any of the hotel or resorts we have an offer",
    "onboarding.city.bangkok": "BANGKOK",
    "onboarding.city.pattaya": "PATTAYA",
    "onboarding.city.phuket": "PHUKET",
    "onboarding.enjoydaycation": "QR code Check-in",
    "onboarding.enjoydaycationdetail":
      "Show QR code on confirmation email to check-in at the hotel and enjoy your Upguest!",
    "onboarding.everyweek": "every week",
    "onboarding.findhotel": "Pick a Date and Find a Hotel",
    "onboarding.findhoteldetail":
      "Choose from any of the hotels or resorts we have on offer. We are adding more every week.",
    "onboarding.showUpguest":
      "Show your Upguest and photo ID at reception and get your drinks voucher. Enjoy your day at the swimming pool!",
    "steps.guestinfo": "GUEST INFO",
    "steps.paymentmethod": "PAYMENT METHOD",
    "text.address": "Address",
    "text.adult": "Adult",
    "text.adults": "Adults",
    "text.apply": "Apply",
    "text.backtohome": "Back to home",
    "text.backtohomepage": "Back to Homepage",
    "text.backtoprevious": "Go back to the previous Step",
    "text.booknow": "BOOK NOW",
    "text.cancellationpolicy": "Cancellation Policy",
    "text.changeofplans":
      "Change of plans? No problem! You can cancel with full refund up until midnight before check in.",
    "text.checkout.email_address": "Email Address (You will get your Upguest confirmation here)",
    "text.contactInfo": "Contact Info",
    "text.checkpoolbarmenu": " Check the pool bar menu for details.",
    "text.contactus": "Please contact support at hello@Upguest.com",
    "text.children": "Children",
    "text.continerevieworder": "Continue to review your order",
    "text.revieworder": "Review your order",
    "text.date": "date",
    "text.discount": "Discount:",
    "text.endingin": "Ending in",
    "text.entervalidemail": "Please enter a valid email",
    "text.error": "Error",
    "text.extraadult": "Extra Adult",
    "text.extrakid": "Extra Kid",
    "text.from": "From",
    "text.bestseller": "Best Seller",
    "text.first_name": "First name",
    "text.grandtotal": "Grand Total",
    "text.havediscountcode": "Do you have a discount code?",
    "text.homepage": "Homepage",
    "text.includes": "includes",
    "text.including": "including",
    "text.creditappliesto": "credit applies to",
    "text.includingvoucher": "including {currenciedValue} Voucher",
    "text.kidage": "5-12 Years",
    "text.kids": "Kid",
    "text.notallowed": "Not allowed",
    "text.last_name": "Last Name",
    "text.loading": "Loading",
    "text.mistyped_or_moved": "You may have mistyped the address or the page may have moved.",
    "text.occupancy.first": "A Upguest is valid for 1 adult.",
    "text.occupancy.second": "You can add 1 adult OR 1 kid to a Upguest.",
    "text.occupancy.third": "Infant up to 5 years free of charge, 1 infant per Upguest.",
    "text.occupancy.fourth":
      "Need to add more people? You can book another Upguest after booking the first.",
    "text.occupancypolicy": "Occupancy Policy",
    "text.openinghours": "Opening Hours",
    "text.ordersummary": "ORDER SUMMARY",
    "text.overage": "Over 13 Years",
    "text.page_not_found": "We can’t seem to find a page you are looking for!",
    "text.phone_number": "Phone Number",
    "text.placeorder": "Book now",
    "text.pool": "Pool",
    "text.buffet": "Buffet",
    "text.skybar": "SkyBar",
    "text.restaurant": "Restaurant",
    "text.poolbar": "Pool Bar",
    "text.promocode": "Promocode",
    "text.doYouHavePromocode": "Do you have a promo code?",
    "text.returnhomepage": "Return to Search",
    "text.reviews": "Reviews",
    "text.savenow": "Save Now!",
    "text.sorryerror": "We’re sorry for the error and hope you’ll have a good day.",
    "text.startingfrom": "Starting from",
    "text.selectdate": "Select date:",
    "text.subjectavailability": "All items subject to availability.",
    "text.subtotal": "Subtotal",
    "text.tooltip": "Invite up to 1 adult OR 1 kid for a Upguest!",
    "text.order.thankyou1": "Thank you for",
    "text.order.thankyou2": "your order!",
    "text.order.unsuccessful1": "Order was",
    "text.order.unsuccessful2": " unsuccessful!",
    "text.send_confirmation": "We’ve emailed you an order confirmation.",
    "text.notavailable": "Not available",
    "text.topayment": "PROCEED TO PAYMENT",
    "text.total": "Total ",
    "text.totaltax1": "Total ",
    "text.totaltax2": "Excl. processing fee",
    "text.voucher": "Voucher",
    "text.Upguestincludes": "Upguest includes",
    "text.fooddrinkvouchervalue": "Food & Drink Voucher Value x",
    "text.for1adult": "for 1 Adult",
    "text.voucher_after": "Voucher",
    "text.voucher_before": "​with ",
    "text.yesreceivemail": "Yes, I agree to receive marketing communications from this hotel.",
    "text.tax_before": "​",
    "text.tax_after": "Tax",
    "src.components.HotelsList.offTextFirstPart": "Up to",
    "src.components.HotelsList.offTextSecondPart": "off",
    "src.components.HotelsList.viewDetailsButton": "View Details",
    "src.components.HotelsList.LoadMoreButtonText": "Load more",
    "src.components.HotelsList.readMoreText": "Read More",
    "src.checkout.views.Billing.Page.infoText":
      "It’s almost yours! We just need a few more details.",
    "src.checkout.views.Billing.Page.orderSummary": "Order summary",
    "src.checkout.views.Billing.Page.guestDetails": "Guest details",
    "src.checkout.views.Payment.View.infoText.partOne": "Change your mind?",
    "src.checkout.views.Payment.View.infoText.partTwo":
      "You can cancel for FREE until midnight before check-in.",
    "src.checkout.views.Payment.View.orderDetails": "Order details",
    "src.checkout.views.Payment.View.paymentDetails": "Payment details",
    "src.checkout.views.Payment.View.paymentSubblock.securityPercent": "100% Secure Payment",
    "src.checkout.views.Payment.View.security.title": "Secure Payment",
    "src.checkout.views.Payment.View.security.text":
      "All information is fully encrypted, secure, and protected.",
    "src.checkout.views.Payment.View.security.agree.text.firstPart":
      "By clicking on Book Now, you agree to our",
    "src.checkout.views.Payment.View.security.agree.terms": "Terms & Conditions",
    "src.checkout.views.Payment.View.security.agree.text.secondPart": " and ",
    "src.checkout.views.Payment.View.security.agree.policy": "Privacy Policy",
    "src.checkout.components.HotelImageInfo.passValueText.firstPart": "Upguest with",
    "src.checkout.components.HotelImageInfo.passValueText.secondPart": "voucher",
    "text.hopelove": "We hope you love Upguest & have a great luxury daycation.",
    "text.ordernumber": "Order Number",
    "src.checkout.components.Bill.discount": "Discount",
    "src.checkout.components.Bill.tax": "Processing Fee",
    "src.checkout.components.Bill.total": "Total",
    "src.checkout.components.Bill.subTotal": "SubTotal",
    "src.checkout.components.Bill.minimalPrice": "Minimum payment amount is ฿20",
    "src.checkout.components.Bill.UpguestFor": "Upguest for",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.firstName": "First Name",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.lastName": "Last Name",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.email": "Email Address",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.phoneNumber": "Phone Number",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.futureUpdateOptIn": "Yes,I agree to receive marketing communication from this hotel",
    "src.views.OrderConfirmed.View.thankyou.thankYou": "Thank you",
    "src.views.OrderConfirmed.View.thankyou.notification.firstPart":
      "You will receive an email confirmation at ",
    "src.views.OrderConfirmed.View.thankyou.notification.secondPart": " shortly.",
    "src.views.OrderConfirmed.View.bill.title": "BOOKING SUMMARY",
    "src.views.OrderConfirmed.View.bill.pooltime": "Pool Opening Time",
    "src.views.OrderConfirmed.View.contact.enjoyDaycation": "Your Booking is Confirmed",
    "src.views.OrderConfirmed.View.contact.header": "Contact support info",
    "src.views.OrderConfirmed.View.contact.email": "hello@Upguest.com",
    "text.percentOff": "{x}% OFF",
    "bnb-gallery.noPhotosProvided": "No photos to show",
    "bnb-gallery.showPhotoList": "Show photo list",
    "bnb-gallery.hidePhotoList": "Hide photo list",
    "line.updates": "Get the latest updates on LINE",
    "text.patner_subscribe": "Subscribe for {priceText}",
    "text.patner_signup": "Sign Up",
  },
  th: {
    "text.Upguestincludes": "Upguest includes",
    "text.bestseller": "ขายดีที่สุด",
    "text.percentOff": "ส่วนลด {x}%",
    "faq.header": "คำถามที่พบบ่อย",
    "faq.subheader": "คุณสามารถค้นหาคำถามที่พบบ่อยเกี่ยวกับ Upguest ได้ที่นี่",
    "faq.question1": "Upguest คืออะไร?",
    "faq.answer1":
      "Upguest ช่วยให้คุณเป็นแขกรายวันชั่วคราวที่โรงแรมและรีสอร์ทสุดหรูได้ทั้งวัน โดยไม่จำเป็นต้องจองห้อง คุณสามารถใช้เวลาหนึ่งวันที่สระว่ายน้ำด้วยบัตรผ่านสระว่ายน้ำของเราในขณะที่สามมารถใช้บัตรกำนัลเพื่อรับส่วนลดค่าอาหารและเครื่องดื่ม บัตรผ่านบุฟเฟ่ต์ของเราอนุญาตให้เข้าถึงร้านอาหารบุฟเฟ่ต์ที่ดีที่สุดและบัตรผ่านดาดฟ้าของเราจะช่วยให้คุณเข้าถึงบาร์บนดาดฟ้าสุดพิเศษพร้อมข้อเสนอพิเศษ",
    "faq.question2": "Upguest สามารถใช้ได้ในช่วงเวลาใด / กี่ชั่วโมง?",
    "faq.answer2":
      "คุณสามารถดูเวลาเปิดทำการของแต่ละโรงแรมร้านอาหารและบาร์บนชั้นดาดฟ้าบนเว็บไซต์ของเรารวมทั้งในอีเมลยืนยันการจองของคุณหลังจากที่คุณจอง โดยปกติแล้ว Upguest จะใช้ได้ทั้งวัน",
    "faq.question3": "ฉันจะได้รับอะไรบ้างเมื่อจอง Upguest",
    "faq.answer3":
      "หน้าการจองและอีเมลการจองที่คุณจะได้รับจะระบุสิทธิประโยชน์และข้อเสนอพิเศษทั้งหมดอย่างชัดเจน แต่เมื่อใช้บัตรผ่านสระว่ายน้ำคุณจะได้รับสิทธิ์เข้าใช้สระว่ายน้ำฟรีบัตรกำนัลที่ใช้แลกเป็นเครื่องดื่มและของว่าง ผ้าเช็ดตัว เครื่องดื่มต้อนรับและ Wifi ฟรี  บัตรบุฟเฟ่ต์ช่วยให้คุณเข้าถึงบุฟเฟ่ต์ที่ดีที่สุดและบัตรผ่านชั้นดาดฟ้าช่วยให้คุณเข้าถึงบาร์บนชั้นดาดฟ้าที่ดีที่สุดได้",
    "faq.question4": "ฉันสามารถเข้าฟิตเนส / ยิมที่โรงแรมได้หรือไม่?",
    "faq.answer4":
      "โรงแรมบางที่ให้บริการฟิตเนสหรือยิมฟรีสำหรับลูกค้า Upguest และบางโรงแรม อาจจะเรียกเก็บค่าธรรมเนียมที่สามารถชำระด้วยเครดิต Upguest ซึ่งคุณจะได้รับข้อมูลนี้ในหน้าโรงแรมและในอีเมลยืนยันที่คุณจะได้รับหลังการจอง",
    "faq.question5": "ฉันสามารถนำอาหารและเครื่องดื่มของของฉันไปที่โรงแรมได้หรือไม่?",
    "faq.answer5": "คุณไม่สามารถนำอาหารและเครื่องดื่มจากภายนอกเข้าไปบริโภคในโรงแรมได้",
    "faq.question6": "มีผ้าขนหนูให้ที่โรงแรมหรือไม่?",
    "faq.answer6": "ผ้าขนหนูมีบริการที่บริเวณสระว่ายน้ำ",
    "faq.question7": "เมื่อฉันทำการจอง Upguest แล้ว ฉันต้องทำอย่างไร?",
    "faq.answer7":
      "วันที่คุณทำการจอง Upguest ไว้ เพียงแสดงอีเมลเพื่อยืนยันการจองที่แผนกต้อนรับของโรงแรมกับยื่นประจำตัวประชาชนที่มีชื่อตรงกับผู้รับบริการที่จองไว้ พนักงานต้อนรับจะให้บัตรเข้าใช้บริเวณสระว่ายน้ำและรวมข้อมูลเพิ่มเติมเกี่ยวกับวิธีการแลกเครดิตอาหารและเครื่องดื่มของคุณ",
    "faq.question8": "ไม่สามารถจองโรงแรมในวันที่ต้องการเข้าใช้บริการได้",
    "faq.answer8":
      "เกิดขึ้นได้สองกรณี กรณีแรก คุณทำการจองมากกว่าสามเดือนขึ้นไปก่อนวันเข้ารับการบริการ ซึ่งโดยปกติระบบจะสามารถทำการจองล่วงหน้าได้ไม่เกินสามเดือนนับจากวันที่ทำการจอง กรณีที่สอง โรงแรมที่คุณต้องการเข้าไปใช้บริการสระว่ายน้ำได้ถูกจองสิทธิ์เข้าใช้ในวันที่ระบุเต็มแล้ว ทั้งนี้เราแนะนำให้คุณตรวจสอบข้อเสนอจากโรงแรมบริเวณใกล้เคียงแทน",
    "faq.question9": "ฉันจะใช้รหัสโปรโมชั่นได้อย่างไร",
    "faq.answer9":
      "คุณสามารถใช้รหัสโปรโมชั่นของคุณในหน้าการชำระเงินหลังจากที่เลือก Upguest กรุณาใส่รหัสและคลิกส่งเพื่อใช้ส่วนลดของคุณ",
    "faq.question10": "เมืองของฉันไม่ได้อยู่บนรายการที่ให้เลือก",
    "faq.answer10":
      "เรากำลังขยายการบริการให้ครอบคลุมเมืองต่างๆอย่างต่อเนื่อง หากคุณต้องการให้เราขยายบริการไปที่เมืองของคุณ โปรดส่งข้อความถึงเราที่ hello@Upguest.com เราแนะนำให้คุณสมัครรับจดหมายข่าวของเราซึ่งเราจะแจ้งให้คุณทราบเกี่ยวกับเมืองและโรงแรมที่เพิ่มใหม่",
    "faq.question11": "โรงแรมที่ฉันชอบไม่อยู่ในรายการที่ให้เลือก",
    "faq.answer11":
      "ราคัดสรรพันธมิตรที่เป็นโรงแรมของเราและตั้งเป้าหมายเพื่อให้มีคุณภาพมากกว่าปริมาณหากคุณคิดว่าเราควรเพิ่มโรงแรมที่คุณต้องการกรุณาส่งข้อความถึงเราที่ hello@Upguest.com{breakingLine}" +
      "เราแนะนำให้คุณสมัครรับจดหมายข่าวของเราซึ่งเราจะแจ้งให้คุณทราบเกี่ยวกับเมืองและโรงแรมที่เพิ่มใหม่ตลอดเวลา",
    "faq.question12": "ฉันสามารถจอง Upguest ได้ล่วงหน้านานเท่าไหร่",
    "faq.answer12":
      "คุณสามารถจอง Upguest ของคุณสำหรับวันนี้ พรุ่งนี้และนานได้ถึง 3 เดือนล่วงหน้า{breakingLine}" +
      "สำหรับการจอง Upguest ในวันเดียวกับวันทำการจอง โปรดวางแผนตามเวลาเปิดทำการของสระว่ายน้ำของโรงแรมร้านอาหารหรือบาร์บนดาดฟ้า",
    "faq.question13": "นโยบายการยกเลิก / คืนเงินคืออะไร",
    "faq.answer13":
      "หากคุณเปลี่ยนใจคุณสามารถยกเลิกการจองด้วยการรับเงินคืนเต็มจำนวนจนถึงเที่ยงคืนก่อนวันเช็คอิน{breakingLine}" +
      "คำสั่งการยกเลิกที่ได้รับหลังเที่ยงคืนก่อนเช็คอินจะไม่ได้รับเงินคืนตามนโยบายการยกเลิกของเรา{breakingLine}" +
      "โปรดทราบว่าหากคุณมีสิทธิ์ได้รับเงินคืนเราจะดำเนินการทันทีเมื่อคุณขอยกเลิก อย่างไรก็ตามขึ้นอยู่กับนโยบายของธนาคารของคุณ ในบางกรณีอาจใช้เวลาถึง 2 สัปดาห์ก่อนที่การคืนเงินจะปรากฏในบัญชีธนาคารของคุณ",
    "faq.question14": "ฉันสามารถยกเลิกได้อย่างไร?",
    "faq.answer14":
      "คุณสามารถยกเลิก Upguest ของคุณโดยการส่งข้อความไปที่ hello@Upguest.com ระบุหมายเลขยืนยันการจองของคุณและที่อยู่อีเมลที่ใช้ในการจอง โดยเราขอแนะนำให้คุณตรวจสอบนโยบายการยกเลิกการจองเพื่อดูว่าการจองของคุณนั้นเข้าออยู่ภายใต้เงื่อนไขที่จะได้รับเงินคืนหรือไม่{breakingLine}" +
      "ทั้งนี้ หากคุณมีสิทธิ์ได้รับเงินคืน เราจะดำเนินการให้ทันทีเมื่อคุณขอยกเลิกการจอง โดยระยะเวลาการดำเนินการคืนเงินจะขึ้นอยู่กับนโยบายของธนาคาร ซึ่งในบางกรณีอาจต้องใช้เวลาถึง 2 สัปดาห์สำหรับยอดเงินที่จะแสดงในบัญชีธนาคารของคุณ",
    "faq.question15": "ฉันสามารถเปลี่ยนวันเข้าใช้บริการของฉันได้อย่างไร",
    "faq.answer15":
      "คุณสามารถเปลี่ยนวันที่ Upguest ของคุณได้ถึงเที่ยงคืนก่อนวันที่จะเข้าใช้บริการ โดยการส่งข้อความวันที่คุณต้องการเปลี่ยนแปลง และหมายเลขยืนยันการจองของ Upguest มาที่ hello@Upguest.com",
    "faq.question16": "ฉันสามารถเพิ่มจำนวนคนสำหรับการจอง Upguest ของฉันได้อย่างไร",
    "faq.answer16":
      "สำหรับบัตรผ่านสระว่ายน้ำคุณสามารถเพิ่มผู้ใหญ่ 1 คนหรือเด็ก 1 คน (อายุ 5 ปีขึ้นไป){breakingLine}สำหรับบัตรผ่านสระว่ายน้ำเด็ก 1 คน (อายุไม่เกิน 5 ปี) ไม่เสียค่าใช้จ่าย{breakingLine}หากต้องการพาเพื่อนมาเพิ่ม กรุณาทำการจองใหม่ในวันเดียวกันเพื่อให้มีคนเข้าร่วมประสบการณ์ Upguest ของคุณมากขึ้น",
    "faq.question17": "ฉันไม่ได้รับอีเมลยืนยันการจอง Upguest ของฉัน",
    "faq.answer17":
      "โปรดตรวจสอบว่าอีเมลอยู่ในโฟลเดอร์อีเมลขยะของคุณหรือไม่ หากเป็นเช่นนั้น เราขอแนะนำให้คุณเพิ่มอีเมล hello@Upguest.com ในสมุดที่อยู่ของคุณ{breakingLine}" +
      "หากคุณยังไม่เห็นอีเมลโปรดติดต่อเราได้ที่ hello@Upguest.com พร้อมกับระบุที่อยู่อีเมลที่ถูกต้องและเราจะส่งอีเมลยืนยันการจองไปให้คุณอีกครั้ง สาเหตุอาจมาจากการกรอกที่อยู่อีเมลไม่ถูกต้องขณะคุณทำการลงทะเบียนบนเว็บไซต์ของเรา",
    "faq.question18": "มีความปลอดภัยที่จะกรอกรายละเอียดบัตรเครดิตของฉันหรือไม่",
    "faq.answer18":
      "เราได้มาตรฐานด้านการรักษาความปลอดภัยสำหรับการใช้จ่ายออนไลน์โดยใช้บัตรเครดิตหรือบัตรเดบิตของคุณ ทั้งนี้ ก่อนที่คุณจะใส่รายละเอียดการชำระเงินทุกครั้ง เราแนะนำให้คุณตรวจสอบอีกครั้งว่า เครื่องหมายแม่กุญแจ ได้แสดงอยู่หน้าที่อยู่เว็บไซต์ของเราในแถบที่อยู่ของเบราเซอร์ ซึ่งหมายความว่าคุณมีการเชื่อมต่อที่ปลอดภัยกับระบบของเรา และคุณมั่นใจได้ว่าคุณสามารถป้อนข้อมูลบัตรเครดิตหรือบัตรเดบิตของคุณอย่างปลอดภัย",
    "covid.header": "ข้อมูล COVID-19",
    "covid.welcomeText":
      "ข่าวล่าสุด: 9 กุมภาพันธ์ 2564{breakingLine}{breakingLine}" +
      "เรียนลูกค้าทุกท่าน{breakingLine}{breakingLine}" +
      "Upguest มุ่งมั่นที่สร้างรูปแบบการพักผ่อนที่ปลอดภัยให้กับลูกค้าของเราเพื่อการพักผ่อนและผ่อนคลายที่สระว่ายน้ำของโรงแรมหรูและเพลิดเพลินกับเครื่องดื่มและอาหาร{breakingLine}{breakingLine}" +
      "ความปลอดภัยและความสะดวกสบายของคือความสำคัญสูงสุดสำหรับเราและโรงแรมพันธมิตรของเรา{breakingLine}{breakingLine}" +
      "เราปฏิบัติตามหลักเกณฑ์ของรัฐบาลอย่างเคร่งครัดและจะอัปเดตข้อมูลบนหน้าโรงแรมเพจลิสติ้งจองโรงแรมในกรณีที่มีมาตรการเพิ่มเติมใดๆ",
    "covid.question1": "โรงแรมปลอดภัยสำหรับการเข้าใช้บริการหรือไม่?",
    "covid.answer1":
      "โรงแรมและรีสอร์ทที่เป็นพันธมิตรกับเราปฏิบัติตามแนวทางของการท่องเที่ยวแห่งประเทศไทยอย่างเคร่งครัดในเรื่องการคัดกรองการทำความสะอาดและการเว้นระยะห่างระหว่างบุคคล ผู้เข้าพักทุกคนจะได้รับการตรวจสอบอุณหภูมิก่อนเข้าโรงแรมและมีการทำความสะอาดพื้นที่สาธารณะด้วยน้ำยาฆ่าเชื้ออยู่บ่อยครั้ง ทุกที่ที่จำเป็นจะมีการดำเนินการเพื่อให้แน่ใจว่ามีการเว้นระยะห่างทางสังคม",
    "covid.question2": "สระว่ายน้ำปลอดภัยไหม?",
    "covid.answer2":
      "ไม่มีหลักฐานว่า COVID-19 สามารถแพร่กระจายสู่มนุษย์ผ่านการใช้สระว่ายน้ำร่วมกัน (ที่มา: cdc.gov) Upguest และผู้บริหารของโรงแรมแนะนำให้ท่านทำตามแนวทางปฏิบัติในการว่ายน้ำอย่างปลอดภัยควบคู่ไปกับการเว้นระยะห่างทางสังคมและการป้องกันตัวเองในชีวิตประจำวัน",
    "covid.question3": "ฉันจะใช้บริการปลอดภัยได้อย่างไร?",
    "covid.answer3":
      "มีขั้นตอนปฏิบัติมากมายที่คุณสามารถทำได้รวมถืงการล้างมือให้สะอาดเป็นประจำ (ด้วยสบู่และน้ำหรือเจลล้างมือที่มีส่วนผสมของแอลกอฮอล์) และหลีกเลี่ยงการสัมผัสตาจมูกและปากรวมถึงอยู่ห่างจากผู้ที่กำลังไอหรือจามอย่างน้อยสองเมตร",
    "covid.question4": "ฉันจะหาข้อมูลเพิ่มเติมได้ที่ไหน?",
    "covid.answer4":
      "ปฏิบัติตามคำแนะนำขององค์การอนามัยโลกสำหรับประชาชนเกี่ยวกับ COVID-19{breakingLine}{breakingLine}" +
      "การท่องเที่ยวแห่งประเทศไทยได้จัดเตรียมเว็บไซต์ให้ข้อมูลเฉพาะสำหรับ Amazing Thailand Safety & Health Administration (SHA) เป็นภาษาไทย{breakingLine}{breakingLine}" +
      "กระทรวงการต่างประเทศของไทยโพสต์ข้อมูลอัปเดตเกี่ยวกับ COVID-19 บนเว็บไซต์ของตนเป็นประจำทั้งภาษาไทยและภาษาอังกฤษ",
    "button.booknow": "จองเลย",
    "button.view": "เข้าชม",
    "covid.message": "ข้อมูลเกี่ยวกับ COVID-19",
    "creditcard.cvc": "CVC",
    "creditcard.expiry": "หมดอายุวันที่ (MM/YY)",
    "creditcard.name": "ชื่อ",
    "creditcard.number": "หมายเลขบัตร",
    "selectPass.title": "เลือก Pass ที่ใช่สำหรับคุณ",
    "selectPass.pool": "Pool",
    "selectPass.buffet": "Buffet",
    "selectPass.skyBar": "SkyBar",
    "selectPass.comingSoon": "(coming soon)",
    "emailcta.enteremail": "กรอกอีเมลของคุณที่นี่",
    "emailcta.newtoUpguest": "เข้าใช้ Upguest ครั้งแรกใช่มั้ย?",
    "emailcta.signuppromotion": "ลงทะเบียนเพื่อรับโปรโมชั่น",
    "emailcta.signuppromotion2": "ใหม่ล่าสุดจากทางโรงแรม",
    "explore.main1": "เข้าถึงร้านอาหาร บาร์ชั้นดาดฟ้าในโรงแรม",
    "explore.main2": "และสระว่ายน้ำที่ดีที่สุด เพื่อรับส่วนลดมากมายสำหรับอาหารและเครื่องดื่ม",
    "footer.aboutus": "เกี่ยวกับเรา",
    "footer.browsehotels": "เลือกดูโรงแรม",
    "footer.contactus": "ติดต่อเรา",
    "footer.discover": "ค้นพบ",
    "footer.faq": "คำถามที่พบบ่อย",
    "footer.hotelpartnerlogin": "เข้าสู่ระบบพาร์ทเนอร์โรงแรม",
    "footer.howitworks": "ขั้นตอนการใช้งาน",
    "footer.partners": "พาร์ทเนอร์",
    "footer.partnerwithus": "ร่วมเป็นพาร์ทเนอร์กับเรา",
    "footer.privacypolicy": "นโยบายความเป็นส่วนตัว",
    "footer.signup": "ลงทะเบียนเพื่อรับโปรโมชั่น",
    "footer.signup2": "ใหม่ล่าสุดจากทางโรงแรม",
    "footer.subscribe": "สมัครสมาชิก Upguest",
    "footer.support": "สนับสนุน",
    "footer.termsconditions": "ข้อตกลงและเงื่อนไข",
    "footer.partner_sign_up": "Become a partner",
    "header.becomehotelpartner": "มาเป็นพาร์ทเนอร์กับเรา",
    "header.booknow": "จองเลย",
    "header.browsehotels": "เลือกดูโรงแรม",
    "header.howitworks": " ขั้นตอนการจอง Upguest",
    "header.partner_sign_up": "Become a partner",
    "header.login": "เข้าสู่ระบบ",
    "header.signup": "ลงชื่อ",
    "homepage.poster1": "เข้าใช้สระว่ายน้ำ รูฟท็อป และทานบุฟเฟ่ต์ที่โรงแรมสุดหรู",
    "homepage.poster2": "โดยไม่ต้องจองห้องพัก",
    "hotels.currency": "บาท",
    "hotels.guest": "แขก",
    "hotels.popular": "โรงแรมยอดนิยม",
    "hotels.starting": "เริ่มต้นที่",
    "hoteldetails.choose": "เลือกวันที่",
    "hoteldetails.enjoy": "เพลิดเพลินไปกับ Daycation",
    "hoteldetails.howitworks.first":
      "เลือกวันที่และมูลค่าบัตรกำลังที่ต้องการ ยิ่งบัตรกำนัลราคาสูง ส่วนลดก็ยิ่งมากขึ้นอีก!",
    "hoteldetails.howitworks.second": "เลือกจำนวนคน ผู้ใหญ่ 1 คน หรือ เด็ก 1 คน และทำการจอง",
    "hoteldetails.howitworks.third":
      "แสดงอีเมลที่ใช้จอง Upguest พร้อมบัตรประจำตัวประชาชนที่แผนกต้อนรับของโรงแรมเพื่อแลกรับบัตรกำนัล",
    "hoteldetails.howitworks.fourth": "และเพลิดเพลินไปกับ Upguest สุดหรูของคุณได้เลย!",
    "hoteldetails.select": "เลือกบัตรกำนัลและจอง",
    "hoteldetails.slogan1": "Get a Pool Pass for the day with F&B credits",
    "hoteldetails.slogan2": "to spend at the hotel restaurants and bars",
    "hoteldetails.subslogan1": "และสระว่ายน้ำของโรงแรมที่ดีที่สุด",
    "hoteldetails.subslogan2": "จะได้รับส่วนลดมากยิ่งขึ้น",
    "mainimage.getUpguest": "ได้รับ Upguest",
    "mainimage.search": "ค้นหา",
    "mainimage.wantluxurydaycation": "ต้องการ daycation หรูหรือไม่?",
    "mainmenu.account": "Account2",
    "mainmenu.loginregister": "Log In | Register2",
    "mainmenu.logout": "Log Out2",
    "mainmenu.myaccount": "My Account2",
    "onboarding.addingmore": "เรากำลังเพิ่มมากขึ้น",
    "onboarding.bookdetails": "วันหยุดสุดฟินที่สระว่ายน้ำ",
    "onboarding.bookdetails2": "ที่โรงแรมสุดหรู",
    "onboarding.cancellation":
      "หากคุณเปลี่ยนใจภายหลัง คุณสามารถยกเลิกได้ฟรีจนถึงเที่ยงคืนก่อนเช็คอิน",
    "onboarding.choosedate": "เลือก Pass ที่ต้องการและจองเลย!",
    "onboarding.choosedatedetail":
      "เรามีข้อเสนอดี ๆ มากมายทั้งสำหรับเข้าใช้สระว่ายน้ำ ทานบุฟเฟ่ต์พรีเมียม หรือบาร์ชั้นดาดฟ้า",
    "onboarding.choosehotelresort": "เลือกจากโรงแรมหรือรีสอร์ทที่ไหนก็ได้ที่เรามี",
    "onboarding.city.bangkok": "BANGKOK",
    "onboarding.city.pattaya": "PATTAYA",
    "onboarding.city.phuket": "PHUKET",
    "onboarding.enjoydaycation": "เพลิดเพลินไปกับ Daycation",
    "onboarding.enjoydaycationdetail":
      "แสดง Upguest ของคุณที่แผนกต้อนรับเพื่อรับ บัตรกำนัล และสนุกไปกับ Daycation สุดหรูได้เลย!",
    "onboarding.everyweek": "ทุกสัปดาห์",
    "onboarding.findhotel": "เลือกวันที่และค้นหาโรงแรม",
    "onboarding.findhoteldetail":
      "เลือกโรงแรมหรือรีสอร์ทพาร์ทเนอร์ของเรา โดยเราจะมีข้อเสนอเพิ่มมากขึ้นทุกสัปดาห์",
    "onboarding.showUpguest":
      "แสดง Upguest และภาพของคุณ ID ที่แผนกต้อนรับและได้รับบัตรกำนัลเครื่องดื่มของคุณ เพลิดเพลินกับวันของคุณในสระว่ายน้ำ!",
    "steps.guestinfo": "ข้อมูลของท่าน",
    "steps.paymentmethod": "วิธีการชำระเงิน",
    "text.address": "ที่อยู่",
    "text.adult": "ผู้ใหญ่",
    "text.adults": "ผู้ใหญ่",
    "text.apply": "ใช้รหัสส่วนลด",
    "text.backtohome": "กลับไปหน้าหลัก",
    "text.backtohomepage": "กลับไปหน้าหลัก",
    "text.backtoprevious": "กลับไปที่ขั้นตอนก่อนหน้า",
    "text.booknow": "จองเลย",
    "text.cancellationpolicy": "นโยบายเกี่ยวกับการยกเลิก",
    "text.changeofplans":
      "ต้องการเปลี่ยนแผนใช่มั้ย? ไม่มีปัญหา! คุณสามารถยกเลิกการจองพร้อมขอรับเงินคืนเต็มจำนวนได้จนถึงเที่ยงคืนก่อนวันเช็คอิน",
    "text.checkout.email_address": "ที่อยู่อีเมล (คุณจะได้รับการยืนยัน Upguest ของคุณที่นี่)",
    "text.checkpoolbarmenu": "โปรดตรวจสอบรายละเอียดเมนูที่บาร์ริมสระ",
    "text.contactus": "โปรดติดต่อฝ่ายสนับสนุนที่ hello@Upguest.com",
    "text.continerevieworder": "กรุณาตรวจสอบคำสั่งซื้อของคุณ",
    "text.revieworder": "กรุณาตรวจสอบคำสั่งซื้อของคุณ",
    "text.children": "เด็ก",
    "text.date": "วันที่",
    "text.discount": "รหัสส่วนลด:",
    "text.endingin": "หมายเลขบัตรเครดิต 4 ตัวสุดท้าย",
    "text.entervalidemail": "กรุณาใส่อีเมลที่ถูกต้อง",
    "text.error": "เกิดข้อผิดพลาด",
    "text.extraadult": "ผู้ใหญ่",
    "text.extrakid": "เด็ก",
    "text.first_name": "ชื่อ",
    "text.from": "เริ่มต้น",
    "text.grandtotal": "ยอดรวมทั้งหมด",
    "text.havediscountcode": "คุณมีรหัสส่วนลด",
    "text.homepage": "หน้าหลัก",
    "text.includes": "ครอบคลุมบริการ",
    "text.including": "รวม",
    "text.creditappliesto": "สามารถใช้ได้กับ",
    "text.includingvoucher": "รวม บัตรกำนัล มูลค่า {currenciedValue}",
    "text.kidage": "อายุตั้งแต่ 5-12 ปี",
    "text.kids": "Kid",
    "text.notallowed": "Not allowed",
    "text.last_name": "นามสกุล",
    "text.loading": "โหลด",
    "text.mistyped_or_moved": "คุณอาจพิมพ์ที่อยู่ผิดหรือหน้าเว็บอาจย้ายไป",
    "text.occupancypolicy": "นโยบายการเข้าใช้บริการ",
    "text.occupancy.first": "Upguest 1 ใบใช้ได้กับผู้ใหญ่เพียง 1 คน",
    "text.occupancy.second": "คุณสามารถเพิ่มผู้ใหญ่หรือเด็กใน Upguest ได้เพียง 1 คน/ใบ",
    "text.occupancy.third": "เด็กอายุไม่เกิน 5 ปีเข้าใช้บริการได้ฟรี (Upguest 1 ใบ ต่อเด็ก 1 คน)",
    "text.occupancy.fourth":
      "หากต้องการเพิ่มสมาชิกเข้าใช้บริการ คุณสามารถจอง Upguest เพิ่มได้หลังจากการจองครั้งแรกเสร็จสิ้นแล้ว",
    "text.contactInfo": "Contact Info",
    "text.openinghours": "ช่วงเวลาเปิดบริการ",
    "text.order.thankyou1": "ขอบคุณสำหรับคำสั่งซื้อของคุณ",
    "text.order.thankyou2": "​",
    "text.order.unsuccessful1": "คำสั่งซื้อของคุณไม่สำเร็จ",
    "text.order.unsuccessful2": "",
    "text.ordersummary": "สรุปยอดสั่งจอง",
    "text.overage": "เกิน 13 ปีขึ้นไป",
    "text.page_not_found": "เราจะไม่พบหน้าที่คุณต้องการ!",
    "text.phone_number": "หมายเลขโทรศัพท์",
    "text.placeorder": "จอง",
    "text.pool": "สระว่ายน้ำ",
    "text.buffet": "Buffet",
    "text.skybar": "SkyBar",
    "text.restaurant": "Restaurant",
    "text.poolbar": "Pool Bar",
    "text.promocode": "รหัสโปรโมชั่น",
    "text.doYouHavePromocode": "คุณมีรหัสส่วนลด",
    "text.startingfrom": "เริ่มต้น",
    "text.returnhomepage": "กลับไปที่การค้นหา",
    "text.reviews": "รีวิว",
    "text.send_confirmation": "เราได้ส่งอีเมลยืนยันการสั่งซื้อไปให้คุณแล้ว",
    "text.selectdate": "เลือกวันที่:",
    "text.savenow": "ลดทันที",
    "text.sorryerror": "ขออภัยในความผิดพลาดและเราหวังว่าวันนี้เป็นวันดีๆของคุณ",
    "text.subjectavailability": "รายการดังกล่าวอาจมีการเปลี่ยนแปลงได้",
    "text.subtotal": "ยอดรวม",
    "text.tooltip": "เชิญผู้ใหญ่หรือเด็กเพิ่มเติมเพื่อเข้าใช้ Upguest!",
    "text.topayment": "ดำเนินการชำระเงิน",
    "text.tax": "ภาษี",
    "text.notavailable": "ไม่มีบริการนี้ ",
    "text.total": "ทั้งหมด",
    "text.totaltax1": "ทั้งหมด ",
    "text.totaltax2": "ไม่รวมค่าดำเนินการ",
    "text.voucher": "บัตรกำนัล",
    "text.fooddrinkvouchervalue": "voucher อาหารและเครื่องดื่มมูลค่า x บาท",
    "text.for1adult": "สำหรับผู้ใหญ่ 1 ท่าน",
    "text.voucher_after": "​Voucher",
    "text.voucher_before": "และ ",
    "text.yesreceivemail": "ใช่ ฉันต้องการรับอีเมลพร้อมส่วนลดและข้อเสนอพิเศษ",
    "text.tax_before": "ภาษี ",
    "text.tax_after": "​",
    "src.components.HotelsList.offTextFirstPart": "ลด",
    "src.components.HotelsList.offTextSecondPart": " ",
    "src.components.HotelsList.viewDetailsButton": "ดูรายละเอียด",
    "src.components.HotelsList.LoadMoreButtonText": "ดูหน้าถัดไป",
    "src.components.HotelsList.readMoreText": "อ่านเพิ่มเติม..",
    "src.checkout.views.Billing.Page.infoText":
      "กรอกข้อมูลอีกแค่นิดเดียวเท่านั้น การจองกำลังจะเสร็จสิ้นแล้ว!",
    "src.checkout.views.Billing.Page.orderSummary": "สรุปยอดคำสั่งจอง",
    "src.checkout.views.Billing.Page.guestDetails": "ข้อมูลของคุณ",
    "src.checkout.views.Payment.View.infoText.partOne": "หากคุณต้องการเปลี่ยนแพลน",
    "text.buy.dogc": "ซื้อบัตรของขวัญ Upguest",
    "src.checkout.views.Payment.View.infoText.partTwo":
      "คุณสามารถทำการยกเลิกการจองได้จนถึงเที่ยงคืนก่อนวันที่เช็คอิน",
    "src.checkout.views.Payment.View.orderDetails": "รายละเอียดคำสั่งซื้อ",
    "src.checkout.views.Payment.View.paymentDetails": "ข้อมูลการชำระเงิน",
    "src.checkout.views.Payment.View.paymentSubblock.securityPercent":
      "ระบบชำระเงินมีความปลอดภัย 100%",
    "src.checkout.views.Payment.View.security.title": "ระบบการชำระเงินมีความปลอดภัย",
    "src.checkout.views.Payment.View.security.text": "ข้อมูลจะถูกเข้ารหัสป้องกันเพื่อความปลอดภัย",
    "src.checkout.views.Payment.View.security.agree.text.firstPart":
      "หากคุณยอมรับข้อตกลงและนโยบายของเรา",
    "src.checkout.views.Payment.View.security.agree.terms": "ข้อตกลง",
    "src.checkout.views.Payment.View.security.agree.text.secondPart": " และ ",
    "src.checkout.views.Payment.View.security.agree.policy": "นโยบายความเป็นส่วนตัว",
    "src.checkout.components.HotelImageInfo.passValueText.firstPart": "Upguest และ",
    "src.checkout.components.HotelImageInfo.passValueText.secondPart": "Voucher",
    "text.ordernumber": "รหัสคำสั่งซื้อ",
    "text.apply.begin": "ใช่โค๊ดบัตรของขวัญ",
    "text.please.confirmation.no": "กรุณาระบุรหัสคำสั่งซื้อของคุณ",
    "text.apply.end": "ในช่องรหัสส่วนลด",
    "text.beingsentgc": "มีบัตรของขวัญส่งมาหาคุณ!",
    "text.receiveexplain.begin": "ส่งบัตรของขวัญมูลค่า",
    "text.receiveexplain.end": "บาทเพื่อนำไปใช้ที่ Upguest.com",
    "src.checkout.components.Bill.discount": "ส่วนลด",
    "src.checkout.components.Bill.tax": "ค่าดำเนินการ",
    "src.checkout.components.Bill.total": "ยอดรวม",
    "src.checkout.components.Bill.minimalPrice": "ชำระขั้นต่ำ 20 บาท",
    "src.checkout.components.Bill.UpguestFor": "Upguest สำหรับ",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.firstName": "ชื่อ",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.lastName": "นามสกุล",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.email": "ที่อยู่อีเมล",
    "src.checkout.components.GuestAddressForm.GuestAddressForm.phoneNumber": "หมายเลขโทรศัพท์",
    "src.views.OrderConfirmed.View.thankyou.thankYou": "ขอบคุณที่ใช้บริการกับ Upguest",
    "src.views.OrderConfirmed.View.thankyou.notification.firstPart":
      "คุณจะได้รับอีเมล์ยืนยันการจองที่ ",
    "src.views.OrderConfirmed.View.thankyou.notification.secondPart": " ในทันที",
    "src.views.OrderConfirmed.View.bill.title": "สรุปข้อมูลการจอง",
    "src.views.OrderConfirmed.View.bill.pooltime": "เวลาเปิดทำการของสระว่ายน้ำ",
    "src.views.OrderConfirmed.View.contact.enjoyDaycation": "สนุกกับวันพักผ่อนสุดพิเศษของคุณ",
    "src.views.OrderConfirmed.View.contact.header": "ติดต่อทีมงานเพื่อขอความช่วยเหลือ",
    "src.views.OrderConfirmed.View.contact.email": "hello@Upguest.com",
    "bnb-gallery.noPhotosProvided": "",
    "bnb-gallery.showPhotoList": "แสดงรายการรูปภาพ",
    "bnb-gallery.hidePhotoList": "ซ่อนรายการรูปภาพ",
    "line.updates": "รับข้อมูลอัพเดทล่าสุดผ่าน Line",
    "text.patner_subscribe": "Subscribe for {priceText}",
    "text.patner_signup": "Sign Up",
  },
};

const messages = data;

const availableLanguages = Object.keys(data);

const languagePreferences = getBrowserLocales({ languageCodeOnly: true });

languagePreferences.map((lang) => {
  if (defaultLocale === "" && availableLanguages.includes(lang)) {
    defaultLocale = lang;
  }
});

if (defaultLocale === "") {
  defaultLocale = "en";
}

export interface LocaleContextType {
  locale: string;
  setLocale: (locale: string) => void;
}

export const LocaleContext = React.createContext<LocaleContextType>({
  locale: defaultLocale,
  // tslint:disable-next-line:no-empty
  setLocale: () => {},
});

const { Provider: RawLocaleProvider } = LocaleContext;

function getKeyValueJson(lData, lLocale) {
  return lData[lLocale];
}

const LocaleProvider: React.FC = ({ children }) => {
  // const {locale, setLocale} = React.useContext(LocaleContext)

  /*
  languagePreferences.map(lang => {
    if()
  })*/
  const { storedValue: locale, setValue: setLocale } = useLocalStorage("locale", defaultLocale);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      locale={locale}
      messages={getKeyValueJson(data, locale)}
      onError={(err) => {
        if (!err.message.includes("[React Intl] Missing message: ")) {
          console.error(err);
        }
      }}
      key={locale}
    >
      <RawLocaleProvider
        value={{
          locale,
          setLocale,
        }}
      >
        {children}
      </RawLocaleProvider>
    </IntlProvider>
  );
};

export { LocaleProvider, defaultLocale, messages };
